import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.SP.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("pz-direction", {
    attrs: {
      "ask-postal-code": ""
    },
    model: {
      value: _vm.formulario1.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "direccion", $$v);
      },
      expression: "formulario1.direccion"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estado_civil_id",
      label: _vm.$t("forms_flujo.form1_persona.SP.civil_state"),
      error: _vm.errors.get("estado_civil_id")
    }
  }, [_c("el-select", {
    ref: "estado_civil_id",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.civil_state")
    },
    model: {
      value: _vm.formulario1.estado_civil_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "estado_civil_id", $$v);
      },
      expression: "formulario1.estado_civil_id"
    }
  }, _vm._l(_vm.marital_status, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.estado,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.civil_state")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "genero",
      label: _vm.$t("forms_flujo.form1_persona.SP.genero"),
      error: _vm.errors.get("genero")
    }
  }, [_c("el-select", {
    ref: "genero",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.genero")
    },
    model: {
      value: _vm.formulario1.genero,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "genero", $$v);
      },
      expression: "formulario1.genero"
    }
  }, _vm._l(_vm.genero, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.genero")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 6
      },
      lg: {
        span: 6
      },
      md: {
        span: 6
      },
      sm: {
        span: 12
      },
      xs: {
        span: 12
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "tiene_hijos",
      label: _vm.$t("forms_flujo.form1_persona.SP.tiene_hijos"),
      error: _vm.errors.get("tiene_hijos")
    }
  }, [_c("el-select", {
    ref: "tiene_hijos",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.tiene_hijos")
    },
    model: {
      value: _vm.formulario1.tiene_hijos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "tiene_hijos", $$v);
      },
      expression: "formulario1.tiene_hijos"
    }
  }, _vm._l(_vm.arreglo_hijos, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.tiene_hijos")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 6
      },
      lg: {
        span: 6
      },
      md: {
        span: 6
      },
      sm: {
        span: 12
      },
      xs: {
        span: 12
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cant_hijos",
      label: _vm.$t("forms_flujo.form1_persona.SP.cant_hijos"),
      error: _vm.errors.get("cant_hijos")
    }
  }, [_c("el-input", {
    ref: "cant_hijos",
    attrs: {
      disabled: !_vm.formulario1.tiene_hijos,
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.cant_hijos")
    },
    model: {
      value: _vm.formulario1.cant_hijos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "cant_hijos", _vm._n($$v));
      },
      expression: "formulario1.cant_hijos"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.carnet_conducir",
      label: _vm.$t("forms_flujo.form1_persona.SP.tiene_carnet_conducir"),
      error: _vm.errors.get("carnet_conducir")
    }
  }, [_c("el-select", {
    ref: "info_extra.carnet_conducir",
    model: {
      value: _vm.formulario1.info_extra.carnet_conducir,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "carnet_conducir", $$v);
      },
      expression: "formulario1.info_extra.carnet_conducir"
    }
  }, [_c("el-option", {
    attrs: {
      value: 1,
      label: _vm.$t("1")
    }
  }), _c("el-option", {
    attrs: {
      value: 0,
      label: _vm.$t("0")
    }
  })], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "pais_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.SP.pais_nacimiento")
    }
  }, [_c("el-select", {
    ref: "pais_nacimiento",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.pais_nacimiento")
    },
    model: {
      value: _vm.formulario1.pais_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "pais_nacimiento", $$v);
      },
      expression: "formulario1.pais_nacimiento"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nombre,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.pais_nacimiento")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ciudad_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.SP.ciudad_nacimiento"),
      error: _vm.errors.get("ciudad_nacimiento")
    }
  }, [_c("el-input", {
    ref: "ciudad_nacimiento",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.ciudad_nacimiento")
    },
    model: {
      value: _vm.formulario1.ciudad_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "ciudad_nacimiento", _vm._n($$v));
      },
      expression: "formulario1.ciudad_nacimiento"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "banco_deposito",
      label: _vm.$t("forms_flujo.form1_persona.SP.banco_deposito"),
      error: _vm.errors.get("banco_deposito")
    }
  }, [_c("el-select", {
    ref: "banco_deposito",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.banco_deposito")
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.formulario1.banco_deposito,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "banco_deposito", $$v);
      },
      expression: "formulario1.banco_deposito"
    }
  }, _vm._l(_vm.bancos, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.short_name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cuenta_deposito",
      label: _vm.$t("forms_flujo.form1_persona.SP.cuenta_deposito"),
      error: _vm.errors.get("cuenta_deposito")
    }
  }, [_c("el-input", {
    ref: "cuenta_deposito",
    attrs: {
      type: "text",
      disabled: _vm.formulario1.banco_deposito === "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.cuenta_deposito")
    },
    model: {
      value: _vm.formulario1.cuenta_deposito,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "cuenta_deposito", $$v);
      },
      expression: "formulario1.cuenta_deposito"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 24
      },
      lg: {
        span: 24
      },
      md: {
        span: 24
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("div", [_c("el-col", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "open_bank_integration",
      error: _vm.errors.get("open_bank_integration"),
      label: " "
    }
  }, [_c("el-checkbox", {
    attrs: {
      disabled: _vm.unnax_checkbox_disabled
    },
    on: {
      change: function change($event) {
        return _vm.setUnnaxDialogVisible(true);
      }
    },
    model: {
      value: _vm.formulario1.open_bank_integration,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "open_bank_integration", $$v);
      },
      expression: "formulario1.open_bank_integration"
    }
  }, [_c("div", {
    staticStyle: {
      "white-space": "initial"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("commons.open_banking.label")) + " ")])])], 1)], 1), _c("el-col", {
    staticClass: "pz-large-margin"
  }, [_c("div", [_vm._v(" " + _vm._s(_vm.$t("commons.open_banking.label_adicional")) + " ")])]), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-dialog", {
    staticClass: "pz-dialog-banking",
    attrs: {
      title: _vm._f("capitalize")(_vm.$t("commons.bank_aggregation.title_header"), {
        onlyFirstLetter: true
      }),
      visible: _vm.show_unnax_dialog,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.show_unnax_dialog = $event;
      }
    }
  }, [_c("el-row", {
    staticClass: "row justify-content-center"
  }, [_vm.formulario1.widget_url ? _c("pz-unnax-iframe", {
    attrs: {
      url: _vm.formulario1.widget_url
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };