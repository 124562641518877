export var CONFIGURATIONFront = {
  views: {
    instructions: true,
    preview: true
  },
  sharpnessThreshold: 55,
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636"
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635"
        }
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },
    moduleCustomization: {
      legends: {
        initializing: "iniciando",
        processing: "procesando",
        scan: {
          none: "ENFOCA TU ID SOBRE LA GUÍA",
          smallDocument: "ACERCATE MÁS",
          goodDocument: "",
          capturing: "CAPTURANDO ",
          tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR"
        },
        manualCapture: {
          instruction: "Frente",
          buttonNext: "Continuar",
          tooltip: "Captura nuevamente",
          mobile: {
            instruction: "Captura el Frente de tu identificación",
            buttonNext: "Continuar"
          },
          desktop: {
            instruction: "Sube el Frente de tu identificación",
            title: "Frente"
          }
        }
      },
      legendsInstructions: {
        title: 'Identificación',
        subtitle: 'Captura el frente de tu identifcación',
        buttonNext: 'Continuar'
      },
      legendsPreview: {
        title: 'Identificación',
        subtitle: 'Imagen frontal de tu identificación',
        confirmation: '¿Los datos de tu identificación son legibles?',
        buttonNext: 'Sí, continuar',
        buttonRetry: 'Volver a capturar'
      }
    }
  },
  pathDependencies: {
    //  imageDirectory: window.location.host
  }
};
export var CONFIGURATIONReverse = {
  views: {
    instructions: true,
    preview: true
  },
  sharpnessThreshold: 55,
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636"
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635"
        }
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },
    moduleCustomization: {
      legends: {
        initializing: "iniciando",
        processing: "procesando",
        scan: {
          none: "ENFOCA TU ID SOBRE LA GUÍA",
          smallDocument: "ACERCATE MÁS",
          goodDocument: "",
          capturing: "CAPTURANDO ",
          tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR"
        },
        manualCapture: {
          instruction: "Reverso",
          buttonNext: "Continuar",
          tooltip: "Captura nuevamente",
          mobile: {
            instruction: "Captura el Reverso de tu identificación",
            buttonNext: "Continuar"
          },
          desktop: {
            instruction: "Sube el Reverso de tu identificación",
            title: "Reverso"
          }
        }
      },
      legendsInstructions: {
        title: 'Identificación',
        subtitle: 'Captura el reverso de tu identifcación',
        buttonNext: 'Continuar'
      },
      legendsPreview: {
        title: 'Identificación',
        subtitle: 'Imagen trasera de tu identificación',
        confirmation: '¿Los datos de tu identificación son legibles?',
        buttonNext: 'Sí, continuar',
        buttonRetry: 'Volver a capturar'
      }
    }
  },
  pathDependencies: {
    // imageDirectory: 'ASSETS_URL'
  }
};
export var CONFIGURATIONFace = {
  views: {
    instructions: true
  },
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636"
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635"
        }
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },
    moduleCustomization: {
      legends: {
        initializing: "Iniciando",
        processing: "procesando",
        facetec: {
          accessibilityCancelButton: "Cancelar",
          feedbackCenterFace: "Centra tu rostro",
          feedbackFaceNotFound: "Enfoca tu rostro",
          feedbackMoveAwayWeb: "Aléjate",
          feedbackMoveWebCloser: "Acércate",
          feedbackMovePhoneAway: "Aléjate",
          feedbackMovePhoneCloser: "Acércate",
          feedbackHoldSteady: "No te muevas",
          feedbackMoveWebEvenCloser: "Aún más cerca",
          instructionsHeaderReadyDesktop: "Biometría facial",
          instructionsMessageReadyDesktop: "Enfoca tu rostro en la guía y da clic en el botón para continuar",
          instructionsHeaderReadyMobile1: "Biometría facial",
          instructionsHeaderReadyMobile2: "",
          instructionsMessageReadyMobile1: "Enfoca tu rostro en la guía y",
          instructionsMessageReadyMobile2: "da clic en el botón para continuar",
          actionImReady: "Continuar",
          resultFacescanUploadMessage: "procesando",
          retryHeader: "Inténtalo nuevamente",
          retrySubheaderMessage: "Necesitamos una imagen clara",
          retryYourImageLabel: "Tu foto",
          retryIdealImageLabel: "Pose ideal",
          retryInstructionMessage1: "Sin brillo o iluminación extrema",
          retryInstructionMessage2: "Expresión neutral, sin sonreír",
          retryInstructionMessage3: "Demasiado borroso, limpia tu cámara",
          presessionFrameYourFace: "Enfoca tu rostro en la guía",
          presessionLookStraightAhead: "Mira al frente",
          presessionHoldSteady3: "No te muevas por: 3",
          presessionHoldSteady2: "No te muevas por: 2",
          presessionHoldSteady1: "No te muevas por: 1",
          presessionEyesStraightAhead: "Mira al frente",
          presessionRemoveDarkGlasses: "Quítate los lentes de sol",
          presessionNeutralExpression: "Expresión neutral, sin sonreír",
          presessionConditionsTooBright: "Entorno com demasiada luz",
          presessionBrightenYourEnvironment: "Entorno con poca luz",
          actionTryAgain: "Aceptar",
          cameraPermissionHeader: "Permiso de cámara o micrófono desactivado",
          cameraPermissionMessage: "Por favor revisa la configuración de tu sistema operativo y los ajustes del navegador.",
          cameraPermissionLaunchSettings: "Aceptar",
          initializingCamera: "iniciando",
          initializingCameraStillLoading: "iniciando...",
          resultSuccessMessage: "Validación correcta",
          enterFullscreenHeader: "Prueba de vida",
          enterFullscreenMessage: "Antes de comenzar da clic en el botón de abajo para abrir en pantalla completa",
          enterFullscreenAction: "Continuar"
        }
      },
      legendsInstructions: {
        title: 'Prueba de vida',
        subtitle: 'Enfoca tu rostro en la guía',
        buttonNext: 'Continuar',
        instructions: 'Recuerda no hacer uso de lentes de sol, gorras u otros elementos que dificulten la identificación de tu rostro.'
      }
    }
  },
  pathDependencies: {
    // imageDirectory: 'ASSETS_URL'
  }
};