import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import { getDepartamentos } from '@/routes/api/resources';
import baseForm1 from '@/components/Flujo/Formularios/Formularios1/baseForm1';
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
export default {
  name: 'PzCompanyForm1Uy',
  components: {
    PzTermsAndConditions: PzTermsAndConditions,
    PzFormFlujo: PzFormFlujo,
    PzCheckForm: PzCheckForm,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseForm1],
  provide: function provide() {
    return {
      myForm: this
    };
  },
  data: function data() {
    return {
      reference: 'formulario1',
      loading: false,
      marital_status: [],
      centerDialogVisible: false,
      departamentos: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        provincia: '',
        telefono: '',
        email: '',
        identity: {
          document: 1
        }
      },
      disable: true,
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required('')],
        nombres: [required('')],
        apellidos: [required('')],
        provincia: [required('')],
        telefono: [required('')]
      }
    };
  },
  created: function created() {
    this.fetchData();
  },
  mounted: function mounted() {},
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var departamento = getDepartamentos().then(function (res) {
        _this.departamentos = res.data;
      }).catch(function () {
        _this.departamentos = [];
      });
      return Promise.all([departamento]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};