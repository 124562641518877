import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { type } from "@/commons/utils/Rules";
import { getDepartamentos } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonFormExtFenoreste',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection
  },
  mixins: [baseform2],
  data: function data() {
    return {
      provincias: [],
      formulario2: {
        direccion: {},
        provincia: '',
        telefono_fijo: '',
        email: '',
        url_trackeo: '',
        ref_empresa: 1,
        tipo_documento: 1
      },
      disable: true,
      rules_formulario2: {
        email: [type({
          field: '',
          type: 'email'
        })]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {},
  beforeMount: function beforeMount() {
    var _this = this;
    var dep = getDepartamentos().then(function (res) {
      _this.provincias = res.data;
    }).catch(function () {
      _this.provincias = [];
    });
    Promise.all([dep]).then(function () {}).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    }
  }
};