import { render, staticRenderFns } from "./OfertaSolicitudTableExtra.vue?vue&type=template&id=764248f5&scoped=true"
import script from "./OfertaSolicitudTableExtra.vue?vue&type=script&lang=js"
export * from "./OfertaSolicitudTableExtra.vue?vue&type=script&lang=js"
import style0 from "./OfertaSolicitudTableExtra.vue?vue&type=style&index=0&id=764248f5&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764248f5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dayanamartinez/Developer/laas_simulator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('764248f5')) {
      api.createRecord('764248f5', component.options)
    } else {
      api.reload('764248f5', component.options)
    }
    module.hot.accept("./OfertaSolicitudTableExtra.vue?vue&type=template&id=764248f5&scoped=true", function () {
      api.rerender('764248f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudTableExtra.vue"
export default component.exports