var getters = {
  /**APP**/
  header: function header(state) {
    return state.app.header;
  },
  location: function location(state) {
    return state.app.location;
  },
  address: function address(state) {
    return state.app.address;
  },
  /**END APP**/
  /**AUTH**/
  token: function token(state) {
    return state.auth.token;
  },
  alias: function alias(state) {
    return state.auth.alias;
  },
  company: function company(state) {
    return state.auth.company;
  },
  /**END AUTH**/
  /**LOADER**/
  loader: function loader(state) {
    return state.loader.loader;
  },
  /**END LOADER**/
  /**PRODUCTO**/
  product: function product(state) {
    return state.product.product;
  },
  form_product: function form_product(state) {
    return state.product.form_product;
  },
  applicant: function applicant(state) {
    return state.product.applicant;
  },
  start_on_list: function start_on_list(state) {
    return state.product.start_on_list;
  },
  sucursal: function sucursal(state) {
    return state.product.sucursal;
  }
  /**END PRODUCTO**/
};
export default getters;