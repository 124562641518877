import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required } from "@/commons/utils/Rules";
import { getEstudios, getTipoViviendas } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
export default {
  name: 'PzPersonForm1Impacta2',
  components: {
    PzDirection: PzDirection,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      properties: [],
      studies: [],
      data_depende: [this.$t('forms_flujo.form1_persona.UY.depende.hijos'), this.$t('forms_flujo.form1_persona.UY.depende.pareja'), this.$t('forms_flujo.form1_persona.UY.depende.hermanos'), this.$t('forms_flujo.form1_persona.UY.depende.notengo'), this.$t('forms_flujo.form1_persona.UY.depende.otros')],
      medio_transporte: [{
        id: 'Transporte publico',
        name: this.$t('forms_flujo.form1_persona.UY.transporte_publico')
      }, {
        id: 'Carro propio',
        name: this.$t('forms_flujo.form1_persona.UY.carro')
      }, {
        id: 'Moto propia',
        name: this.$t('forms_flujo.form1_persona.UY.moto')
      }, {
        id: 'Otros',
        name: this.$t('forms_flujo.form1_persona.UY.otros')
      }],
      formulario1: {
        estudios: '',
        direccion: {},
        tipo_vivienda: '',
        info_extra: {
          depende_deti: [],
          transporte: ''
        }
      },
      rules_formulario1: {
        estudios: [required('')],
        tipo_vivienda: [required('')],
        'info_extra.depende_deti': [required('')],
        'info_extra.transporte': [required('')],
        info_extra: {
          depende_deti: [required('')],
          transporte: [required('')]
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var est = getEstudios().then(function (res) {
        _this.studies = res.data;
      }).catch(function () {
        _this.studies = [];
      });
      var viv = getTipoViviendas().then(function (res) {
        _this.properties = res.data;
      }).catch(function () {
        _this.properties = [];
      });
      return Promise.all([est, viv]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};