var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    staticClass: "pz-large-margin",
    attrs: {
      type: _vm.type,
      icon: _vm.icon
    },
    on: {
      click: _vm.handleClick,
      keydown: _vm.handleEnter
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _c("vue-programmatic-invisible-google-recaptcha", {
    ref: "invisibleRecaptcha",
    staticStyle: {
      display: "none"
    },
    attrs: {
      sitekey: _vm.capchakey,
      "element-id": "invisibleRecaptcha",
      "badge-position": "left",
      "show-badge-mobile": false,
      "show-badge-desktop": false
    },
    on: {
      "recaptcha-callback": _vm.recaptchaCallback
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };