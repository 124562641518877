import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, EspacioBlanco } from "@/commons/utils/Rules";
import { getEstadoCivil, getSucursales } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonForm1Fenoreste',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      marital_status: [],
      sucursales: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        telefono: '',
        fecha_nacimiento: '',
        fecha_ingreso: '',
        ingreso_mensual: '',
        identity: {
          document: 1
        },
        comprobante_domicilio: null,
        comprobante_ingresos: null,
        clearing: null,
        sucursal_id: ''
      },
      view_sucursal: false,
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        telefono: [required('')],
        sucursal_id: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  computed: {
    condocumento: function condocumento() {
      var docmun = localStorage.getItem('docnum');
      if (docmun) {
        return true;
      }
      return false;
    }
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    },
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this.marital_status = res.data;
      }).catch(function () {
        _this.marital_status = [];
      });
      var getsucursales = '';
      if (this.$session.get('sucursal')) {
        this.view_sucursal = true;
        getsucursales = getSucursales().then(function (res) {
          _this.sucursales = res.data;
        }).catch(function () {
          _this.sucursales = [];
        });
      }
      return Promise.all([estado, getsucursales]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};