var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-simulators-template", [_c("el-form", {
    ref: _vm.reference,
    staticStyle: {
      "margin-top": "3%"
    },
    attrs: {
      slot: "simulador",
      "status-icon": "",
      model: _vm.form,
      rules: _vm.rules_formulario1,
      "label-position": "top"
    },
    slot: "simulador"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "valor_vehiculo",
      error: _vm.errors.get("valor_vehiculo"),
      label: _vm.$t("forms_flujo.SimulatorVehiculo.valor_vehiculo")
    }
  }, [_c("money", {
    directives: [{
      name: "money",
      rawName: "v-money",
      value: _vm.money,
      expression: "money"
    }],
    ref: "valor_vehiculo",
    staticClass: "el-input__inner",
    attrs: {
      placeholder: _vm.$t("forms_flujo.SimulatorVehiculo.valor_vehiculoGs")
    },
    nativeOn: {
      blur: function blur($event) {
        return _vm.$nextTick(_vm.emitChange);
      }
    },
    model: {
      value: _vm.form.valor_vehiculo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valor_vehiculo", $$v);
      },
      expression: "form.valor_vehiculo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "entrega_inicial",
      error: _vm.errors.get("entrega_inicial"),
      label: _vm.$t("forms_flujo.SimulatorVehiculo.entrega_inicial")
    }
  }, [_c("money", {
    directives: [{
      name: "money",
      rawName: "v-money",
      value: _vm.money,
      expression: "money"
    }],
    ref: "entrega_inicial",
    staticClass: "el-input__inner",
    attrs: {
      placeholder: _vm.$t("forms_flujo.SimulatorVehiculo.entrega_inicialGs")
    },
    nativeOn: {
      blur: function blur($event) {
        return _vm.$nextTick(_vm.emitChange);
      }
    },
    model: {
      value: _vm.form.entrega_inicial,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "entrega_inicial", $$v);
      },
      expression: "form.entrega_inicial"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "valor_prestamo",
      error: _vm.errors.get("valor_prestamo"),
      label: _vm.$t("forms_flujo.SimulatorVehiculo.valor_prestamo")
    }
  }, [_c("el-input", {
    ref: "valor_prestamo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.SimulatorVehiculo.valor_prestamo")
    },
    model: {
      value: _vm.valor_prestamo,
      callback: function callback($$v) {
        _vm.valor_prestamo = _vm._n($$v);
      },
      expression: "valor_prestamo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "plazo",
      error: _vm.errors.get("plazo"),
      label: _vm.$t("forms_flujo.SimulatorVehiculo.plazo")
    }
  }, [_c("el-input-number", {
    attrs: {
      step: _vm.producto.step_cuota,
      min: _vm.producto.min_cuota,
      max: _vm.producto.max_cuota,
      "step-strictly": ""
    },
    on: {
      change: function change($event) {
        return _vm.$nextTick(_vm.emitChange);
      },
      input: _vm.handleInput
    },
    model: {
      value: _vm.form.plazo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plazo", $$v);
      },
      expression: "form.plazo"
    }
  })], 1)], 1)], 1), _c("pz-oferta-" + _vm.elOfertaView, {
    tag: "comment"
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };