import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { mapGetters } from "vuex";
export default {
  name: "PzYousignV3",
  props: {
    route: {
      required: true,
      default: null,
      type: [String, URL]
    },
    redirect: {
      required: true,
      default: "Prestamos",
      type: [String]
    }
  },
  data: function data() {
    return {
      yousignInstance: null
    };
  },
  computed: _objectSpread({}, mapGetters(["alias"])),
  mounted: function mounted() {
    var _this = this;
    this.loadYousignScript().then(function () {
      _this.initializeYousign();
    }).catch(function (error) {
      console.error("Failed to load Yousign script:", error);
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.yousignInstance) {
      this.yousignInstance.removeMessageListener();
    }
  },
  methods: {
    loadYousignScript: function loadYousignScript() {
      return new Promise(function (resolve, reject) {
        if (document.getElementById("yousign-sdk")) {
          // Script is already loaded
          resolve();
          return;
        }
        var script = document.createElement("script");
        script.id = "yousign-sdk";
        script.src = "https://cdn.yousign.tech/iframe-sdk-1.6.0.min.js";
        script.integrity = "sha384-/7MD1voOOzWVz7FmgeMwmmd1DO85Mo0PkkxdYd9j2wDGzGDGRG/phgnL0c9Xyy52";
        script.crossOrigin = "anonymous";
        script.onload = function () {
          return resolve();
        };
        script.onerror = function (e) {
          return reject(e);
        };
        document.head.appendChild(script);
      });
    },
    initializeYousign: function initializeYousign() {
      var _this2 = this;
      var Yousign = window.Yousign;
      if (!Yousign) {
        console.error("Yousign SDK not available");
        return;
      }
      this.yousignInstance = new Yousign({
        signatureLink: this.route,
        iframeContainerId: "iframe-container",
        isSandbox: true,
        // Set to false in production
        iFrameAttributes: {
          referrerPolicy: "origin-when-cross-origin"
        }
      });
      this.yousignInstance.onStarted(function () {
        console.log("Signer has opened the signature");
      });
      this.yousignInstance.onSuccess(function () {
        console.log("Signer has successfully signed the document");
      });
      this.yousignInstance.onError(function (data) {
        console.error("Signer encountered an error when signing:", data);
      });
      this.yousignInstance.onSignatureDone(function () {
        console.log("Signature completed");
        _this2.$router.push({
          name: _this2.redirect
        });
      });
    }
  }
};