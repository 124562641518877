import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import PzLoaderMixins from '@/commons/PzLoaderMixins';
import { setDocSign } from "@/routes/api/views/vales";
export default {
  name: "PzDocusignEnd",
  components: {},
  mixins: [PzLoaderMixins],
  props: {
    envelopId: {
      require: true,
      default: null,
      type: [String, Number]
    },
    alias: {
      require: true,
      default: null,
      type: [String, Number]
    }
  },
  data: function data() {
    return {
      loading: true
    };
  },
  watch: {},
  created: function created() {
    var _this = this;
    this.sing().finally(function () {
      _this.loaing = false;
    });
  },
  methods: {
    sing: function sing() {
      var _this2 = this;
      return setDocSign(this.alias, this.envelopId, this.$route.query).then(function () {
        _this2.ready();
      }).finally(function () {
        _this2.loading = false;
      });
    },
    ready: function ready() {
      if (window.parentIFrame && window.parentIFrame.getId() === 'docusing') {
        var mens = JSON.stringify({
          t: 'ready',
          m: 'Sended success'
        });
        window.parentIFrame.sendMessage(mens);
      }
    }
  }
};