var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      id: "iframe-container"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };