import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "PzOfertaSolicitudTable",
  mixins: [PzOfertaSolicitudDefault],
  data: function data() {
    return {
      date: '',
      text_tasa: '',
      view_tasa: true,
      viewInfo: false
    };
  },
  mounted: function mounted() {
    if (process.env.VUE_APP_CLIENT == 'impacta') {
      this.view_tasa = false;
      this.text_tasa = this.$t('forms_flujo.oferta_solicitud_table.tasa_semanal');
    } else {
      this.text_tasa = this.$t('forms_flujo.oferta_solicitud_table.tasa');
    }
  },
  computed: _objectSpread({}, mapGetters(["form_product"])),
  created: function created() {
    this.getDate();
  },
  methods: {
    getDate: function getDate() {
      {
        this.viewInfo = this.form_product.route_params.view_info;
        this.date = moment().add(this.myForm.producto.term_type.cant_days, 'days').format(this.$t('date_format'));
      }
    }
  }
};