import baseSimulator from "./baseSimulator";
import PzSimulators from "@/components/Flujo/Templates/PzSimulatorsTemplate";
import PzSimulatorsTemplate from "@/components/Flujo/Templates/PzSimulatorsTemplate";
import currency from "@/commons/filters/other/currency";
import { Money } from 'v-money';
export default {
  name: 'PzVehiculoRotativaSimulator',
  components: {
    PzSimulatorsTemplate: PzSimulatorsTemplate,
    PzSimulators: PzSimulators,
    Money: Money
  },
  mixins: [baseSimulator],
  computed: {
    valor_linea_otorgar: function valor_linea_otorgar() {
      var seguro = this.form.valor_seguro ? this.form.valor_seguro : 0;
      var transferencia = this.form.valor_transferencia ? this.form.valor_transferencia : 0;
      if (seguro == 0 && transferencia == 0) {
        this.form.linea_otorgar = 0;
        return 0;
      }
      var valor = parseFloat(seguro) + parseFloat(transferencia);
      valor = Math.ceil(valor / 500000) * 500000;
      this.form.linea_otorgar = valor;
      this.form.linea_otorgar_porc = valor * 0.1;
      return this.formatTooltip(valor);
    },
    valor_prestamo: function valor_prestamo() {
      var vehiculo = this.form.valor_vehiculo ? this.form.valor_vehiculo : 0;
      var entrega_inicial = this.form.entrega_inicial ? this.form.entrega_inicial : 0;
      var valor = parseFloat(vehiculo) - parseFloat(entrega_inicial);
      this.form.monto = valor;
      return this.formatTooltip(valor);
    }
  },
  data: function data() {
    return {
      vehiculo: true,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      }
    };
  },
  created: function created() {
    this.form.monto = 0;
    this.form.linea_otorgar_porc = 0;
    this.emitChange();
  },
  methods: {
    formatTooltip: function formatTooltip(val) {
      if (val != null) return currency(val, '', 0);
    }
  }
};