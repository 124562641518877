var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "nombres",
    staticStyle: {
      "text-transform": "uppercase"
    },
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nombre")
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", $$v);
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellidos",
      label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
      error: _vm.errors.get("apellido_paterno")
    }
  }, [_c("el-input", {
    ref: "apellidos",
    staticStyle: {
      "text-transform": "uppercase"
    },
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.apellidos")
    },
    model: {
      value: _vm.formulario1.apellidos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellidos", $$v);
      },
      expression: "formulario1.apellidos"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      type: "person",
      "number-hidden": _vm.condocumento,
      "type-label": _vm.$t("forms_flujo.form1_persona.UY.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.UY.identity_doc")
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento"),
      error: _vm.errors.get("fecha_nacimiento")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_nacimiento",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento")
    },
    model: {
      value: _vm.formulario1.fecha_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_nacimiento", $$v);
      },
      expression: "formulario1.fecha_nacimiento"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono"),
      error: _vm.errors.get("telefono")
    }
  }, [_c("el-input", {
    ref: "telefono",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono")
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_ingreso",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso"),
      error: _vm.errors.get("fecha_ingreso")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_ingreso",
    attrs: {
      type: "date",
      "picker-options": _vm.pickerOptions1,
      disabled: _vm.isjubilado,
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso")
    },
    model: {
      value: _vm.formulario1.fecha_ingreso,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_ingreso", $$v);
      },
      expression: "formulario1.fecha_ingreso"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "relacion_laboral_id",
      label: _vm.$t("forms_flujo.form2_persona.BNF.tipo_trabajo")
    }
  }, [_c("el-select", {
    ref: "relacion_laboral_id",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.BNF.tipo_trabajo")
    },
    model: {
      value: _vm.formulario1.relacion_laboral_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "relacion_laboral_id", $$v);
      },
      expression: "formulario1.relacion_laboral_id"
    }
  }, _vm._l(_vm.relacion_laboral, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.relacion,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form2_persona.BNF.tipo_trabajo")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "empresa_oferante",
      label: _vm.$t("forms_flujo.form1_persona.UY.empresa_oferante"),
      error: _vm.errors.get("empresa_oferante")
    }
  }, [_c("el-autocomplete", {
    ref: "empresa_oferante",
    staticClass: "inline-input",
    attrs: {
      "fetch-suggestions": _vm.getSuggest,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.empresa_oferante"),
      "trigger-on-focus": false
    },
    model: {
      value: _vm.formulario1.empresa_oferante,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "empresa_oferante", $$v);
      },
      expression: "formulario1.empresa_oferante"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "tipo_vehiculo",
      label: _vm.$t("forms_flujo.form1_persona.UY.tipo_vehiculo"),
      error: _vm.errors.get("tipo_vehiculo")
    }
  }, [_c("el-input", {
    ref: "tipo_vehiculo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.tipo_vehiculo")
    },
    model: {
      value: _vm.formulario1.tipo_vehiculo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "tipo_vehiculo", $$v);
      },
      expression: "formulario1.tipo_vehiculo"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };