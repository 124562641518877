import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Cedula, required, Rut } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import { getDepartamentos, getRubros } from '@/routes/api/resources';
import baseForm2 from "../baseForm2";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
export default {
  name: 'PzCompanyFormExtEs',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzForwardButton: PzForwardButton,
    PzBackButton: PzBackButton
  },
  mixins: [baseForm2],
  data: function data() {
    return {
      loading: false,
      marital_status: [],
      rubros: [],
      sociosActivo: false,
      centerDialogVisible: false,
      departamentos: [],
      formulario2: {
        company: '',
        razonsocial: '',
        rut: '',
        comienzoactividad: '',
        direccion: '',
        rubro_id: '',
        ref_empresa: '',
        socios: []
      },
      disable: true,
      rules_formulario2: {
        company: [required('')],
        razonsocial: [required('')],
        rut: [Rut('')],
        comienzoactividad: [required('')],
        rubro_id: [required('')]
      },
      rules_socios: {
        nombre: [required('')],
        apellido: [required('')],
        documento: [Cedula('')]
      }
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;
    var depto = getDepartamentos().then(function (res) {
      _this.departamentos = res.data;
    }).catch(function () {
      _this.departamentos = [];
    });
    var rubros = getRubros().then(function (res) {
      _this.rubros = res.data;
    }).catch(function () {
      _this.rubros = [];
    });
    Promise.all([rubros, depto]).then(function () {
      return _this.ready();
    });
  },
  methods: {
    removeSocios: function removeSocios(index) {
      this.formulario2.socios.splice(index, 1);
    },
    addSocios: function addSocios() {
      this.sociosActivo = true;
      //var elem = document.createElement('tr');
      this.formulario2.socios.push({
        nombre: '',
        apellido: '',
        documento: '',
        key: ''
      });
    }
  }
};