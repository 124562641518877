import baseSimulator from "./baseSimulator";
import PzSimulators from "@/components/Flujo/Templates/PzSimulatorsTemplate";
import PzSimulatorsTemplate from "@/components/Flujo/Templates/PzSimulatorsTemplate";
export default {
  name: 'PzDataInputSimulator',
  components: {
    PzSimulatorsTemplate: PzSimulatorsTemplate,
    PzSimulators: PzSimulators
  },
  mixins: [baseSimulator],
  data: function data() {
    return {};
  },
  methods: {
    handleInputNumber: function handleInputNumber() {
      //  this.
    }
  }
};