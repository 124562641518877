var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.datos_solicitante")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "nombres",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", _vm._n($$v));
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellidos",
      label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
      error: _vm.errors.get("apellido_paterno")
    }
  }, [_c("el-input", {
    ref: "apellidos",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.apellidos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellidos", _vm._n($$v));
      },
      expression: "formulario1.apellidos"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      type: "person",
      "type-label": _vm.$t("forms_flujo.form1_persona.UY.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.UY.identity_doc"),
      "number-hidden": _vm.condocumento
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "domicilio",
      label: _vm.$t("forms_flujo.form1_persona.SP.domicilio"),
      error: _vm.errors.get("domicilio")
    }
  }, [_c("el-input", {
    ref: "domicilio",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.domicilio"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.domicilio,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "domicilio", _vm._n($$v));
      },
      expression: "formulario1.domicilio"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estudio",
      label: _vm.$t("forms_flujo.form1_persona.UY.estudio"),
      error: _vm.errors.get("estudio")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.estudio"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.estudio,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "estudio", $$v);
      },
      expression: "formulario1.estudio"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento"),
      error: _vm.errors.get("fecha_nacimiento")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_nacimiento",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.fecha_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_nacimiento", $$v);
      },
      expression: "formulario1.fecha_nacimiento"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "lugar_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.UY.lugar_nacimiento"),
      error: _vm.errors.get("lugar_nacimiento")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.lugar_nacimiento"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.lugar_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "lugar_nacimiento", $$v);
      },
      expression: "formulario1.lugar_nacimiento"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_auxiliar",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono_auxiliar"),
      error: _vm.errors.get("telefono_auxiliar")
    }
  }, [_c("el-input", {
    ref: "telefono_auxiliar",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono_auxiliar"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.telefono_auxiliar,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono_auxiliar", $$v);
      },
      expression: "formulario1.telefono_auxiliar"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_recados",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono_recados"),
      error: _vm.errors.get("telefono_recados")
    }
  }, [_c("el-input", {
    ref: "telefono_recados",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono_recados"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.telefono_recados,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono_recados", $$v);
      },
      expression: "formulario1.telefono_recados"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_Celular",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono_Celular"),
      error: _vm.errors.get("telefono_Celular")
    }
  }, [_c("el-input", {
    ref: "telefono_Celular",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono_Celular"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.telefono_Celular,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono_Celular", $$v);
      },
      expression: "formulario1.telefono_Celular"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("forms_flujo.form1_persona.UY.email"),
      error: _vm.errors.get("email")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.email"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "email", $$v);
      },
      expression: "formulario1.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "antiguedad_domicilio",
      label: _vm.$t("forms_flujo.form1_persona.UY.antiguedad_domicilio"),
      error: _vm.errors.get("antiguedad_domicilio")
    }
  }, [_c("el-input", {
    ref: "antiguedad_domicilio",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.antiguedad_domicilio"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.antiguedad_domicilio,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "antiguedad_domicilio", $$v);
      },
      expression: "formulario1.antiguedad_domicilio"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "edad",
      label: _vm.$t("forms_flujo.form1_persona.UY.edad"),
      error: _vm.errors.get("edad")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.edad"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.edad,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "edad", $$v);
      },
      expression: "formulario1.edad"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estado_civil_id",
      label: _vm.$t("forms_flujo.form1_persona.UY.civil_state"),
      error: _vm.errors.get("estado_civil_id")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.estado_civil_id"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.estado_civil_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "estado_civil_id", $$v);
      },
      expression: "formulario1.estado_civil_id"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "regimen_patrimonial",
      label: _vm.$t("forms_flujo.form1_persona.UY.regimen_patrimonial"),
      error: _vm.errors.get("regimen_patrimonial")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.regimen_patrimonial"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.regimen_patrimonial,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "regimen_patrimonial", $$v);
      },
      expression: "formulario1.regimen_patrimonial"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombre_conyugue",
      label: _vm.$t("forms_flujo.form1_persona.UY.nombre_conyugue"),
      error: _vm.errors.get("nombre_conyugue")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.nombre_conyugue"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.nombre_conyugue,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombre_conyugue", $$v);
      },
      expression: "formulario1.nombre_conyugue"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "num_socio_conyugue",
      label: _vm.$t("forms_flujo.form1_persona.UY.num_socio_conyugue"),
      error: _vm.errors.get("num_socio_conyugue")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.num_socio_conyugue"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.num_socio_conyugue,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "num_socio_conyugue", $$v);
      },
      expression: "formulario1.num_socio_conyugue"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "personas_viven_en_casa",
      label: _vm.$t("forms_flujo.form1_persona.UY.personas_viven_en_casa"),
      error: _vm.errors.get("personas_viven_en_casa")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.personas_viven_en_casa"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.personas_viven_en_casa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "personas_viven_en_casa", $$v);
      },
      expression: "formulario1.personas_viven_en_casa"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "propiedad_vivienda",
      label: _vm.$t("forms_flujo.form1_persona.UY.propiedad_vivienda"),
      error: _vm.errors.get("propiedad_vivienda")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.propiedad_vivienda"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.propiedad_vivienda,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "propiedad_vivienda", $$v);
      },
      expression: "formulario1.propiedad_vivienda"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "destino_prestamo",
      label: _vm.$t("forms_flujo.form1_persona.UY.destino_prestamo"),
      error: _vm.errors.get("destino_prestamo")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.destino_prestamo")
    },
    model: {
      value: _vm.formulario1.destino_prestamo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "destino_prestamo", $$v);
      },
      expression: "formulario1.destino_prestamo"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };