import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getEstadoCivil, getPaises, getSucursales } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonFormExtBnf',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseform2],
  data: function data() {
    return {
      reference: 'formulario2',
      marital_status: [],
      sucursales: [],
      paises: [],
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }],
      provincias: [],
      formulario2: {
        direccion: {},
        provincia: '',
        telefono_fijo: '',
        email: '',
        ref_empresa: '',
        tipo_documento: 1,
        genero: '',
        estado_civil_id: '',
        sucursal_id: '',
        nombre_conyuge: '',
        cedula_conyuge: {
          document: 1
        }
      },
      disable: true,
      rules_formulario2: {
        tipo_vivienda: [required('')],
        direccion: [required('')],
        provincia: [required('')],
        telefono_fijo: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })],
        genero: [required('')],
        estado_civil_id: [required('')],
        sucursal_id: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {
    var data_client = getFromLocal('data_client');
    if (data_client) {
      this.formulario2.telefono_fijo = data_client['telefono_fijo'];
      this.formulario2.email = data_client['email'];
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;
    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    var estado = getEstadoCivil().then(function (res) {
      _this.marital_status = res.data;
    }).catch(function () {
      _this.marital_status = [];
    });
    var paises = getPaises().then(function (res) {
      _this.paises = res.data;
    }).catch(function () {
      _this.paises = [];
    });
    var dep = getDepartamentos().then(function (res) {
      _this.provincias = res.data;
    }).catch(function () {
      _this.provincias = [];
    });
    var getsucursales = getSucursales(this.$route.params.id_custom).then(function (res) {
      _this.sucursales = res.data;
    }).catch(function () {
      _this.sucursales = [];
    });
    Promise.all([estado, dep, paises, getsucursales]).then(function () {}).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    }
  }
};