import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type, EspacioBlanco } from "@/commons/utils/Rules";
import { getEstadoCivil, getSucursales } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonForm1Default',
  components: {
    PzTermsAndConditions: PzTermsAndConditions,
    PzCheckForm: PzCheckForm,
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    var _this = this;
    var check_Fecha = function check_Fecha(rule, value, callback) {
      if (_this.formulario1.jubilado) {
        return callback();
      }
      if (!_this.formulario1.jubilado && !value) {
        return callback(new Error(_this.$t('rules.incomplete')));
      }
      return callback();
    };
    return {
      marital_status: [],
      sucursales: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        telefono: '',
        estado_civil_id: '',
        fecha_nacimiento: '',
        fecha_ingreso: '',
        cuota_maxima: '',
        ingreso_mensual: '',
        destino_prestamo: '',
        jubilado: null,
        identity: {
          document: 1
        },
        comprobante_domicilio: null,
        comprobante_ingresos: null,
        clearing: null,
        sucursal_id: ''
      },
      view_sucursal: false,
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        nombres: [required(''), EspacioBlanco()],
        apellidos: [required(''), EspacioBlanco()],
        telefono: [required('')],
        estado_civil_id: [required('')],
        fecha_nacimiento: [required('')],
        fecha_ingreso: [{
          validator: check_Fecha,
          trigger: 'blur'
        }],
        jubilado: [required('')],
        cuota_maxima: [required(''), type({
          field: '',
          type: 'number'
        })],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })],
        destino_prestamo: [required('')],
        sucursal_id: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    var data_client = getFromLocal('data_client');
    if (data_client) {
      this.formulario1.identity = data_client.identity, this.formulario1.email = data_client.email, this.formulario1.telefono = data_client.telefono;
    }
    this.fetchData();
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    },
    fetchData: function fetchData() {
      var _this2 = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this2.marital_status = res.data;
      }).catch(function () {
        _this2.marital_status = [];
      });
      var getsucursales = '';
      if (this.$session.get('sucursal')) {
        this.view_sucursal = true;
        getsucursales = getSucursales().then(function (res) {
          _this2.sucursales = res.data;
        }).catch(function () {
          _this2.sucursales = [];
        });
      }
      return Promise.all([estado, getsucursales]).then(function () {}).finally(function () {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      });
    }
  }
};