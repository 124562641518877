import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { getBancos } from "@/routes/api/resources";
import { required } from "@/commons/utils/Rules";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import { mapGetters } from "vuex";
export default {
  name: 'PzPersonFormExtImpacta2',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection
  },
  mixins: [baseform2],
  data: function data() {
    return {
      bancos: [],
      tipo_cuenta: [{
        id: 'ahorros',
        name: 'Ahorros'
      }, {
        id: 'monetaria',
        name: 'Monetaria'
      }],
      formulario2: {
        ingreso_mensual: '',
        fecha_ingreso: '',
        banco_deposito: '',
        cuenta_deposito: '',
        telefono_empresa: '',
        familiar: [{
          nombre_completo: '',
          telefono: ''
        }, {
          nombre_completo: '',
          telefono: ''
        }],
        personal: [{
          nombre_completo: '',
          telefono: ''
        }],
        info_extra: {
          profesion: '',
          cargo_ocupa: '',
          empresa_trabajo: '',
          tipo_cuenta: ''
        },
        direccion: {}
      },
      rules_formulario2: {
        ingreso_mensual: [required('')],
        fecha_ingreso: [required('')],
        banco_deposito: [required('')],
        cuenta_deposito: [required('')],
        telefono_empresa: [required('')],
        info_extra: {
          profesion: [required('')],
          cargo_ocupa: [required('')],
          empresa_trabajo: [required('')],
          tipo_cuenta: [required('')]
        },
        familiar: [{
          nombre_completo: [required('')],
          telefono: [required('')]
        }, {
          nombre_completo: [required('')],
          telefono: [required('')]
        }],
        personal: [{
          nombre_completo: [required('')],
          telefono: [required('')]
        }]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['applicant'])),
  created: function created() {
    var _this = this;
    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    var bancos = getBancos().then(function (res) {
      _this.bancos = res.data;
    }).catch(function () {
      _this.bancos = [];
    });
    return Promise.all([bancos]).then(function () {}).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {}
};