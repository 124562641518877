import "core-js/modules/es.math.sign.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import util from '../util/index';
import { merge } from "@/commons/utils/functions";
import settings from "@/settings/settings";

/**
 * 123456 => '123,456'
 *
 * @params {Object} options
 * 
 */

function number(value, format, options) {
  options = merge(settings.filters.number, options);
  format = util.exist(format) ? format : options.format;
  var config = parseFormat(format);
  var number = parseNumber(value);
  var thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ',';
  var decimalSeparator = options.decimalSeparator != null ? options.decimalSeparator : '.';
  config.sign = config.sign || number.sign;
  if (config.unit) {
    var numberWithUnit = addUnit(number.float, config);
    return config.sign + numberWithUnit;
  }
  var int = config.decimals === 0 ? toFixed(number.float, 0) : number.int;
  switch (config.base) {
    case '':
      int = '';
      break;
    case '0,0':
      int = addSeparator(int, thousandsSeparator);
      break;
  }
  var fraction = getFraction(number.float, config.decimals, decimalSeparator);
  return config.sign + int + fraction;
}
Math.sign = function (x) {
  x = +x;
  if (x === 0 || isNaN(x)) {
    return x;
  }
  return x > 0 ? 1 : -1;
};
function parseNumber(num) {
  return {
    float: Math.abs(parseFloat(num)),
    int: Math.abs(parseInt(num)),
    sign: Math.sign(num) < 0 ? '-' : ''
  };
}
function parseFormat() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '0';
  // eslint-disable-next-line no-useless-escape
  var regex = /([\+\-])?([0-9\,]+)?([\.0-9]+)?([a\s]+)?/;
  var matches = string ? string.match(regex) : ['', '', '', '', ''];
  var float = matches[3];
  var decimals = float ? float.match(/0/g).length : 0;
  return {
    sign: matches[1] || '',
    base: matches[2] || '',
    decimals: decimals,
    unit: matches[4] || ''
  };
}
function addUnit(num, config) {
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var si = [{
    value: 1,
    symbol: ""
  }, {
    value: 1E3,
    symbol: "K"
  }, {
    value: 1E6,
    symbol: "M"
  }];
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  num = (num / si[i].value).toFixed(config.decimals).replace(rx, "$1");
  return num + config.unit.replace('a', si[i].symbol);
}
function addSeparator(num, separator) {
  var regex = /(\d+)(\d{3})/;
  var string = num.toString();
  var x = string.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  while (regex.test(x1)) {
    x1 = x1.replace(regex, '$1' + separator + '$2');
  }
  return x1 + x2;
}
function getFraction(num, decimals, separator) {
  var fraction = toFixed(num, decimals).toString().split('.')[1];
  return fraction ? separator + fraction : '';
}
function toFixed(num, precision) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}
export default number;