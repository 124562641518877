import _toConsumableArray from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _typeof from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var ArrayProto = Array.prototype,
  ObjProto = Object.prototype;
var
  // eslint-disable-next-line no-unused-vars
  slice = ArrayProto.slice,
  toString = ObjProto.toString;
var util = {};
util.isArray = function (obj) {
  return Array.isArray(obj);
};
var MAX_ARRAY_INDEX = Math.pow(2, 53) - 1;
util.isArrayLike = function (obj) {
  if (_typeof(obj) !== 'object' || !obj) {
    return false;
  }
  var length = obj.length;
  return typeof length === 'number' && length % 1 === 0 && length >= 0 && length <= MAX_ARRAY_INDEX;
};
util.isObject = function (obj) {
  var type = _typeof(obj);
  return type === 'function' || type === 'object' && !!obj;
};
util.each = function (obj, callback) {
  var i, len;
  if (util.isArray(obj)) {
    for (i = 0, len = obj.length; i < len; i++) {
      if (callback(obj[i], i, obj) === false) {
        break;
      }
    }
  } else {
    for (i in obj) {
      if (callback(obj[i], i, obj) === false) {
        break;
      }
    }
  }
  return obj;
};
util.each(['Arguments', 'Function', 'String', 'Number', 'Date', 'RegExp', 'Error'], function (name) {
  util['is' + name] = function (obj) {
    return toString.call(obj) === '[object ' + name + ']';
  };
});
util.toArray = function (list, start) {
  start = start || 0;
  var i = list.length - start;
  var ret = new Array(i);
  while (i--) {
    ret[i] = list[i + start];
  }
  return ret;
};
util.toNumber = function (value) {
  if (typeof value !== 'string') {
    return value;
  } else {
    var parsed = Number(value);
    return isNaN(parsed) ? value : parsed;
  }
};
util.convertRangeToArray = function (range) {
  return _toConsumableArray(Array(range + 1).keys()).slice(1);
};
util.convertArray = function (value) {
  if (util.isArray(value)) {
    return value;
  } else if (util.isPlainObject(value)) {
    // convert plain object to array.
    var keys = Object.keys(value);
    var i = keys.length;
    var res = new Array(i);
    var key;
    while (i--) {
      key = keys[i];
      res[i] = {
        $key: key,
        $value: value[key]
      };
    }
    return res;
  } else {
    return value || [];
  }
};
function multiIndex(obj, is) {
  // obj,['1','2','3'] -> ((obj['1'])['2'])['3']
  return is.length ? multiIndex(obj[is[0]], is.slice(1)) : obj;
}
util.getPath = function (obj, is) {
  // obj,'1.2.3' -> multiIndex(obj,['1','2','3'])
  return multiIndex(obj, is.split('.'));
};

/**
 * Strict object type check. Only returns true
 * for plain JavaScript objects.
 *
 * @param {*} obj
 * @return {Boolean}
 */

var toString1 = Object.prototype.toString;
var OBJECT_STRING = '[object Object]';
util.isPlainObject = function (obj) {
  return toString1.call(obj) === OBJECT_STRING;
};
util.exist = function (value) {
  return value !== null && typeof value !== 'undefined';
};
export default util;