import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type } from "@/commons/utils/Rules";
import { getLocalidad as _getLocalidad, getDepartamentos, getMunicipios } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
export default {
  name: 'PzPersonFormExtMx',
  components: {
    PzDirection: PzDirection,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseform2],
  data: function data() {
    return {
      properties: [],
      provincias: [],
      employment_relations: [],
      studies: [],
      municipios: [],
      localidad: [],
      formas_de_enterarse: [],
      formulario2: {
        tipo_vivienda: '',
        telefono_fijo: '',
        direccion: {},
        mail: '',
        telefono_empresa: '',
        estudio: '',
        relacion_laboral: '',
        persona_contacto: '',
        telefono_persona_contacto: '',
        comoseentero: '',
        url_trackeo: '',
        ref_empresa: '',
        tipo_documento: 1,
        codigo_postal: '',
        personal: {
          nombre_completo: '',
          telefono: '',
          direccion: '',
          parentesco: ''
        },
        familiar: {
          nombre_completo: '',
          telefono: '',
          direccion: '',
          parentesco: ''
        }
      },
      disable: true,
      rules_formulario2: {
        telefono_fijo: [required('')],
        mail: [type({
          field: '',
          type: 'email'
        })]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  created: function created() {
    //this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var deptos = getDepartamentos().then(function (res) {
        _this.provincias = res.data;
      }).catch(function () {
        _this.provincias = [];
      });
      var municipios = this.getMunicipio();
      var localidades = this.getLocalidad();
      Promise.all([deptos, municipios, localidades]).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    },
    getMunicipio: function getMunicipio() {
      var _this2 = this;
      getMunicipios(this.formulario2.nivel2).then(function (res) {
        _this2.municipios = res.data;
      }).catch(function () {
        _this2.municipios = [];
      });
    },
    getLocalidad: function getLocalidad() {
      var _this3 = this;
      _getLocalidad(this.formulario2.nivel3).then(function (res) {
        _this3.localidad = res.data;
      }).catch(function () {
        _this3.localidad = [];
      });
    }
  }
};