import mime from 'mime-types';
var variables = require("@/assets/sass/variables_clients/".concat(process.env.VUE_APP_CLIENT, "/variables.json"));
export default {
  name: "PzUpload",
  props: {
    file: {
      type: File,
      default: function _default() {
        return null;
      }
    },
    tip: {
      type: String,
      default: null
    },
    tipIcon: {
      type: String,
      default: 'question',
      enum: ['warning', 'info', 'question']
    },
    template: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      file_: null,
      tip_: null,
      template_: null,
      icon: ''
    };
  },
  computed: {
    secondary: function secondary() {
      return variables['--color-secondary'];
    }
  },
  watch: {
    tip: function tip(value) {
      if (value === this.tip_) return;
      this.tip_ = value;
    },
    value: function value(_value) {
      if (_value === this.file_) return;
      this.file_ = _value;
    },
    template: function template(value) {
      if (value === this.template_) return;
      this.template_ = value;
    }
  },
  created: function created() {
    this.tip_ = this.tip;
    this.template_ = this.template;
    this.file_ = this.file;
  },
  methods: {
    addAttachment: function addAttachment(request) {
      this.file_ = request.file;
      this.$emit('input', this.file_);
      this.icon = this.getFileTypeExtencions(request.file.type);
    },
    getFileTypeExtencions: function getFileTypeExtencions(fileType) {
      return mime.extension(fileType);
    },
    getFileExtencionsType: function getFileExtencionsType(fileType) {
      return mime.extension(fileType);
    }
  }
};