var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("transition", {
    attrs: {
      name: "el-loading-fade"
    },
    on: {
      "after-leave": _vm.handleAfterLeave
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "el-loading-mask",
    class: [_vm.customClass, {
      "is-fullscreen": _vm.fullscreen
    }],
    style: {
      backgroundColor: _vm.background || ""
    }
  }, [_c("div", {
    class: "pz-loader-svg"
  }), _vm.text ? _c("p", {
    staticClass: "el-loading-text"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]) : _vm._e()])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };