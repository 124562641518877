var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.descuentoTerceros["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.descuentoTerceros, "captcha", $$v);
      },
      expression: "descuentoTerceros['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.descuento_terceros_factura.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      model: _vm.descuentoTerceros,
      "status-icon": "",
      rules: _vm.rules_descuentoTerceros
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cheque",
      error: _vm.errors.get("cheque")
    }
  }, [_c("label", [_vm._v(_vm._s(_vm.$t("forms_flujo.descuento_terceros_factura.doc_label")) + " - " + _vm._s(_vm.$t("forms_flujo.descuento_terceros_factura.document")))]), _c("pz-upload", {
    attrs: {
      tip: _vm.$t("forms_flujo.descuento_terceros_cheque.document_warning")
    },
    model: {
      value: _vm.descuentoTerceros.cheque,
      callback: function callback($$v) {
        _vm.$set(_vm.descuentoTerceros, "cheque", $$v);
      },
      expression: "descuentoTerceros.cheque"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "documento_librador",
      label: _vm.$t("forms_flujo.descuento_terceros_factura.librador_doc_number"),
      error: _vm.errors.get("documento_librador")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.descuento_terceros_factura.librador_doc_number")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("documento_librador");
      }
    },
    model: {
      value: _vm.descuentoTerceros.documento_librador,
      callback: function callback($$v) {
        _vm.$set(_vm.descuentoTerceros, "documento_librador", $$v);
      },
      expression: "descuentoTerceros.documento_librador"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombre_librador",
      label: _vm.$t("forms_flujo.descuento_terceros_factura.librador_name"),
      error: _vm.errors.get("nombre_librador")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.descuento_terceros_factura.librador_name")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("nombre_librador");
      }
    },
    model: {
      value: _vm.descuentoTerceros.nombre_librador,
      callback: function callback($$v) {
        _vm.$set(_vm.descuentoTerceros, "nombre_librador", $$v);
      },
      expression: "descuentoTerceros.nombre_librador"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_contacto",
      label: _vm.$t("forms_flujo.descuento_terceros_factura.librador_phone"),
      error: _vm.errors.get("telefono_contacto")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.descuento_terceros_factura.librador_phone")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("telefono_contacto");
      }
    },
    model: {
      value: _vm.descuentoTerceros.telefono_contacto,
      callback: function callback($$v) {
        _vm.$set(_vm.descuentoTerceros, "telefono_contacto", _vm._n($$v));
      },
      expression: "descuentoTerceros.telefono_contacto"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("forms_flujo.descuento_terceros_factura.librador_email"),
      error: _vm.errors.get("email")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.descuento_terceros_factura.librador_email")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("email");
      }
    },
    model: {
      value: _vm.descuentoTerceros.email,
      callback: function callback($$v) {
        _vm.$set(_vm.descuentoTerceros, "email", $$v);
      },
      expression: "descuentoTerceros.email"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };