var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-radio-group", {
    staticClass: "center-block"
  }, [_c("el-col", {
    staticClass: "pz-large-margin"
  }, [_c("h3", {
    staticClass: "text-center pz-large-margin pz-color-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.label_1")) + " ")]), _vm._l(_vm.myForm.montos_array, function (value, index) {
    return _c("el-col", {
      key: index
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.myForm.otras_ofertas.final,
        expression: "myForm.otras_ofertas.final"
      }],
      staticClass: "el-col el-col-4",
      attrs: {
        id: index,
        type: "radio"
      },
      domProps: {
        value: index,
        checked: _vm._q(_vm.myForm.otras_ofertas.final, index)
      },
      on: {
        change: function change($event) {
          return _vm.$set(_vm.myForm.otras_ofertas, "final", index);
        }
      }
    }), _c("label", {
      staticClass: "pz-color-primary pz-font-size-lg el-col el-col-20",
      attrs: {
        for: index
      }
    }, [_c("span", {
      staticClass: "pz-font-size-xl pz-color-secondary"
    }, [_vm._v(" " + _vm._s(_vm._f("currency")(value.monto, _vm.myForm.producto.moneda_prestamo.symbol, 0, {
      spaceBetweenAmountAndSymbol: true
    })) + " ")]), _vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.label_2")) + " "), _c("span", {
      staticClass: "pz-font-size-xl pz-color-secondary"
    }, [_vm._v(" " + _vm._s(value.cuota) + " ")]), _vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.label_3")) + " "), _c("span", {
      staticClass: "pz-font-size-xl pz-color-secondary"
    }, [_vm._v(" " + _vm._s(_vm._f("currency")(value.monto_cuota, _vm.myForm.producto.moneda_prestamo.symbol, 0, {
      spaceBetweenAmountAndSymbol: true
    })) + " ")])])]);
  })], 2), _c("el-col", {
    staticClass: "pz-large-margin"
  }, [_c("h3", {
    staticClass: "text-center pz-large-margin pz-color-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.label_4")) + " ")]), _vm._l(_vm.myForm.cuotas_array, function (value, index) {
    return _c("el-col", {
      key: index + 2
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.myForm.otras_ofertas.final,
        expression: "myForm.otras_ofertas.final"
      }],
      staticClass: "el-col el-col-4",
      attrs: {
        id: index + 2,
        type: "radio"
      },
      domProps: {
        value: index + 2,
        checked: _vm._q(_vm.myForm.otras_ofertas.final, index + 2)
      },
      on: {
        change: function change($event) {
          return _vm.$set(_vm.myForm.otras_ofertas, "final", index + 2);
        }
      }
    }), _c("label", {
      staticClass: "pz-color-primary pz-font-size-lg el-col el-col-20",
      attrs: {
        for: index + 2
      }
    }, [_c("span", {
      staticClass: "pz-font-size-xl pz-color-secondary"
    }, [_vm._v(" " + _vm._s(_vm._f("currency")(value.monto, _vm.myForm.producto.moneda_prestamo.symbol, 0, {
      spaceBetweenAmountAndSymbol: true
    })) + " ")]), _vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.label_2")) + " "), _c("span", {
      staticClass: "pz-font-size-xl pz-color-secondary"
    }, [_vm._v(" " + _vm._s(value.cuota) + " ")]), _vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.label_3")) + " "), _c("span", {
      staticClass: "pz-font-size-xl pz-color-secondary"
    }, [_vm._v(" " + _vm._s(_vm._f("currency")(value.monto_cuota, _vm.myForm.producto.moneda_prestamo.symbol, 0, {
      spaceBetweenAmountAndSymbol: true
    })) + " ")])])]);
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };