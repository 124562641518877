var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("pz-title", [_c("p", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(" DATOS DEL SOLICITANTE ")])]), _c("el-row", {
    staticClass: "center-block",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      offset: 1,
      span: 22
    }
  }, [_c("el-form", {
    ref: _vm.reference,
    attrs: {
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    ref: "nombres",
    attrs: {
      type: "text",
      placeholder: "Nombre Completo"
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", $$v);
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "numero_documento",
      error: _vm.errors.get("numero_documento")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    ref: "numero_documento",
    attrs: {
      type: "text",
      placeholder: "C.I"
    },
    model: {
      value: _vm.formulario1.numero_documento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "numero_documento", $$v);
      },
      expression: "formulario1.numero_documento"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "provincia",
      error: _vm.errors.get("provincia")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-select", {
    ref: "provincia",
    attrs: {
      placeholder: "Departamento"
    },
    model: {
      value: _vm.formulario1.provincia,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "provincia", $$v);
      },
      expression: "formulario1.provincia"
    }
  }, _vm._l(_vm.departamentos, function (item) {
    return _c("el-option", {
      key: item.departamento,
      attrs: {
        label: item.departamento,
        value: item.departamento
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-col", {
    staticClass: "margin",
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellidos",
      error: _vm.errors.get("apellidos")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    ref: "apellidos",
    attrs: {
      type: "text",
      placeholder: "Apellidos"
    },
    model: {
      value: _vm.formulario1.apellidos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellidos", $$v);
      },
      expression: "formulario1.apellidos"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "telefono",
      error: _vm.errors.get("telefono")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    ref: "telefono",
    attrs: {
      type: "text",
      placeholder: "Teléfono"
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "email",
      error: _vm.errors.get("email")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    ref: "email",
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: "Email"
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("email");
      }
    },
    model: {
      value: _vm.formulario1.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "email", $$v);
      },
      expression: "formulario1.email"
    }
  })], 1)], 1)], 1), _c("pz-check-form"), _c("el-col", {
    staticClass: "center-block text-center",
    attrs: {
      span: 24
    }
  }, [_vm.producto.showBack ? _c("pz-back-button", {
    attrs: {
      "button-name": "Simular"
    }
  }) : _vm._e(), _c("pz-forward-button")], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };