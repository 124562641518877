//import store from "@/store";

export default {
  name: "PzLoaderMixins",
  data: function data() {
    return {
      instance: true
    };
  },
  mounted: function mounted() {
    var _this = this;
    setTimeout(function () {
      _this.setDown();
    }, 0);
  },
  updated: function updated() {
    var _this2 = this;
    setTimeout(function () {
      _this2.setDown();
    }, 0);
  },
  methods: {
    setDown: function setDown() {
      if (this.instance) {
        this.instance = false;
        this.$store.dispatch('loader/down', {
          trigger: 'route_enter'
        });
      }
    }
  }
};