import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getPaises } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import i18n from "@/lang/lang_config";
import PzTermsAndConditions from "@/commons/forms/Fields/PzTermsAndConditions";
export default {
  name: 'PzPersonForm1Hipotecalo',
  components: {
    PzCheckForm: PzCheckForm,
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument,
    PzDirection: PzDirection,
    PzTermsAndConditions: PzTermsAndConditions
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      paises: [],
      departamentos: [],
      formulario1: {
        nombres: '',
        apellido_paterno: '',
        doc_lugar_emision: '',
        direccion: {},
        identity: {},
        email: '',
        telefono: '',
        info_extra: [{
          padron: '',
          departamento_inmueble: '',
          localidad: '',
          valor_estimado: ''
        }]
      },
      rules_formulario1: {
        nombres: [required('')],
        apellido_paterno: [required('')],
        'identity.document': [required('')],
        'identity.number': [required('')],
        doc_lugar_emision: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })],
        info_extra: {}
      }
    };
  },
  created: function created() {
    this.fetchData();
    this.buildInfoExtraRules();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var departamentos = getDepartamentos().then(function (res) {
        _this.departamentos = res.data;
      }).catch(function () {
        _this.departamentos = [];
      });
      var paises = getPaises().then(function (res) {
        _this.paises = res.data;
      }).catch(function () {
        _this.paises = [];
      });
      return Promise.all([departamentos, paises]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    },
    buildInfoExtraRules: function buildInfoExtraRules() {
      // Dynamically create validation rules for each inmueble
      this.rules_formulario1.info_extra = this.formulario1.info_extra.reduce(function (rules, inmueble, index) {
        rules["info_extra.".concat(index, ".padron")] = [required(i18n.tc('labels.padron'))];
        rules["info_extra.".concat(index, ".departamento_inmueble")] = [required(i18n.tc('labels.departamento_inmueble'))];
        // rules[`info_extra.${index}.localidad`] = [required(i18n.tc('labels.localidad'))];
        // rules[`info_extra.${index}.valor_estimado`] = [required(i18n.tc('labels.valor_estimado'))];
        return rules;
      }, {});
    },
    addInmueble: function addInmueble() {
      this.formulario1.info_extra.push({
        padron: '',
        departamento_inmueble: '',
        localidad: '',
        valor_estimado: ''
      });
      this.buildInfoExtraRules();
    },
    removeInmueble: function removeInmueble(index) {
      if (index > 0) {
        this.formulario1.info_extra.splice(index, 1);
        this.buildInfoExtraRules();
      }
    }
  }
};