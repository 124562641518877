import PzOfertaSolicitudDefault from "@/components/Flujo/Simuladores/Ofertas/baseOferta";
import moment from "moment";
export default {
  name: "PzOfertaSolicitudTableExtra",
  mixins: [PzOfertaSolicitudDefault],
  data: function data() {
    return {
      date: '',
      text_tasa: ''
    };
  },
  computed: {
    date_fin: function date_fin() {
      console.log('getDateFin');
      console.log(this.myForm.form.plazo);
      console.log(this.myForm.producto.term_type.cant_days);
      return moment().add(this.myForm.producto.term_type.cant_days * this.myForm.form.plazo, 'days').format(this.$t('date_format'));
    }
  },
  mounted: function mounted() {
    if (process.env.VUE_APP_CLIENT == 'impacta') {
      this.text_tasa = this.$t('forms_flujo.oferta_solicitud_table.tasa_semanal');
    } else {
      this.text_tasa = this.$t('forms_flujo.oferta_solicitud_table.tasa');
    }
  },
  created: function created() {
    this.getDate();
  },
  methods: {
    getDate: function getDate() {
      {
        this.date = moment().add(this.myForm.producto.term_type.cant_days, 'days').format(this.$t('date_format'));
      }
    }
  }
};