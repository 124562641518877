var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("aside", {
    staticClass: "h-100"
  }, [_vm.ambientetest ? _c("div", {
    staticClass: "animacion-homologacion parpadea"
  }, [_c("font-awesome-icon", {
    staticStyle: {
      color: "orange"
    },
    attrs: {
      icon: "exclamation-triangle"
    }
  }), _vm._v(" " + _vm._s(_vm.ambientetest) + " ")], 1) : _vm._e(), _c("pz-banner"), _c("el-container", {
    directives: [{
      name: "pz-loading",
      rawName: "v-pz-loading.fullscreen.lock",
      value: _vm.loader.run,
      expression: "loader.run",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    attrs: {
      "pz-loading-text": _vm.loader.text,
      "data-iframe-height": ""
    }
  }, [_c("el-main", {
    staticClass: "pz-main"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("router-view", {
    key: _vm.key
  })], 1), _c("el-backtop", {
    attrs: {
      target: ".pz-main"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };