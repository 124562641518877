import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import Cookies from 'js-cookie';
import { isEmpty } from "element-ui/src/utils/util";
import settings from '@/settings/settings';
import { capitalize } from "@/commons/utils/functions";
var currentStorage = settings.storage;
function excuteStorageMethod(action, params) {
  action = action.toLowerCase() + capitalize(currentStorage);
  return storage[action].apply(null, params);
}
export function clearAll() {
  sessionStorage.clear();
}
export function saveInLocal(key, data) {
  excuteStorageMethod('set', [key, data]);
}
export function removeFromLocal(key) {
  excuteStorageMethod('delete', [key]);
}
export function getFromLocal(key) {
  try {
    if (!isEmpty(key)) {
      return excuteStorageMethod('get', [key]);
    }
  } catch (e) {
    excuteStorageMethod('delete', [key]);
    return null;
  }
}

///////
export var storage = {
  // eslint-disable-next-line no-unused-vars
  setLocalStorage: function setLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  // eslint-disable-next-line no-unused-vars
  deleteLocalStorage: function deleteLocalStorage(key) {
    localStorage.removeItem(key);
  },
  getLocalStorage: function getLocalStorage(key) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  },
  // eslint-disable-next-line no-unused-vars
  setCookies: function setCookies(key, data) {
    return Cookies.set(key, JSON.stringify(data));
  },
  // eslint-disable-next-line no-unused-vars
  deleteCookies: function deleteCookies(key) {
    return Cookies.remove(key);
  },
  // eslint-disable-next-line no-unused-vars
  getCookies: function getCookies(key) {
    if (Cookies.get(key)) {
      return JSON.parse(Cookies.get(key));
    }
    return null;
  }
};