var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario2["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "captcha", $$v);
      },
      expression: "formulario2['captcha']"
    }
  }, [_c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      model: _vm.formulario2,
      "status-icon": "",
      rules: _vm.rules_formulario2
    },
    slot: "formulario"
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "rut",
      label: _vm.$t("forms_flujo.form2_empresa.UY.doc_number"),
      error: _vm.errors.get("rut")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_empresa.UY.doc_number")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("rut");
      }
    },
    model: {
      value: _vm.formulario2.rut,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "rut", $$v);
      },
      expression: "formulario2.rut"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "company",
      label: _vm.$t("forms_flujo.form2_empresa.UY.company"),
      error: _vm.errors.get("company")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_empresa.UY.company")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("company");
      }
    },
    model: {
      value: _vm.formulario2.company,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "company", $$v);
      },
      expression: "formulario2.company"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "razonsocial",
      label: _vm.$t("forms_flujo.form2_empresa.UY.company_name"),
      error: _vm.errors.get("razonsocial")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_empresa.UY.company_name")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("razonsocial");
      }
    },
    model: {
      value: _vm.formulario2.razonsocial,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "razonsocial", $$v);
      },
      expression: "formulario2.razonsocial"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "comienzoactividad",
      label: _vm.$t("forms_flujo.form2_empresa.UY.activity_start"),
      error: _vm.errors.get("comienzoactividad")
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: _vm.$t("forms_flujo.form2_empresa.UY.activity_start"),
      format: "dd-MM-yyyy",
      "value-format": "dd-MM-yyyy"
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("comienzoactividad");
      }
    },
    model: {
      value: _vm.formulario2.comienzoactividad,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "comienzoactividad", $$v);
      },
      expression: "formulario2.comienzoactividad"
    }
  })], 1), _c("el-form-item", {
    staticClass: "bordered",
    attrs: {
      prop: "rubro_id",
      label: _vm.$t("forms_flujo.form2_empresa.UY.activity_type"),
      error: _vm.errors.get("rubro_id")
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: _vm.$t("forms_flujo.form2_empresa.UY.activity_type")
    },
    model: {
      value: _vm.formulario2.rubro_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "rubro_id", $$v);
      },
      expression: "formulario2.rubro_id"
    }
  }, _vm._l(_vm.rubros, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.rubro,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "direccion",
      label: _vm.$t("forms_flujo.form2_empresa.UY.address"),
      error: _vm.errors.get("direccion")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_empresa.UY.address")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("direccion");
      }
    },
    model: {
      value: _vm.formulario2.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "direccion", $$v);
      },
      expression: "formulario2.direccion"
    }
  })], 1)], 1), _c("el-col", {
    staticClass: "center-block text-center",
    attrs: {
      slot: "buttons",
      span: 24
    },
    slot: "buttons"
  }, [_vm.showBack ? _c("pz-back-button", {
    attrs: {
      back: _vm.handleBack
    }
  }) : _vm._e(), _c("el-button", {
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.addSocios
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_empresa.UY.add_partner")) + " ")]), _c("pz-forward-button", {
    attrs: {
      forward: _vm.handleSave
    }
  })], 1), _c("el-row", {
    attrs: {
      slot: "button_footer"
    },
    slot: "button_footer"
  }, [_vm.formulario2.socios.length > 0 ? _c("el-col", {
    staticClass: "el-row",
    staticStyle: {
      "text-align": "center",
      width: "100%",
      color: "white",
      "font-size": "medium"
    },
    attrs: {
      span: 24
    }
  }, [_c("table", {
    staticClass: "table"
  }, [_c("tbody", _vm._l(_vm.formulario2.socios, function (socio, index) {
    return _c("tr", {
      key: index
    }, [_c("td", [_c("el-form-item", {
      key: socio.key,
      attrs: {
        label: _vm.$t("forms_flujo.form2_empresa.UY.partner.doc_number"),
        prop: "socios." + index + ".documento",
        rules: _vm.rules_socios.documento
      }
    }, [_c("el-input", {
      attrs: {
        type: "text",
        "auto-complete": "no",
        placeholder: _vm.$t("forms_flujo.form2_empresa.UY.partner.doc_number")
      },
      model: {
        value: socio.documento,
        callback: function callback($$v) {
          _vm.$set(socio, "documento", $$v);
        },
        expression: "socio.documento"
      }
    })], 1)], 1), _c("td", [_c("el-form-item", {
      key: socio.key,
      attrs: {
        label: _vm.$t("forms_flujo.form2_empresa.UY.partner.name"),
        prop: "socios." + index + ".nombre",
        rules: _vm.rules_socios.nombre
      }
    }, [_c("el-input", {
      attrs: {
        type: "text",
        "auto-complete": "no",
        placeholder: _vm.$t("forms_flujo.form2_empresa.UY.partner.name")
      },
      model: {
        value: socio.nombre,
        callback: function callback($$v) {
          _vm.$set(socio, "nombre", $$v);
        },
        expression: "socio.nombre"
      }
    })], 1)], 1), _c("td", [_c("el-form-item", {
      key: socio.key,
      attrs: {
        label: _vm.$t("forms_flujo.form2_empresa.UY.partner.surename"),
        prop: "socios." + index + ".apellido",
        rules: _vm.rules_socios.apellido
      }
    }, [_c("el-input", {
      attrs: {
        type: "text",
        "auto-complete": "no",
        placeholder: _vm.$t("forms_flujo.form2_empresa.UY.partner.surename")
      },
      model: {
        value: socio.apellido,
        callback: function callback($$v) {
          _vm.$set(socio, "apellido", $$v);
        },
        expression: "socio.apellido"
      }
    })], 1)], 1), _c("td", [_c("el-button", {
      staticClass: "el-dele-socios",
      attrs: {
        type: "danger",
        icon: "el-icon-delete",
        circle: ""
      },
      on: {
        click: function click($event) {
          return _vm.removeSocios(index);
        }
      }
    })], 1)]);
  }), 0)])]) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };