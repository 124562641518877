var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form3_persona.SP.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    staticClass: "row justify-content-center",
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    staticClass: "row justify-content-center",
    attrs: {
      xl: {
        span: 24
      },
      lg: {
        span: 24
      },
      md: {
        span: 24
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "is_unnax_face_id_completed",
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "is_unnax_face_id_completed",
    attrs: {
      type: "hidden"
    },
    model: {
      value: _vm.formulario1.is_unnax_face_id_completed,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "is_unnax_face_id_completed", $$v);
      },
      expression: "formulario1.is_unnax_face_id_completed"
    }
  })], 1), _vm.iframe_url ? _c("pz-unnax-face-id-iframe", {
    attrs: {
      url: _vm.iframe_url
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };