import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { isEmpty } from "@/commons/utils/functions";
import { getDocumentsType } from "@/routes/api/resources";
import { required } from "@/commons/utils/Rules";
export default {
  name: "PzIdentityDocument",
  components: {},
  props: {
    prop: {
      default: null,
      type: String
    },
    value: {
      type: [Object],
      default: function _default() {
        return {
          document: '',
          number: ''
        };
      }
    },
    typeHidden: {
      type: Boolean,
      default: false
    },
    typeLabel: {
      type: String,
      default: null
    },
    numberLabel: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'person'
    },
    numberHidden: {
      type: Boolean,
      default: false
    },
    noRequerido: {
      type: Boolean,
      default: false
    },
    errors: {
      type: String,
      default: null
    },
    typeActive: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    var _this = this;
    var check_doc = function check_doc(rule, value, callback) {
      if (!_this.regla) return callback();
      var pattern = new RegExp(_this.regla.toString());
      if (pattern.test(value)) {
        return callback();
      }
      return callback(new Error(_this.$t('rules.identity_rule')));
    };
    return {
      listDocuments: [],
      tipo: '',
      numero_documento: '',
      tipo_documento: '',
      regla: '',
      rule: [{
        validator: check_doc,
        trigger: 'blur'
      }]
    };
  },
  watch: {
    value: function value(val) {
      this.setTipoDocumento(val);
      this.setNumeroDocumento(val);
    },
    type: function type(val) {
      this.setTipo(val);
    },
    // eslint-disable-next-line no-unused-vars
    numero_documento: function numero_documento(val) {
      this.handleChange();
    },
    // eslint-disable-next-line no-unused-vars
    tipo_documento: function tipo_documento(val) {
      this.handleChange();
    }
  },
  created: function created() {
    var _this2 = this;
    if (!this.noRequerido) this.rule.push(required(''));
    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    this.setTipoDocumento(this.value);
    this.setNumeroDocumento(this.value);
    this.setTipo(this.type);
    var doc = this.documentsType();
    Promise.all([doc]).finally(function () {
      _this2.$store.dispatch('loader/down', {
        trigger: _this2.$options.name
      });
    });
  },
  methods: {
    handleChange: function handleChange() {
      var value = this.getInputValue();
      var key = this.getInputKey();
      if (this.listDocuments.length > 0 && key) {
        this.regla = this.listDocuments.find(function (datos) {
          return datos.id == key;
        });
        this.regla = this.regla.rules;
      }
      var docs = {
        document: key,
        number: value
      };
      this.$emit('input', docs);
    },
    documentsType: function documentsType() {
      var _this3 = this;
      //if (!this.typeHidden) {
      console.log('active ' + this.typeActive);
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      getDocumentsType(this.tipo, this.typeActive).then(function (res) {
        _this3.listDocuments = res.data;
        if (_this3.listDocuments.length === 1) {
          _this3.tipo_documento = _this3.listDocuments[0].id;
          _this3.regla = _this3.listDocuments[0].rules;
        } else {
          var self = _this3;
          var found = null;
          if (!isEmpty(_this3.value.document)) {
            found = _this3.listDocuments.filter(function (value) {
              return value.id.toString() === self.value.document.toString();
            });
          }
          if (isEmpty(found)) {
            _this3.tipo_documento = _this3.listDocuments[0].id;
          }
        }
        _this3.handleChange();
      }).catch().finally(function () {
        _this3.$store.dispatch('loader/down', {
          trigger: _this3.$options.name
        });
      });
      // }
    },
    getInputKey: function getInputKey() {
      return this.tipo_documento;
    },
    getInputValue: function getInputValue() {
      return this.numero_documento;
    },
    setTipoDocumento: function setTipoDocumento(value) {
      var input = this.getInputValue();
      if (input === value.document) return;
      this.tipo_documento = value.document;
    },
    setNumeroDocumento: function setNumeroDocumento(value) {
      var input = this.getInputKey();
      if (input === value.number) return;
      this.numero_documento = value.number;
    },
    setTipo: function setTipo(value) {
      var input = this.tipo;
      if (input === value) return;
      this.tipo = value;
    }
  }
};