var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.datos_conyugue")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombre_conyuge",
      label: _vm.$t("forms_flujo.form1_persona.UY.nombre_conyuge"),
      error: _vm.errors.get("nombre_conyuge")
    }
  }, [_c("el-input", {
    ref: "nombre_conyuge",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.nombre_conyuge")
    },
    model: {
      value: _vm.formulario1.nombre_conyuge,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombre_conyuge", $$v);
      },
      expression: "formulario1.nombre_conyuge"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nacionalidad_conyuge",
      label: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
    }
  }, [_c("el-select", {
    ref: "nacionalidad_conyuge",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
    },
    model: {
      value: _vm.formulario1.nacionalidad_conyuge,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nacionalidad_conyuge", $$v);
      },
      expression: "formulario1.nacionalidad_conyuge"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nacionalidad,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_nacimiento_conyuge",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento"),
      error: _vm.errors.get("fecha_nacimiento_conyuge")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_nacimiento_conyuge",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento")
    },
    model: {
      value: _vm.formulario1.fecha_nacimiento_conyuge,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_nacimiento_conyuge", $$v);
      },
      expression: "formulario1.fecha_nacimiento_conyuge"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "lugar_nacimiento_conyuge",
      label: _vm.$t("forms_flujo.form1_persona.SP.lugar_nacimiento"),
      error: _vm.errors.get("lugar_nacimiento_conyuge")
    }
  }, [_c("el-input", {
    ref: "lugar_nacimiento_conyuge",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.lugar_nacimiento")
    },
    model: {
      value: _vm.formulario1.lugar_nacimiento_conyuge,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "lugar_nacimiento_conyuge", $$v);
      },
      expression: "formulario1.lugar_nacimiento_conyuge"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "bienes_separados",
      error: _vm.errors.get("bienes_separados"),
      label: _vm.$t("forms_flujo.form1_persona.UY.con_separacion_bienes")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "SI",
      "inactive-text": "NO"
    },
    model: {
      value: _vm.formulario1.bienes_separados,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "bienes_separados", $$v);
      },
      expression: "formulario1.bienes_separados"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity_conyugue",
      type: "person",
      "type-label": _vm.$t("forms_flujo.form1_persona.UY.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.UY.identity_doc")
    },
    model: {
      value: _vm.formulario1.identity_conyugue,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity_conyugue", $$v);
      },
      expression: "formulario1.identity_conyugue"
    }
  })], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "doc_vencimiento_conyugue",
      label: _vm.$t("forms_flujo.form1_persona.UY.doc_vencimiento"),
      error: _vm.errors.get("doc_vencimiento_conyugue")
    }
  }, [_c("el-date-picker", {
    ref: "doc_vencimiento_conyugue",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.doc_vencimiento")
    },
    model: {
      value: _vm.formulario1.doc_vencimiento_conyugue,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "doc_vencimiento_conyugue", $$v);
      },
      expression: "formulario1.doc_vencimiento_conyugue"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "doc_lugar_emision_conyugue",
      label: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision"),
      error: _vm.errors.get("doc_lugar_emision_conyugue")
    }
  }, [_c("el-input", {
    ref: "doc_lugar_emision_conyugue",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
    },
    model: {
      value: _vm.formulario1.doc_lugar_emision_conyugue,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "doc_lugar_emision_conyugue", $$v);
      },
      expression: "formulario1.doc_lugar_emision_conyugue"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };