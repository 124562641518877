import { required } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonForm2FenoresteNew',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      marital_status: [],
      sucursales: [],
      formulario1: {
        ramo_trabajo: '',
        telefono_empresa: '',
        fecha_ingreso: '',
        cargo_ocupa: '',
        ingreso_mensual: '',
        info_extra: {
          empresa_trabajo: '',
          otros_ingresos: '',
          fuentes_otros_ingresos: '',
          domicilio: ''
        }
      }
    };
  },
  created: function created() {},
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    }
  }
};