var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.personas_expuestas")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.is_pep",
      label: _vm.$t("forms_flujo.form1_persona.UY.is_pep"),
      error: _vm.errors.get("info_extra.is_pep")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "SI",
      "inactive-text": "NO"
    },
    model: {
      value: _vm.formulario1.info_extra.is_pep,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "is_pep", $$v);
      },
      expression: "formulario1.info_extra.is_pep"
    }
  }), _c("el-col", {
    staticClass: "pz-font-size-sm",
    staticStyle: {
      transform: "translateY(-18px)"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      title: "Personas Políticamente Expuestas",
      width: "400",
      trigger: "hover",
      content: _vm.$t("forms_flujo.form1_persona.UY.is_pep_info")
    }
  }, [_c("i", {
    staticClass: "el-icon-info pz-font-size-bs pz-color-secondary",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.relacion_pep",
      label: _vm.$t("forms_flujo.form1_persona.UY.relacion_pep"),
      error: _vm.errors.get("info_extra.relacion_pep")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "SI",
      "inactive-text": "NO"
    },
    model: {
      value: _vm.formulario1.info_extra.relacion_pep,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "relacion_pep", $$v);
      },
      expression: "formulario1.info_extra.relacion_pep"
    }
  }), _c("el-col", {
    staticClass: "pz-font-size-sm"
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      title: "RELACIÓN",
      width: "400",
      trigger: "hover",
      content: _vm.$t("forms_flujo.form1_persona.UY.relacion_pep_info")
    }
  }, [_c("i", {
    staticClass: "el-icon-info pz-font-size-bs pz-color-secondary",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })])], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.cargo_pep",
      label: _vm.$t("forms_flujo.form1_persona.UY.cargo_pep"),
      error: _vm.errors.get("info_extra.cargo_pep")
    }
  }, [_c("el-input", {
    ref: "info_extra.cargo_pep",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.cargo_pep")
    },
    model: {
      value: _vm.formulario1.info_extra.cargo_pep,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "cargo_pep", $$v);
      },
      expression: "formulario1.info_extra.cargo_pep"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };