var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSave
    },
    model: {
      value: _vm.formularioProducts["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formularioProducts, "captcha", $$v);
      },
      expression: "formularioProducts['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.select_products_all.title")) + " ")]), _c("el-col", {
    attrs: {
      slot: "formulario",
      xl: {
        span: 8,
        offset: 8
      },
      lg: {
        span: 10,
        offset: 7
      },
      md: {
        span: 12,
        offset: 6
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    },
    slot: "formulario"
  }, [_c("el-form", {
    ref: "formularioProducts",
    attrs: {
      model: _vm.formularioProducts,
      "status-icon": "",
      rules: _vm.rules_formularioProducts
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "selected",
      label: _vm.$t("forms_flujo.select_products_all.products"),
      error: _vm.errors.get("selected")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.select_products_all.products")
    },
    on: {
      blur: function blur($event) {
        return _vm.errors.clear("selected");
      }
    },
    model: {
      value: _vm.formularioProducts.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.formularioProducts, "selected", $$v);
      },
      expression: "formularioProducts.selected"
    }
  }, _vm._l(_vm.productos, function (producto) {
    return _c("el-option", {
      key: producto.code_string,
      attrs: {
        label: producto.nombre_comercial,
        value: producto.code_string
      }
    });
  }), 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };