var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.datos_personales")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "nombres",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nombre")
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", $$v);
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellido_paterno",
      label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
      error: _vm.errors.get("apellido_paterno")
    }
  }, [_c("el-input", {
    ref: "apellido_paterno",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.apellidos")
    },
    model: {
      value: _vm.formulario1.apellido_paterno,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellido_paterno", $$v);
      },
      expression: "formulario1.apellido_paterno"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      type: "person",
      "type-label": _vm.$t("forms_flujo.form1_persona.UY.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.UY.identity_doc")
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "doc_lugar_emision",
      label: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
    }
  }, [_c("el-select", {
    ref: "doc_lugar_emision",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
    },
    model: {
      value: _vm.formulario1.doc_lugar_emision,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "doc_lugar_emision", $$v);
      },
      expression: "formulario1.doc_lugar_emision"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nombre,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
      }
    });
  }), 1)], 1)], 1)], 1), _c("pz-direction", {
    model: {
      value: _vm.formulario1.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "direccion", $$v);
      },
      expression: "formulario1.direccion"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("forms_flujo.form1_persona.UY.email"),
      error: _vm.errors.get("email")
    }
  }, [_c("el-input", {
    ref: "email",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.email")
    },
    model: {
      value: _vm.formulario1.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "email", $$v);
      },
      expression: "formulario1.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono"),
      error: _vm.errors.get("telefono")
    }
  }, [_c("el-input", {
    ref: "telefono",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono")
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.producto.conditions, function (condition) {
    return _c("el-col", {
      key: condition.id,
      staticClass: "row justify-content-between",
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("pz-terms-and-conditions", {
      attrs: {
        prop: "condition_" + condition.slug,
        name: condition.nombre
      },
      model: {
        value: _vm.formulario1.conditions[condition.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.formulario1.conditions, condition.slug, $$v);
        },
        expression: "formulario1.conditions[condition.slug]"
      }
    }, [_c("div", {
      domProps: {
        innerHTML: _vm._s(condition.contenido)
      }
    })])], 1);
  }), 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center pz-large-margin",
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "pz-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.datos_inmueble")) + " ")])])], 1), _vm._l(_vm.formulario1.info_extra, function (inmueble, index) {
    return _c("el-row", [_c("el-row", [_c("div", {
      staticClass: "pz-title"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c("div", {
      staticStyle: {
        "margin-bottom": "10px"
      }
    })]), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("el-form-item", {
      attrs: {
        prop: "info_extra." + index + ".padron",
        label: _vm.$t("forms_flujo.form1_persona.UY.padron"),
        error: _vm.errors.get("info_extra.padron")
      }
    }, [_c("el-input", {
      ref: "info_extra." + index + ".padron",
      refInFor: true,
      attrs: {
        type: "text",
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.padron")
      },
      model: {
        value: inmueble.padron,
        callback: function callback($$v) {
          _vm.$set(inmueble, "padron", $$v);
        },
        expression: "inmueble.padron"
      }
    })], 1)], 1), _c("el-col", {
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("el-form-item", {
      attrs: {
        prop: "info_extra." + index + ".departamento_inmueble",
        label: _vm.$t("forms_flujo.form1_persona.UY.departamento"),
        error: _vm.errors.get("info_extra.departamento")
      }
    }, [_c("el-select", {
      ref: "info_extra." + index + ".departamento_inmueble",
      refInFor: true,
      attrs: {
        clearable: "",
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.departamento")
      },
      model: {
        value: inmueble.departamento_inmueble,
        callback: function callback($$v) {
          _vm.$set(inmueble, "departamento_inmueble", $$v);
        },
        expression: "inmueble.departamento_inmueble"
      }
    }, _vm._l(_vm.departamentos, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.departamento,
          value: item.id,
          placeholder: _vm.$t("forms_flujo.form1_persona.UY.departamento")
        }
      });
    }), 1)], 1)], 1)], 1), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("el-form-item", {
      attrs: {
        prop: "info_extra." + index + ".localidad",
        label: _vm.$t("forms_flujo.form1_persona.UY.localidad"),
        error: _vm.errors.get("info_extra.localidad")
      }
    }, [_c("el-input", {
      ref: "info_extra." + index + ".localidad",
      refInFor: true,
      attrs: {
        type: "text",
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.localidad")
      },
      model: {
        value: inmueble.localidad,
        callback: function callback($$v) {
          _vm.$set(inmueble, "localidad", $$v);
        },
        expression: "inmueble.localidad"
      }
    })], 1)], 1), _c("el-col", {
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("el-form-item", {
      attrs: {
        prop: "info_extra." + index + ".valor_estimado",
        label: _vm.$t("forms_flujo.form1_persona.UY.valor_estimado"),
        error: _vm.errors.get("info_extra.valor_estimado")
      }
    }, [_c("el-input", {
      ref: "info_extra." + index + ".valor_estimado",
      refInFor: true,
      attrs: {
        type: "text",
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.valor_estimado")
      },
      model: {
        value: inmueble.valor_estimado,
        callback: function callback($$v) {
          _vm.$set(inmueble, "valor_estimado", $$v);
        },
        expression: "inmueble.valor_estimado"
      }
    })], 1)], 1)], 1), index > 0 ? _c("el-row", [_c("el-button", {
      staticStyle: {
        float: "right"
      },
      attrs: {
        type: "danger"
      },
      on: {
        click: function click($event) {
          return _vm.removeInmueble(index);
        }
      }
    }, [_vm._v(" Eliminar ")])], 1) : _vm._e()], 1);
  }), _c("el-button", {
    on: {
      click: _vm.addInmueble
    }
  }, [_vm._v("Nuevo inmueble")])], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };