import { getBancos } from '@/routes/api/resources';
import { required } from "@/commons/utils/Rules";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
export default {
  name: 'PzBaseFormDescuentos',
  mixins: [baseFormFlujo],
  data: function data() {
    return {
      reference: 'descuentoTerceros',
      bancos: [],
      descuentoTerceros: {
        personaFisica: false,
        cheque: ''
      },
      rules_descuentoTerceros: {
        cheque: [required('Copia del documento')]
      }
    };
  },
  beforeMount: function beforeMount() {
    var _this = this;
    getBancos().then(function (res) {
      _this.bancos = res.data;
    }).catch(function () {
      _this.bancos = [];
    });
  },
  mounted: function mounted() {
    this.descuentoTerceros.cheque = '';
  },
  methods: {}
};