import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getMunicipios, getLocalidad } from "@/routes/api/resources";
import baseGarante from '../baseGarante';
import { mapGetters } from "vuex";
export default {
  name: 'PzGaranteFormMx',
  components: {},
  mixins: [baseGarante],
  data: function data() {
    var _this = this;
    var checCIGarante = function checCIGarante(rule, value, callback) {
      if (_this.form_product.cedula === _this.formulario_garante.numero_documento) {
        return callback(new Error(_this.$t('forms_flujo.form_garante.MX.error_applicant')));
      } else {
        return callback();
      }
    };
    return {
      employment_relations: [],
      departamentos: [],
      municipios: [],
      localidad: [],
      provincias: [],
      formulario_garante: {
        numero_documento: '',
        telefono: '',
        fecha_nacimiento: '',
        relacion_laboral: '',
        telefono_empresa: '',
        fecha_ingreso: '',
        ingreso_mensual: '',
        url_trackeo: '',
        tipo_documento: 1,
        nivel2: '',
        nivel3: '',
        nivel4: '',
        nivel5: ''
      },
      disable: true,
      rules_formulario_garante: {
        numero_documento: [required(''), {
          validator: checCIGarante,
          trigger: 'blur'
        }],
        telefono: [required('')],
        nivel2: [required('')],
        nivel3: [required('')],
        nivel4: [required('')],
        nivel5: [required('')],
        relacion_laboral: [required('')],
        telefono_empresa: [required('')],
        fecha_ingreso: [required('')],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['product', 'form_product', 'current_step'])),
  provide: function provide() {
    return {
      myForm: this
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.inlocal = 'formGarante';
      this.reference = 'formulario_garante';
      var deptos = getDepartamentos().then(function (res) {
        _this2.provincias = res.data;
      }).catch(function () {
        _this2.provincias = [];
      });
      var municipios = getMunicipios(this.formulario_garante.nivel2).then(function (res) {
        _this2.municipios = res.data;
      }).catch(function () {
        _this2.municipios = [];
      });
      var localidades = getLocalidad(this.formulario_garante.nivel3).then(function (res) {
        _this2.localidad = res.data;
      }).catch(function () {
        _this2.localidad = [];
      });
      return Promise.all([deptos, municipios, localidades]).finally(function () {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      });
    }
  }
};