var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-col", {
    directives: [{
      name: "pz-loading",
      rawName: "v-pz-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      span: 24,
      "data-iframe-height": ""
    }
  }, [_c("pz-title", [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.final_doc.title")) + " ")]), _c("span", {
    attrs: {
      slot: "sub_title"
    },
    slot: "sub_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.final_doc.sub_title")) + " ")])]), _c("el-col", {
    staticClass: "row justify-content-center"
  }, [_c("el-col", {
    staticClass: "pz-font-size-md",
    attrs: {
      span: 20,
      offset: 2
    }
  }, [_c("p", [_vm._v(" " + _vm._s(_vm.$t("views.final.data_received")) + " ")]), _c("p", [_vm._v(" " + _vm._s(_vm.$t("views.final.data_received_2")) + " ")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };