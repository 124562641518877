import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type, EspacioBlanco, onlyLeter } from "@/commons/utils/Rules";
import { getPaises } from "@/routes/api/resources";
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { isEmpty } from "@/commons/utils/functions";
import PzPhoneNumber from "@/commons/forms/Fields/PzPhoneNumber";
export default {
  name: 'PzPersonFormEs1',
  components: {
    PzPhoneNumber: PzPhoneNumber,
    PzIdentityDocument: PzIdentityDocument,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    var _this = this;
    var check_Terms = function check_Terms(rule, value, callback) {
      if (!_this.formulario1.info_extra.terminos_condiciones) {
        return callback(new Error(_this.$t('rules.should_accept_terms2')));
      } else {
        return callback();
      }
    };
    var check_mail = function check_mail(rule, value, callback) {
      if (!value) {
        return callback(new Error(_this.$t('rules.field_mandatory', {
          name: 'Confirmar Correo electrónico'
        })));
      } else if (value != _this.formulario1.mail) {
        return callback(new Error(_this.$t('rules.check_email')));
      } else {
        return callback();
      }
    };
    return {
      marital_status: [],
      tipo_contrato: [{
        id: 'Contratado',
        name: 'Contratado'
      }, {
        id: 'Pre-Pago',
        name: 'Pre-Pago'
      }],
      paises: [],
      suggest: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        telefono: {},
        nacionalidad: '',
        fecha_nacimiento: '',
        mail: '',
        destino_prestamo: '',
        cuota_maxima: '',
        ingreso_mensual: '',
        identity: {},
        info_extra: {
          terminos_condiciones: '',
          autorizo: '',
          autorizo2: ''
        },
        confirmmail: ''
      },
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        nombres: [required(''), EspacioBlanco(), onlyLeter()],
        apellidos: [required(''), EspacioBlanco(), onlyLeter()],
        fecha_nacimiento: [required('')],
        nacionalidad: [required('')],
        mail: [required(''), type({
          field: '',
          type: 'email'
        })],
        cuota_maxima: [required(''), type({
          field: '',
          type: 'number'
        })],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })],
        destino_prestamo: [required('')],
        estado_civil_id: [required('')],
        telefono: [type({
          field: '',
          type: 'number'
        })],
        'info_extra.terminos_condiciones': [{
          validator: check_Terms,
          trigger: 'blur'
        }],
        confirmmail: [{
          validator: check_mail,
          trigger: 'blur'
        }]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
    this.suggest = Object.values(this.$t('credit_motive')).map(function (value) {
      return {
        value: value
      };
    });
  },
  methods: {
    fetchData: function fetchData() {
      var _this2 = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var paises = getPaises().then(function (res) {
        _this2.paises = res.data;
      }).catch(function () {
        _this2.paises = [];
      });
      Promise.all([paises]).finally(function () {
        _this2.$store.dispatch('loader/down', {
          trigger: _this2.$options.name
        });
      });
    },
    getSuggest: function getSuggest(queryString, cb) {
      var suggest = this.suggest;
      var self = this;
      var results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0;
      }) : suggest;
      cb(results);
    }
  }
};