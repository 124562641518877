import { required, type, EspacioBlanco } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
export default {
  name: 'PzPersonForm1Prestama',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument,
    PzCheckForm: PzCheckForm
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      activeClearing: true,
      dialogVisible: false,
      reference: 'formulario1',
      marital_status: [],
      sucursales: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        telefono: '',
        fecha_nacimiento: '',
        identity: {
          document: 1
        },
        consulta_clearing: 0,
        consulta_bcu: 0,
        cuota_maxima: '',
        destino_prestamo: '',
        info_extra: {
          clearing: null
        },
        clearing: null
      },
      view_sucursal: false,
      rules_formulario1: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco()],
        nombres: [required(''), EspacioBlanco()],
        apellidos: [required(''), EspacioBlanco()],
        telefono: [required('')],
        fecha_nacimiento: [required('')],
        type_prestamo: [required('')],
        cuota_maxima: [required(''), type({
          field: '',
          type: 'number'
        })],
        destino_prestamo: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {},
  methods: {}
};