import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type } from "@/commons/utils/Rules";
import { getBancos, getPaises } from "@/routes/api/resources";
import { setInversores } from "@/routes/api/views/flujo";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import { isEmpty, merge } from "@/commons/utils/functions";
export default {
  name: 'PzPersonForm1Inversor',
  components: {
    PzCheckForm: PzCheckForm,
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument,
    PzDirection: PzDirection
  },
  mixins: [PzBaseForm],
  data: function data() {
    return {
      reference: 'formulario1',
      paises: [],
      bancos: [],
      formulario1: {
        nombres: '',
        apellido_paterno: '',
        doc_lugar_emision: '',
        direccion: {},
        identity: {},
        email: '',
        telefono: '',
        data_extra: {
          sucursal_banco: '',
          banco_deposito: '',
          cuenta_deposito: ''
        }
      },
      rules_formulario1: {
        nombres: [required('')],
        apellido_paterno: [required('')],
        'identity.document': [required('')],
        'identity.number': [required('')],
        doc_lugar_emision: [required('')],
        telefono: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })],
        data_extra: {
          sucursal_banco: [required('')],
          banco_deposito: [required('')],
          cuenta_deposito: [required('')]
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var bancos = getBancos().then(function (res) {
        _this.bancos = res.data;
      }).catch(function () {
        _this.bancos = [];
      });
      var paises = getPaises().then(function (res) {
        _this.paises = res.data;
      }).catch(function () {
        _this.paises = [];
      });
      return Promise.all([bancos, paises]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    },
    merging: function merging(form, params) {
      if (!Object.prototype.hasOwnProperty.call(form, "numero_documento")) {
        form['numero_documento'] = {};
      }
      if (Object.prototype.hasOwnProperty.call(form, "identity") && !isEmpty(form.identity)) {
        form['numero_documento'][form.identity.document] = form.identity.number;
      }
      if (!isEmpty(params)) return merge(form, params);else return form;
    },
    sendMessages: function sendMessages(title, message) {
      this.$confirm(message, title, {
        showClose: false,
        showCancelButton: false,
        confirmButtonText: this.$t('views.garante.alert.btn_aceptar'),
        confirmButtonColor: "#fcb326",
        type: 'alert',
        roundButton: true,
        customClass: 'capitas',
        closeOnClickModal: false,
        closeOnPressEscape: false
      });
    },
    handleSend: function handleSend() {
      var _this2 = this;
      this.$refs[this.reference].validate(function (valid) {
        if (valid) {
          _this2.$store.dispatch('loader/up', {
            trigger: _this2.$options.name
          });
          _this2.formulario1 = _this2.merging(_this2.formulario1, {});
          setInversores(_this2.formulario1).then(function () {
            _this2.$router.push({
              name: 'FinInversor'
            });
          }).catch(function (error) {
            _this2.sendMessages(_this2.$t('views.not_available.title.no_services'), _this2.$t('views.not_available.no_services'));
          }).finally(function () {
            _this2.$store.dispatch('loader/down', {
              trigger: _this2.$options.name
            });
          });
        } else {
          _this2.valid = false;
          return false;
        }
      });
    }
  }
};