import { required } from "@/commons/utils/Rules";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import d_profesion from "@/assets/data/profesion";
export default {
  name: 'PzPersonFormExtHipotecalo',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseform2],
  data: function data() {
    return {
      data_profesion: d_profesion,
      isjubilado: false,
      formulario2: {
        profesion: '',
        empresa_trabajo: '',
        ramo_trabajo: '',
        ciudad_trabajo: '',
        codigo_postal_trabajo: '',
        telefono_empresa: '',
        cargo_ocupa: '',
        fecha_ingreso: '',
        laboral_secundaria: {
          empresa_trabajo: '',
          ramo_trabajo: '',
          ciudad_trabajo: '',
          codigo_postal_trabajo: '',
          telefono_empresa: '',
          cargo_ocupa: '',
          fecha_ingreso: ''
        }
      },
      disable: true,
      rules_formulario2: {},
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  }
};