var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulariodocumentacion["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulariodocumentacion, "captcha", $$v);
      },
      expression: "formulariodocumentacion['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form_documentacion_empresa.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      model: _vm.formulariodocumentacion,
      "status-icon": ""
    },
    slot: "formulario"
  }, [_c("el-row", {
    staticClass: "center-block text-center"
  }, _vm._l(_vm.formulariodocumentacion, function (value, name) {
    return _c("el-col", {
      key: name,
      attrs: {
        xl: {
          span: 24
        },
        lg: {
          span: 24
        },
        md: {
          span: 24
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("el-col", {
      attrs: {
        span: 22,
        offset: 1
      }
    }, [_c("el-form-item", {
      attrs: {
        prop: name,
        error: _vm.errors.get(name),
        rules: _vm.getRule(name)
      }
    }, [_c("label", [_vm._v(_vm._s(_vm.settings[name].label_form))]), _c("pz-upload", {
      attrs: {
        tip: _vm.settings[name].hint,
        template: _vm.settings[name].plantilla
      },
      model: {
        value: _vm.formulariodocumentacion[name],
        callback: function callback($$v) {
          _vm.$set(_vm.formulariodocumentacion, name, $$v);
        },
        expression: "formulariodocumentacion[name]"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };