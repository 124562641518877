import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-row", {
    staticClass: "text-center"
  }, [_c("el-col", {
    attrs: {
      span: 20,
      offset: 2
    }
  }, [_c("i18n", {
    staticClass: "pz-offer-color-primary pz-font-size-lg pz-large-margin",
    attrs: {
      path: _vm.myForm.form.plazo === 1 ? "forms_flujo.oferta_solicitud_default.offer_line" : "forms_flujo.oferta_solicitud_default.offer_line_plural",
      choice: _vm.myForm.form.plazo,
      tag: "label"
    }
  }, [_c("label", {
    staticClass: "pz-offer-color-secondary pz-font-size-xl",
    attrs: {
      place: "monto_oferta"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("currency")(_vm.myForm.form.monto_oferta, _vm.myForm.producto.moneda_prestamo.symbol, 0, {
    spaceBetweenAmountAndSymbol: true
  })) + " ")]), _c("label", {
    staticClass: "pz-offer-color-secondary pz-font-size-lg",
    attrs: {
      place: "plazo"
    }
  }, [_vm._v(" " + _vm._s(_vm.myForm.form.plazo) + " ")]), _c("label", {
    staticClass: "pz-offer-color-secondary pz-font-size-xl",
    attrs: {
      place: "monto-cuota"
    }
  }, [_vm._v("  " + _vm._s(_vm.getMontoCuota()) + " ")]), _c("b", {
    attrs: {
      place: "term_type"
    }
  }, [_vm._v(" " + _vm._s(_vm.myForm.producto.term_type.name) + " ")])]), _c("br"), _c("span", {
    staticClass: "pz-font-size-sm text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.pz_simulators_vehiculo.msg3")) + " ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };