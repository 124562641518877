import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
export default {
  name: 'PzPersonForm3Hipotecalo',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      formulario1: {
        info_extra: {
          cargo_pep: '',
          is_pep: '',
          relacion_pep: ''
        }
      },
      rules_formulario1: {}
    };
  }
};