import { required } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonForm1FenoresteNew',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      marital_status: [],
      sucursales: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        estudio: '',
        fecha_nacimiento: '',
        lugar_nacimiento: '',
        telefono_auxiliar: '',
        telefono_recados: '',
        telefono_Celular: '',
        email: '',
        antiguedad_domicilio: '',
        edad: '',
        estado_civil_id: '',
        regimen_patrimonial: '',
        nombre_conyugue: '',
        num_socio_conyugue: '',
        personas_viven_en_casa: '',
        propiedad_vivienda: '',
        destino_prestamo: '',
        domicilio: '',
        identity: {
          document: 1
        }
      },
      rules_formulario1: {
        destino_prestamo: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {},
  computed: {
    condocumento: function condocumento() {
      var docmun = localStorage.getItem('docnum');
      if (docmun) {
        return true;
      }
      return false;
    }
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    }
  }
};