import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export function lazyLoad(view) {
  return function () {
    return import("@/views/inversores/".concat(view, ".vue"));
  };
}
export default [{
  path: "create",
  component: lazyLoad("Create"),
  name: "CreateInversores",
  hidden: true,
  props: true,
  meta: {
    title: "Datos Inversores",
    hidden: true
  }
}, {
  path: "finalizado",
  component: lazyLoad("Final"),
  name: "FinInversor",
  hidden: true,
  props: true,
  meta: {
    title: "Datos Inversores",
    hidden: true
  }
}];