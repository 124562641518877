import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.SP.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    ref: "identity",
    attrs: {
      prop: "identity",
      type: "person",
      "type-label": _vm.$t("forms_flujo.form1_persona.SP.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.SP.identity_doc")
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 6
      },
      lg: {
        span: 6
      },
      md: {
        span: 6
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-phone-number", {
    attrs: {
      error: _vm.errors.get("telefono"),
      "default-country-code": "+34",
      required: ""
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 6
      },
      lg: {
        span: 6
      },
      md: {
        span: 6
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "contrato_movil",
      label: _vm.$t("forms_flujo.form1_persona.SP.contrato_movil")
    }
  }, [_c("el-select", {
    ref: "contrato_movil",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.contrato_movil")
    },
    model: {
      value: _vm.formulario1.contrato_movil,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "contrato_movil", $$v);
      },
      expression: "formulario1.contrato_movil"
    }
  }, _vm._l(_vm.tipo_contrato, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.contrato_movil")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "nombres",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nombre")
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", $$v);
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellidos",
      label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
      error: _vm.errors.get("apellido_paterno")
    }
  }, [_c("el-input", {
    ref: "apellidos",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.apellidos")
    },
    model: {
      value: _vm.formulario1.apellidos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellidos", $$v);
      },
      expression: "formulario1.apellidos"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nacionalidad",
      label: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
    }
  }, [_c("el-select", {
    ref: "nacionalidad",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
    },
    model: {
      value: _vm.formulario1.nacionalidad,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nacionalidad", $$v);
      },
      expression: "formulario1.nacionalidad"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nacionalidad,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.SP.fecha_nacimiento"),
      error: _vm.errors.get("fecha_nacimiento")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_nacimiento",
    attrs: {
      type: "date",
      editable: "",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.fecha_nacimiento")
    },
    model: {
      value: _vm.formulario1.fecha_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_nacimiento", $$v);
      },
      expression: "formulario1.fecha_nacimiento"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "mail",
      label: _vm.$t("forms_flujo.form1_persona.SP.mail"),
      error: _vm.errors.get("mail")
    }
  }, [_c("el-input", {
    ref: "mail",
    attrs: {
      type: "text",
      "auto-complete": "on",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.mail")
    },
    model: {
      value: _vm.formulario1.mail,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "mail", $$v);
      },
      expression: "formulario1.mail"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "confirmmail",
      label: _vm.$t("forms_flujo.form1_persona.SP.confirmmail"),
      error: _vm.errors.get("confirmmail")
    }
  }, [_c("el-input", {
    ref: "mail",
    attrs: {
      type: "text",
      "auto-complete": "on",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.confirmmail")
    },
    model: {
      value: _vm.formulario1.confirmmail,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "confirmmail", $$v);
      },
      expression: "formulario1.confirmmail"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ingreso_mensual",
      label: _vm.$t("forms_flujo.form1_persona.SP.ingreso_mensual"),
      error: _vm.errors.get("ingreso_mensual")
    }
  }, [_c("el-input", {
    ref: "ingreso_mensual",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.ingreso_mensual")
    },
    model: {
      value: _vm.formulario1.ingreso_mensual,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "ingreso_mensual", _vm._n($$v));
      },
      expression: "formulario1.ingreso_mensual"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cuota_maxima",
      label: _vm.$t("forms_flujo.form1_persona.SP.cuota_maxima"),
      error: _vm.errors.get("cuota_maxima")
    }
  }, [_c("el-input", {
    ref: "cuota_maxima",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.cuota_maxima")
    },
    model: {
      value: _vm.formulario1.cuota_maxima,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "cuota_maxima", _vm._n($$v));
      },
      expression: "formulario1.cuota_maxima"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "destino_prestamo",
      label: _vm.$t("forms_flujo.form1_persona.SP.destino_prestamo"),
      error: _vm.errors.get("destino_prestamo")
    }
  }, [_c("el-autocomplete", {
    ref: "destino_prestamo",
    attrs: {
      "fetch-suggestions": _vm.getSuggest,
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.destino_prestamo")
    },
    model: {
      value: _vm.formulario1.destino_prestamo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "destino_prestamo", $$v);
      },
      expression: "formulario1.destino_prestamo"
    }
  })], 1)], 1), _c("el-col", {
    staticClass: "row justify-content-between",
    attrs: {
      xl: {
        span: 24
      },
      lg: {
        span: 24
      },
      md: {
        span: 24
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.autorizo",
      error: _vm.errors.get("autorizo")
    }
  }, [_c("el-checkbox", {
    ref: "info_extra.autorizo",
    attrs: {
      span: 11
    },
    model: {
      value: _vm.formulario1.info_extra.autorizo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "autorizo", $$v);
      },
      expression: "formulario1.info_extra.autorizo"
    }
  }, [_c("div", {
    staticStyle: {
      "white-space": "initial"
    }
  }, [_vm._v(" Autorizo a Reddo Credit a comunicar mis datos identificativos (nombre y apellidos) y de contacto (dirección de correo electrónico y número de teléfono) a Rose & Pages, S.L., Correduría de Seguros, con NIF B87699443 y domicilio en Madrid, C/ Hermosilla, 80, para que pueda remitirme comunicaciones comerciales en relación con el ofrecimiento de seguros comercializados por la compañía que podrían ser de mi interés. ")])])], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "row justify-content-between",
    attrs: {
      xl: {
        span: 24
      },
      lg: {
        span: 24
      },
      md: {
        span: 24
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.autorizo2",
      error: _vm.errors.get("autorizo2")
    }
  }, [_c("el-checkbox", {
    ref: "info_extra.autorizo2",
    attrs: {
      label: _vm.$t("forms_flujo.form1_persona.SP.autorizo2")
    },
    model: {
      value: _vm.formulario1.info_extra.autorizo2,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "autorizo2", $$v);
      },
      expression: "formulario1.info_extra.autorizo2"
    }
  }, [_c("div", {
    staticStyle: {
      "white-space": "initial"
    }
  }, [_vm._v(" Autorizo a Reddo Credit a comunicar mis datos identificativos (nombre y apellidos) y de contacto (dirección de correo electrónico y número de teléfono) a Reddo Finance, S.L., Walddo, con NIF B88611595 y domicilio en Madrid, C/ Hermosilla, 80, para que pueda remitirme comunicaciones comerciales en relación con el ofrecimiento de seguros comercializados por la compañía que podrían ser de mi interés. ")])])], 1)], 1), _c("el-col", {
    staticClass: "row justify-content-between",
    attrs: {
      xl: {
        span: 24
      },
      lg: {
        span: 24
      },
      md: {
        span: 24
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.terminos_condiciones",
      error: _vm.errors.get("info_extra.terminos_condiciones")
    }
  }, [_c("el-checkbox", {
    ref: "info_extra.terminos_condiciones",
    model: {
      value: _vm.formulario1.info_extra.terminos_condiciones,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "terminos_condiciones", $$v);
      },
      expression: "formulario1.info_extra.terminos_condiciones"
    }
  }, [_c("div", {
    staticStyle: {
      "white-space": "initial"
    }
  }, [_vm._v(" He leído y acepto los "), _c("a", {
    attrs: {
      href: "https://reddocredit.com/terminos",
      target: "_blank"
    }
  }, [_vm._v("Términos y Condiciones")]), _vm._v(" , la "), _c("a", {
    attrs: {
      href: "https://reddocredit.com/privacidad",
      target: "_blank"
    }
  }, [_vm._v("Política de Privacidad")]), _vm._v(" y "), _c("a", {
    attrs: {
      href: "https://reddocredit.com/info-general",
      target: "_blank"
    }
  }, [_vm._v("la Información Europea Normalizada del Contrato")]), _vm._v(", y consiento que mis datos personales sean procesados de conformidad con las Condiciones Generales y la Política de Privacidad. ")])])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };