import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getEstadoCivil, getPaises, getSucursales } from "@/routes/api/resources";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import { mapGetters } from "vuex";
export default {
  name: 'PzPersonFormExtVehiculo',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzDirection: PzDirection,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseform2],
  data: function data() {
    var _this = this;
    var check_Jubilado = function check_Jubilado(rule, value, callback) {
      if (_this.viewSucursal && !value) {
        return callback(new Error(_this.$t('rules.incomplete')));
      }
      return callback();
    };
    var check_Sucursal = function check_Sucursal(rule, value, callback) {
      if (!_this.isjubilado && !value) {
        return callback(new Error(_this.$t('rules.incomplete')));
      }
      return callback();
    };
    return {
      marital_status: [],
      sucursales: [],
      paises: [],
      provincias: [],
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }],
      formulario2: {
        direccion: {},
        provincia: '',
        email: '',
        estado_civil_id: '',
        sucursal_id: '',
        info_extra: {
          empresa_trabajo: '',
          direccion_trabajo: ''
        },
        genero: ''
      },
      disable: true,
      rules_formulario2: {
        tipo_vivienda: [required('')],
        direccion: [required('')],
        provincia: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })],
        estado_civil_id: [required('')],
        sucursal_id: [required('')],
        info_extra: {
          direccion: [required('')],
          empresa_trabajo: [{
            validator: check_Jubilado,
            trigger: 'blur'
          }]
        },
        genero: [required('')]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['form_product'])), {}, {
    viewSucursal: function viewSucursal() {
      console.log(this.form_product.route_params.sucursales);
      return this.form_product.route_params.sucursales;
    }
  }),
  created: function created() {
    var data = localStorage.getItem('isjubilado');
    if (data && data == 'true') this.isjubilado = true;else this.isjubilado = false;
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    var estado = getEstadoCivil().then(function (res) {
      _this2.marital_status = res.data;
    }).catch(function () {
      _this2.marital_status = [];
    });
    var paises = getPaises().then(function (res) {
      _this2.paises = res.data;
    }).catch(function () {
      _this2.paises = [];
    });
    var dep = getDepartamentos().then(function (res) {
      _this2.provincias = res.data;
    }).catch(function () {
      _this2.provincias = [];
    });
    var getsucursales = getSucursales(this.$route.params.id_custom).then(function (res) {
      _this2.sucursales = res.data;
    }).catch(function () {
      _this2.sucursales = [];
    });
    Promise.all([estado, dep, paises, getsucursales]).then(function () {}).finally(function () {
      _this2.$store.dispatch('loader/down', {
        trigger: _this2.$options.name
      });
    });
  },
  methods: {}
};