var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-row", {
    staticClass: "nav pz-banner-item"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    staticClass: "pz-banner-item_0",
    attrs: {
      span: 20
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("label", {
    key: _vm.header.title,
    staticClass: "text-uppercase pz-label-banner"
  }, [_vm._v(" " + _vm._s(_vm.header.title ? _vm.header.title : "​") + " ")])])], 1), _c("el-col", {
    staticClass: "pz-banner-item_1 hidden-xs",
    attrs: {
      span: 1
    }
  }), _c("el-col", {
    staticClass: "pz-banner-item_2 hidden-xs",
    attrs: {
      span: 1
    }
  }), _c("el-col", {
    staticClass: "pz-banner-item_3 hidden-xs",
    attrs: {
      span: 1
    }
  }), _c("el-col", {
    staticClass: "pz-banner-item_4 hidden-xs",
    attrs: {
      span: 1
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };