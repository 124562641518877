var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.frame, {
    tag: "comment",
    attrs: {
      route: _vm.route
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };