var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm.myForm.producto.needsClearing || _vm.clearing ? _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "clearing",
      error: _vm.myForm.errors.get("clearing"),
      label: _vm.$t("forms_flujo.check_form.is_in_clearing")
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.myForm.formulario1.clearing,
      callback: function callback($$v) {
        _vm.$set(_vm.myForm.formulario1, "clearing", $$v);
      },
      expression: "myForm.formulario1.clearing"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("1")) + " ")]), _c("el-radio", {
    attrs: {
      label: "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("0")) + " ")]), _c("el-radio", {
    attrs: {
      label: "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dont_know")) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.myForm.producto.needsComprobanteDomicilio ? _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "comprobante_domicilio",
      label: _vm.$t("forms_flujo.check_form.comprobante_domicilio"),
      error: _vm.myForm.errors.get("comprobante_domicilio")
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.myForm.formulario1.comprobante_domicilio,
      callback: function callback($$v) {
        _vm.$set(_vm.myForm.formulario1, "comprobante_domicilio", $$v);
      },
      expression: "myForm.formulario1.comprobante_domicilio"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("1")) + " ")]), _c("el-radio", {
    attrs: {
      label: "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("0")) + " ")])], 1)], 1)], 1) : _vm._e(), _vm.myForm.producto.needsComprobanteIngresos ? _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "comprobante_ingresos",
      label: _vm.$t("forms_flujo.check_form.comprobante_ingresos"),
      error: _vm.myForm.errors.get("comprobante_ingresos")
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.myForm.formulario1.comprobante_ingresos,
      callback: function callback($$v) {
        _vm.$set(_vm.myForm.formulario1, "comprobante_ingresos", $$v);
      },
      expression: "myForm.formulario1.comprobante_ingresos"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("1")) + " ")]), _c("el-radio", {
    attrs: {
      label: "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("0")) + " ")])], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };