import iFrameResize from 'iframe-resizer/js/iframeResizer';
export default {
  name: "PzUnnaxIframe",
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      height: '690px',
      width: '550px'
    };
  },
  methods: {
    onResize: function onResize(messageData) {
      this.height = messageData.height + 'px';
      this.width = messageData.width + 'px';
    },
    iframeLoaded: function iframeLoaded() {
      iFrameResize({
        log: false,
        checkOrigin: false,
        scrolling: 'no',
        onMessage: this.onMessage,
        onResized: this.onResize
      }, '#unnax');
    }
  }
};