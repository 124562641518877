import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario2["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "captcha", $$v);
      },
      expression: "formulario2['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.procedencia_fondos")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario2,
      "status-icon": "",
      rules: _vm.rules_formulario2
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "procedencia",
      label: _vm.$t("forms_flujo.form2_persona.UY.procedencia"),
      error: _vm.errors.get("procedencia")
    }
  }, [_c("el-input", {
    ref: "procedencia",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.procedencia")
    },
    model: {
      value: _vm.formulario2.procedencia,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "procedencia", $$v);
      },
      expression: "formulario2.procedencia"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 6
      },
      lg: {
        span: 6
      },
      md: {
        span: 6
      },
      sm: {
        span: 12
      },
      xs: {
        span: 12
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "moneda_ingresos",
      label: _vm.$t("forms_flujo.form2_persona.UY.moneda_ingresos"),
      error: _vm.errors.get("moneda_ingresos")
    }
  }, [_c("el-select", {
    ref: "moneda_ingresos",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.moneda_ingresos")
    },
    model: {
      value: _vm.formulario2.moneda_ingresos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "moneda_ingresos", $$v);
      },
      expression: "formulario2.moneda_ingresos"
    }
  }, _vm._l(_vm.monedas, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.name,
        placeholder: _vm.$t("forms_flujo.form2_persona.UY.moneda_ingresos")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 6
      },
      lg: {
        span: 6
      },
      md: {
        span: 6
      },
      sm: {
        span: 12
      },
      xs: {
        span: 12
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "monto_ingresos",
      label: _vm.$t("forms_flujo.form2_persona.UY.monto_ingresos"),
      error: _vm.errors.get("monto_ingresos")
    }
  }, [_c("el-input", {
    ref: "monto_ingresos",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.monto_ingresos")
    },
    model: {
      value: _vm.formulario2.monto_ingresos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "monto_ingresos", $$v);
      },
      expression: "formulario2.monto_ingresos"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "concepto_ingresos",
      label: _vm.$t("forms_flujo.form2_persona.UY.concepto_ingresos"),
      error: _vm.errors.get("concepto_ingresos")
    }
  }, [_c("el-input", {
    ref: "concepto_ingresos",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.concepto_ingresos")
    },
    model: {
      value: _vm.formulario2.concepto_ingresos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "concepto_ingresos", $$v);
      },
      expression: "formulario2.concepto_ingresos"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "pais_opera",
      label: _vm.$t("forms_flujo.form2_persona.UY.pais_opera"),
      error: _vm.errors.get("pais_opera")
    }
  }, [_c("el-select", {
    ref: "pais_opera",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.pais_opera")
    },
    model: {
      value: _vm.formulario2.pais_opera,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "pais_opera", $$v);
      },
      expression: "formulario2.pais_opera"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nombre,
        value: item.nombre,
        placeholder: _vm.$t("forms_flujo.form2_persona.UY.pais_opera")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "destino_prestamo",
      label: _vm.$t("forms_flujo.form2_persona.UY.destino_prestamo"),
      error: _vm.errors.get("destino_prestamo")
    }
  }, [_c("el-input", {
    ref: "destino_prestamo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.destino_prestamo")
    },
    model: {
      value: _vm.formulario2.destino_prestamo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "destino_prestamo", $$v);
      },
      expression: "formulario2.destino_prestamo"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.aldia_obligaciones",
      label: _vm.$t("forms_flujo.form2_persona.UY.aldia_obligaciones"),
      error: _vm.errors.get("info_extra.aldia_obligaciones")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "SI",
      "inactive-text": "NO"
    },
    model: {
      value: _vm.formulario2.info_extra.aldia_obligaciones,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "aldia_obligaciones", $$v);
      },
      expression: "formulario2.info_extra.aldia_obligaciones"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.beneficiario",
      label: _vm.$t("forms_flujo.form2_persona.UY.beneficiario"),
      error: _vm.errors.get("info_extra.beneficiario")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "SI",
      "inactive-text": "NO"
    },
    model: {
      value: _vm.formulario2.info_extra.beneficiario,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "beneficiario", $$v);
      },
      expression: "formulario2.info_extra.beneficiario"
    }
  })], 1)], 1)], 1), _c("br"), _c("el-col", {
    staticClass: "text-center pz-large-margin",
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "pz-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.otros_datos")) + " ")])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.comosecontacto",
      label: _vm.$t("forms_flujo.form2_persona.UY.comosecontacto"),
      error: _vm.errors.get("info_extra.comosecontacto")
    }
  }, [_c("el-input", {
    ref: "info_extra.comosecontacto",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.comosecontacto")
    },
    model: {
      value: _vm.formulario2.info_extra.comosecontacto,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "comosecontacto", $$v);
      },
      expression: "formulario2.info_extra.comosecontacto"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.actividad_esperada",
      label: _vm.$t("forms_flujo.form2_persona.UY.actividad_esperada"),
      error: _vm.errors.get("info_extra.actividad_esperada")
    }
  }, [_c("el-input", {
    ref: "info_extra.actividad_esperada",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.actividad_esperada")
    },
    model: {
      value: _vm.formulario2.info_extra.actividad_esperada,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "actividad_esperada", $$v);
      },
      expression: "formulario2.info_extra.actividad_esperada"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "personal[0].nombre_completo",
      label: _vm.$t("forms_flujo.form2_persona.UY.personal.nombre_completo"),
      error: _vm.errors.get("personal[0].nombre_completo")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.personal.nombre_completo")
    },
    model: {
      value: _vm.formulario2.personal[0].nombre_completo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.personal[0], "nombre_completo", $$v);
      },
      expression: "formulario2.personal[0].nombre_completo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "personal[0].telefono",
      label: _vm.$t("forms_flujo.form2_persona.UY.personal.telefono"),
      error: _vm.errors.get("personal[0].telefono")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.personal.telefono")
    },
    model: {
      value: _vm.formulario2.personal[0].telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.personal[0], "telefono", $$v);
      },
      expression: "formulario2.personal[0].telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "institucional[0].nombre_completo",
      label: _vm.$t("forms_flujo.form2_persona.UY.institucional.nombre_completo"),
      error: _vm.errors.get("institucional[0].nombre_completo")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.institucional.nombre_completo")
    },
    model: {
      value: _vm.formulario2.institucional[0].nombre_completo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.institucional[0], "nombre_completo", $$v);
      },
      expression: "formulario2.institucional[0].nombre_completo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "institucional[0].telefono",
      label: _vm.$t("forms_flujo.form2_persona.UY.institucional.telefono"),
      error: _vm.errors.get("institucional[0].telefono")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.institucional.telefono")
    },
    model: {
      value: _vm.formulario2.institucional[0].telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.institucional[0], "telefono", $$v);
      },
      expression: "formulario2.institucional[0].telefono"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };