var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.informacion_empleo")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.empresa_trabajo",
      label: _vm.$t("forms_flujo.form1_persona.UY.empresa_trabajo"),
      error: _vm.errors.get("info_extra.empresa_trabajo")
    }
  }, [_c("el-input", {
    ref: "info_extra.empresa_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.empresa_trabajo"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.info_extra.empresa_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "empresa_trabajo", $$v);
      },
      expression: "formulario1.info_extra.empresa_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ramo_trabajo",
      label: _vm.$t("forms_flujo.form1_persona.SP.rubro_laboral"),
      error: _vm.errors.get("ramo_trabajo")
    }
  }, [_c("el-input", {
    ref: "ramo_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.rubro_laboral"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.ramo_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "ramo_trabajo", _vm._n($$v));
      },
      expression: "formulario1.ramo_trabajo"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.domicilio",
      label: _vm.$t("forms_flujo.form1_persona.UY.domicilio"),
      error: _vm.errors.get("info_extra.domicilio")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.domicilio"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.info_extra.domicilio,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "domicilio", $$v);
      },
      expression: "formulario1.info_extra.domicilio"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_empresa",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono_trabajo"),
      error: _vm.errors.get("telefono_empresa")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono_trabajo"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.telefono_empresa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono_empresa", $$v);
      },
      expression: "formulario1.telefono_empresa"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_ingreso",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso_laboral"),
      error: _vm.errors.get("fecha_ingreso")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso_laboral"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.fecha_ingreso,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_ingreso", $$v);
      },
      expression: "formulario1.fecha_ingreso"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cargo_ocupa",
      label: _vm.$t("forms_flujo.form1_persona.UY.puesto"),
      error: _vm.errors.get("cargo_ocupa")
    }
  }, [_c("el-input", {
    ref: "cargo_ocupa",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.puesto"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.cargo_ocupa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "cargo_ocupa", $$v);
      },
      expression: "formulario1.cargo_ocupa"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ingreso_mensual",
      label: _vm.$t("forms_flujo.form1_persona.UY.ingresos_mensuales"),
      error: _vm.errors.get("ingreso_mensual")
    }
  }, [_c("el-input", {
    ref: "ingreso_mensual",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.ingresos_mensuales"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.ingreso_mensual,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "ingreso_mensual", $$v);
      },
      expression: "formulario1.ingreso_mensual"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.otros_ingresos",
      label: _vm.$t("forms_flujo.form1_persona.UY.otros_ingresos"),
      error: _vm.errors.get("info_extra.otros_ingresos")
    }
  }, [_c("el-input", {
    ref: "info_extra.otros_ingresos",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.otros_ingresos"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.info_extra.otros_ingresos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "otros_ingresos", $$v);
      },
      expression: "formulario1.info_extra.otros_ingresos"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.fuentes_otros_ingresos",
      label: _vm.$t("forms_flujo.form1_persona.UY.fuentes_otros_ingresos"),
      error: _vm.errors.get("info_extra.fuentes_otros_ingresos")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fuentes_otros_ingresos"),
      disabled: true
    },
    model: {
      value: _vm.formulario1.info_extra.fuentes_otros_ingresos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "fuentes_otros_ingresos", $$v);
      },
      expression: "formulario1.info_extra.fuentes_otros_ingresos"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };