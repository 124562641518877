import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario2["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "captcha", $$v);
      },
      expression: "formulario2['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.datos_laborales")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario2,
      "status-icon": "",
      rules: _vm.rules_formulario2
    },
    slot: "formulario"
  }, [_c("label", [_vm._v(_vm._s(_vm.$t("forms_flujo.form2_persona.UY.actividad_principal")))]), _c("hr", {
    staticClass: "pz-dotted",
    staticStyle: {
      "margin-top": "1px"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "profesion",
      label: _vm.$t("forms_flujo.form2_persona.UY.profesion"),
      error: _vm.errors.get("profesion")
    }
  }, [_c("el-select", {
    ref: "profesion",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.profesion")
    },
    model: {
      value: _vm.formulario2.profesion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "profesion", $$v);
      },
      expression: "formulario2.profesion"
    }
  }, _vm._l(_vm.data_profesion, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.name,
        placeholder: _vm.$t("forms_flujo.form2_persona.UY.profesion")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "empresa_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.empresa_trabajo"),
      error: _vm.errors.get("empresa_trabajo")
    }
  }, [_c("el-input", {
    ref: "empresa_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.empresa_trabajo")
    },
    model: {
      value: _vm.formulario2.empresa_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "empresa_trabajo", $$v);
      },
      expression: "formulario2.empresa_trabajo"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ramo_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.ramo"),
      error: _vm.errors.get("ramo_trabajo")
    }
  }, [_c("el-input", {
    ref: "ramo_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.ramo")
    },
    model: {
      value: _vm.formulario2.ramo_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "ramo_trabajo", $$v);
      },
      expression: "formulario2.ramo_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ciudad_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.ciudad"),
      error: _vm.errors.get("ciudad_trabajo")
    }
  }, [_c("el-input", {
    ref: "ciudad_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.ciudad")
    },
    model: {
      value: _vm.formulario2.ciudad_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "ciudad_trabajo", $$v);
      },
      expression: "formulario2.ciudad_trabajo"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "codigo_postal_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.codigo_postal"),
      error: _vm.errors.get("codigo_postal_trabajo")
    }
  }, [_c("el-input", {
    ref: "codigo_postal_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.codigo_postal")
    },
    model: {
      value: _vm.formulario2.codigo_postal_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "codigo_postal_trabajo", $$v);
      },
      expression: "formulario2.codigo_postal_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_empresa",
      label: _vm.$t("forms_flujo.form2_persona.UY.telefono"),
      error: _vm.errors.get("telefono_empresa")
    }
  }, [_c("el-input", {
    ref: "telefono_empresa",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.telefono")
    },
    model: {
      value: _vm.formulario2.telefono_empresa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "telefono_empresa", $$v);
      },
      expression: "formulario2.telefono_empresa"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cargo_ocupa",
      label: _vm.$t("forms_flujo.form2_persona.UY.cargo_ocupa"),
      error: _vm.errors.get("cargo_ocupa")
    }
  }, [_c("el-input", {
    ref: "cargo_ocupa",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.cargo_ocupa")
    },
    model: {
      value: _vm.formulario2.cargo_ocupa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "cargo_ocupa", $$v);
      },
      expression: "formulario2.cargo_ocupa"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_ingreso",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso"),
      error: _vm.errors.get("fecha_ingreso")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_ingreso",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso")
    },
    model: {
      value: _vm.formulario2.fecha_ingreso,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "fecha_ingreso", $$v);
      },
      expression: "formulario2.fecha_ingreso"
    }
  })], 1)], 1)], 1), _c("el-col", {
    staticClass: "text-center pz-large-margin",
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "pz-subtitle"
  }, [_c("label", [_vm._v(_vm._s(_vm.$t("forms_flujo.form2_persona.UY.actividad_secundaria")))]), _c("hr", {
    staticClass: "pz-dotted",
    staticStyle: {
      "margin-top": "1px"
    }
  })])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.empresa_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.empresa_trabajo"),
      error: _vm.errors.get("laboral_secundaria.empresa_trabajo")
    }
  }, [_c("el-input", {
    ref: "laboral_secundaria.empresa_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.empresa_trabajo")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.empresa_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "empresa_trabajo", $$v);
      },
      expression: "formulario2.laboral_secundaria.empresa_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.ramo_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.ramo"),
      error: _vm.errors.get("laboral_secundaria.ramo_trabajo")
    }
  }, [_c("el-input", {
    ref: "laboral_secundaria.ramo_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.ramo")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.ramo_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "ramo_trabajo", $$v);
      },
      expression: "formulario2.laboral_secundaria.ramo_trabajo"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.ciudad_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.ciudad"),
      error: _vm.errors.get("laboral_secundaria.ciudad_trabajo")
    }
  }, [_c("el-input", {
    ref: "laboral_secundaria.ciudad_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.ciudad")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.ciudad_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "ciudad_trabajo", $$v);
      },
      expression: "formulario2.laboral_secundaria.ciudad_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.codigo_postal_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.codigo_postal"),
      error: _vm.errors.get("laboral_secundaria.codigo_postal_trabajo")
    }
  }, [_c("el-input", {
    ref: "laboral_secundaria.codigo_postal_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.codigo_postal")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.codigo_postal_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "codigo_postal_trabajo", $$v);
      },
      expression: "formulario2.laboral_secundaria.codigo_postal_trabajo"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.telefono_empresa",
      label: _vm.$t("forms_flujo.form2_persona.UY.telefono"),
      error: _vm.errors.get("laboral_secundaria.telefono_empresa")
    }
  }, [_c("el-input", {
    ref: "laboral_secundaria.telefono",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.telefono")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.telefono_empresa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "telefono_empresa", $$v);
      },
      expression: "formulario2.laboral_secundaria.telefono_empresa"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.cargo_ocupa",
      label: _vm.$t("forms_flujo.form2_persona.UY.cargo_ocupa"),
      error: _vm.errors.get("laboral_secundaria.cargo_ocupa")
    }
  }, [_c("el-input", {
    ref: "laboral_secundaria.cargo_ocupa",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.cargo_ocupa")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.cargo_ocupa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "cargo_ocupa", $$v);
      },
      expression: "formulario2.laboral_secundaria.cargo_ocupa"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "laboral_secundaria.fecha_ingreso",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso"),
      error: _vm.errors.get("laboral_secundaria.fecha_ingreso")
    }
  }, [_c("el-date-picker", {
    ref: "laboral_secundaria.fecha_ingreso",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso")
    },
    model: {
      value: _vm.formulario2.laboral_secundaria.fecha_ingreso,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.laboral_secundaria, "fecha_ingreso", $$v);
      },
      expression: "formulario2.laboral_secundaria.fecha_ingreso"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };