var state = {
  loader: {
    run: true,
    text: ''
  },
  instances: 0,
  trigger: ''
};
var mutations = {
  SET_LOADER_UP: function SET_LOADER_UP(state, _ref) {
    var trigger = _ref.trigger,
      text = _ref.text;
    window.scrollTo(0, 0);
    state.instances++;
    state.loader.run = state.instances > 0;
    state.trigger = trigger;
    state.loader.text = text;
  },
  SET_LOADER_DOWN: function SET_LOADER_DOWN(state, _ref2) {
    var trigger = _ref2.trigger;
    window.scrollTo(0, 0);
    if (state.instances > 0) state.instances--;
    state.loader.run = state.instances > 0;
    state.loader.text = '';
    state.trigger = trigger;
  }
};
var actions = {
  up: function up(_ref3, data) {
    var commit = _ref3.commit;
    commit('SET_LOADER_UP', data);
  },
  down: function down(_ref4, data) {
    var commit = _ref4.commit;
    commit('SET_LOADER_DOWN', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};