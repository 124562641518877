import _defineProperty from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import { required } from '@/commons/utils/Rules';
import PzBaseForm from "@/commons/forms/PzBaseForm";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: "PzFadSdkForm",
  components: {
    PzIdentityDocument: PzIdentityDocument,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm],
  props: {
    loan: {
      type: [Number, String],
      required: true
    }
  },
  data: function data() {
    return {
      reference: 'formularioNaat',
      formulario: {
        codigo_verificacion: '',
        numero_documento: {},
        identity: {},
        loan: this.loan
      },
      rules_formulario_validarTelefono: {
        'identity.document': [required('')],
        'identity.number': [required('')]
      }
    };
  },
  methods: {
    required: required,
    setNumeroDoc: function setNumeroDoc() {
      this.formulario.numero_documento = _defineProperty({}, this.formulario.identity.document, this.formulario.identity.number);
    },
    handleResend: function handleResend() {
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.setNumeroDoc();
      var isvalid = this.handleSave();
      if (isvalid) {
        this.$emit('input', this.formulario);
        this.$emit('resend');
      }
      this.$store.dispatch('loader/down', {
        trigger: this.$options.name
      });
    },
    handleSend: function handleSend() {
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.setNumeroDoc();
      var isvalid = this.handleSave();
      if (isvalid) {
        this.$emit('input', this.formulario);
        this.$emit('send');
      }
      this.$store.dispatch('loader/down', {
        trigger: this.$options.name
      });
    }
  }
};