var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      type: "person",
      "type-label": _vm.$t("forms_flujo.form1_persona.UY.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.UY.identity_doc"),
      "type-hidden": ""
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono"),
      error: _vm.errors.get("telefono")
    }
  }, [_c("el-input", {
    ref: "telefono",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono")
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estado_civil_id",
      label: _vm.$t("forms_flujo.form1_persona.UY.civil_state"),
      error: _vm.errors.get("estado_civil_id")
    }
  }, [_c("el-select", {
    ref: "estado_civil_id",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.civil_state")
    },
    model: {
      value: _vm.formulario1.estado_civil_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "estado_civil_id", $$v);
      },
      expression: "formulario1.estado_civil_id"
    }
  }, _vm._l(_vm.marital_status, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.estado,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.civil_state")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento"),
      error: _vm.errors.get("fecha_nacimiento")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_nacimiento",
    attrs: {
      type: "date",
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      "picker-options": _vm.pickerOptions1,
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_nacimiento")
    },
    model: {
      value: _vm.formulario1.fecha_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_nacimiento", $$v);
      },
      expression: "formulario1.fecha_nacimiento"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 4
      },
      lg: {
        span: 4
      },
      md: {
        span: 4
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "jubilado",
      label: _vm.$t("forms_flujo.form1_persona.UY.jubilado"),
      error: _vm.errors.get("jubilado")
    }
  }, [_c("el-select", {
    ref: "jubilado",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.jubilado")
    },
    model: {
      value: _vm.formulario1.jubilado,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "jubilado", $$v);
      },
      expression: "formulario1.jubilado"
    }
  }, [_c("el-option", {
    key: 1,
    attrs: {
      label: _vm.$t("forms_flujo.form1_persona.UY.jubilado_si"),
      value: true
    }
  }), _c("el-col", {
    staticClass: "hidden-sm-and-up"
  }), _c("el-option", {
    key: 0,
    attrs: {
      label: _vm.$t("forms_flujo.form1_persona.UY.jubilado_no"),
      value: false
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 8
      },
      lg: {
        span: 8
      },
      md: {
        span: 8
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_ingreso",
      label: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso"),
      error: _vm.errors.get("fecha_ingreso")
    }
  }, [_c("el-date-picker", {
    ref: "fecha_ingreso",
    attrs: {
      type: "date",
      "picker-options": _vm.pickerOptions1,
      disabled: !!_vm.formulario1.jubilado,
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.fecha_ingreso")
    },
    model: {
      value: _vm.formulario1.fecha_ingreso,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "fecha_ingreso", $$v);
      },
      expression: "formulario1.fecha_ingreso"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cuota_maxima",
      label: _vm.$t("forms_flujo.form1_persona.UY.cuota_maxima"),
      error: _vm.errors.get("cuota_maxima")
    }
  }, [_c("el-input", {
    ref: "cuota_maxima",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.cuota_maxima")
    },
    model: {
      value: _vm.formulario1.cuota_maxima,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "cuota_maxima", _vm._n($$v));
      },
      expression: "formulario1.cuota_maxima"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ingreso_mensual",
      label: _vm.$t("forms_flujo.form1_persona.UY.ingreso_mensual"),
      error: _vm.errors.get("ingreso_mensual")
    }
  }, [_c("el-input", {
    ref: "ingreso_mensual",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.ingreso_mensual")
    },
    model: {
      value: _vm.formulario1.ingreso_mensual,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "ingreso_mensual", _vm._n($$v));
      },
      expression: "formulario1.ingreso_mensual"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "destino_prestamo",
      label: _vm.$t("forms_flujo.form1_persona.UY.destino_prestamo"),
      error: _vm.errors.get("destino_prestamo")
    }
  }, [_c("el-input", {
    ref: "destino_prestamo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.destino_prestamo")
    },
    model: {
      value: _vm.formulario1.destino_prestamo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "destino_prestamo", $$v);
      },
      expression: "formulario1.destino_prestamo"
    }
  })], 1)], 1)], 1), _c("pz-check-form"), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.producto.conditions, function (condition) {
    return _c("el-col", {
      key: condition.id,
      staticClass: "row justify-content-between",
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("pz-terms-and-conditions", {
      attrs: {
        prop: "condition_" + condition.slug,
        name: condition.nombre
      },
      model: {
        value: _vm.formulario1.conditions[condition.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.formulario1.conditions, condition.slug, $$v);
        },
        expression: "formulario1.conditions[condition.slug]"
      }
    }, [_c("div", {
      domProps: {
        innerHTML: _vm._s(condition.contenido)
      }
    })])], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };