var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("pz-title", [_vm._t("form_title", null, {
    slot: "title"
  }), _vm._t("form_subtitle", null, {
    slot: "sub_title"
  })], 2), _c("el-row", {
    staticClass: "center-block"
  }, [_c("el-col", {
    attrs: {
      span: 22,
      offset: 1
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm._t("formulario")], 2)], 1)], 1), _vm._t("buttons", function () {
    return [_c("el-col", {
      staticClass: "text-center",
      attrs: {
        span: 24
      }
    }, [_vm._t("button_back", function () {
      return [_vm.showBack ? _c("pz-back-button", {
        attrs: {
          back: _vm.handleBack
        }
      }) : _vm._e()];
    }), _vm._t("button_forward", function () {
      return [_vm.availableCaptcha ? _c("pz-captcha-button", {
        attrs: {
          forward: _vm.handleForward
        },
        model: {
          value: _vm.captchaToken,
          callback: function callback($$v) {
            _vm.captchaToken = $$v;
          },
          expression: "captchaToken"
        }
      }) : _c("pz-forward-button", {
        attrs: {
          forward: _vm.handleForward,
          "button-name": _vm.nameButton
        }
      })];
    })], 2)];
  }), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_vm._t("button_footer")], 2)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };