import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario2["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "captcha", $$v);
      },
      expression: "formulario2['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.SP.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario2,
      "status-icon": "",
      rules: _vm.rules_formulario2
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "tipo_vivienda",
      label: _vm.$t("forms_flujo.form2_persona.SP.property_type"),
      error: _vm.errors.get("tipo_vivienda")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.property_type")
    },
    model: {
      value: _vm.formulario2.tipo_vivienda,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "tipo_vivienda", $$v);
      },
      expression: "formulario2.tipo_vivienda"
    }
  }, _vm._l(_vm.properties, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.tipo,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form2_persona.property_type")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "relacion_laboral_id",
      label: _vm.$t("forms_flujo.form2_persona.SP.employee_type"),
      error: _vm.errors.get("relacion_laboral_id")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.employee_type")
    },
    model: {
      value: _vm.formulario2.relacion_laboral_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "relacion_laboral_id", $$v);
      },
      expression: "formulario2.relacion_laboral_id"
    }
  }, _vm._l(_vm.employment_relations, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.relacion,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cash_in",
      label: _vm.$t("forms_flujo.form2_persona.SP.cash_in"),
      error: _vm.errors.get("cash_in")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.cash_in")
    },
    model: {
      value: _vm.formulario2.cash_in,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "cash_in", $$v);
      },
      expression: "formulario2.cash_in"
    }
  }, _vm._l(_vm.cahs_in_list, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nombre,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form2_persona.SP.cash_in")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "comoseentero",
      label: _vm.$t("forms_flujo.form2_persona.UY.how_find_out"),
      error: _vm.errors.get("comoseentero")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.how_find_out")
    },
    model: {
      value: _vm.formulario2.comoseentero,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "comoseentero", $$v);
      },
      expression: "formulario2.comoseentero"
    }
  }, _vm._l(_vm.formas_de_enterarse, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.forma,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.fuente_ingreso_principal",
      label: _vm.$t("forms_flujo.form2_persona.SP.fuente_ingreso_principal"),
      error: _vm.errors.get("fuente_ingreso_principal")
    }
  }, [_c("el-select", {
    ref: "info_extra.fuente_ingreso_principal",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.fuente_ingreso_principal")
    },
    model: {
      value: _vm.formulario2.info_extra.fuente_ingreso_principal,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "fuente_ingreso_principal", $$v);
      },
      expression: "formulario2.info_extra.fuente_ingreso_principal"
    }
  }, _vm._l(_vm.income_source, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form2_persona.SP.fuente_ingreso_principal")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.referenciado_por",
      label: _vm.$t("forms_flujo.form2_persona.SP.referenciado_por"),
      error: _vm.errors.get("referenciado_por")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.referenciado_por")
    },
    model: {
      value: _vm.formulario2.info_extra.referenciado_por,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "referenciado_por", $$v);
      },
      expression: "formulario2.info_extra.referenciado_por"
    }
  })], 1)], 1)], 1), _c("hr", {
    staticClass: "pz-dotted"
  }), _c("el-row", {
    staticClass: "pz-large-margin",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "row justify-content-center",
    attrs: {
      span: 24
    }
  }, [_c("b", {
    staticClass: "pz-font-size-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.SP.contact.title")) + " ")])]), _c("el-col", {
    staticClass: "row justify-content-center",
    attrs: {
      span: 24
    }
  }, [_c("label", [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.SP.contact.sub_title")) + " ")])])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "persona_contacto",
      label: _vm.$t("forms_flujo.form2_persona.SP.contact.full_name"),
      error: _vm.errors.get("persona_contacto")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.contact.full_name")
    },
    model: {
      value: _vm.formulario2.persona_contacto,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "persona_contacto", $$v);
      },
      expression: "formulario2.persona_contacto"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_persona_contacto",
      label: _vm.$t("forms_flujo.form2_persona.SP.contact.phone"),
      error: _vm.errors.get("telefono_persona_contacto")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.contact.phone")
    },
    model: {
      value: _vm.formulario2.telefono_persona_contacto,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "telefono_persona_contacto", $$v);
      },
      expression: "formulario2.telefono_persona_contacto"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email_contacto",
      label: _vm.$t("forms_flujo.form2_persona.SP.email_contacto"),
      error: _vm.errors.get("email_contacto")
    }
  }, [_c("el-input", {
    ref: "email_contacto",
    attrs: {
      type: "text",
      "auto-complete": "on",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.email_contacto")
    },
    model: {
      value: _vm.formulario2.email_contacto,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "email_contacto", $$v);
      },
      expression: "formulario2.email_contacto"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.acepted_before",
      error: _vm.errors.get("accepted_before")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": _vm.$t("forms_flujo.form1_persona.SP.acepted_before")
    },
    model: {
      value: _vm.formulario2.info_extra.accepted_before,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "accepted_before", $$v);
      },
      expression: "formulario2.info_extra.accepted_before"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.has_others_credits",
      error: _vm.errors.get("has_others_credits")
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": _vm.$t("forms_flujo.form1_persona.SP.has_others_credits")
    },
    model: {
      value: _vm.formulario2.info_extra.has_others_credits,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "has_others_credits", $$v);
      },
      expression: "formulario2.info_extra.has_others_credits"
    }
  })], 1)], 1)], 1), _vm.vnz ? _c("div", [_c("hr", {
    staticClass: "pz-dotted"
  }), _c("el-row", {
    staticClass: "pz-large-margin",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "row justify-content-center",
    attrs: {
      span: 24
    }
  }, [_c("b", {
    staticClass: "pz-font-size-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.SP.venezolano.title")) + " ")])]), _c("el-col", {
    staticClass: "row justify-content-center",
    attrs: {
      span: 24
    }
  }, [_c("label", [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.SP.venezolano.sub_title")) + " ")])])], 1), _c("el-row", {
    staticClass: "pz-large-margin",
    attrs: {
      gutter: 20
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      "type-hidden": "",
      "type-active": false,
      type: "person",
      "number-label": _vm.$t("forms_flujo.form1_persona.SP.CIV")
    },
    model: {
      value: _vm.formulario2.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "identity", $$v);
      },
      expression: "formulario2.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.tiempo_viviendo_espana",
      label: _vm.$t("forms_flujo.form2_persona.SP.tiempo_viviendo_espana"),
      error: _vm.errors.get("tiempo_viviendo_espana")
    }
  }, [_c("el-select", {
    ref: "info_extra.tiempo_viviendo_espana",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.tiempo_viviendo_espana")
    },
    model: {
      value: _vm.formulario2.info_extra.tiempo_viviendo_espana,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "tiempo_viviendo_espana", $$v);
      },
      expression: "formulario2.info_extra.tiempo_viviendo_espana"
    }
  }, _vm._l(_vm.tiempo_viviendo, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form2_persona.SP.tiempo_viviendo_espana")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estudios",
      label: _vm.$t("forms_flujo.form1_persona.SP.studies"),
      error: _vm.errors.get("estudios")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.studies")
    },
    model: {
      value: _vm.formulario2.estudios,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "estudios", $$v);
      },
      expression: "formulario2.estudios"
    }
  }, _vm._l(_vm.studies, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.estudio,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.institucion_de_estudio",
      label: _vm.$t("forms_flujo.form1_persona.SP.institucion_de_estudio"),
      error: _vm.errors.get("institucion_de_estudio")
    }
  }, [_c("el-autocomplete", {
    attrs: {
      "fetch-suggestions": _vm.getSuggest,
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.institucion_de_estudio")
    },
    model: {
      value: _vm.formulario2.info_extra.institucion_de_estudio,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "institucion_de_estudio", $$v);
      },
      expression: "formulario2.info_extra.institucion_de_estudio"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.ultima_direccion_venezuela",
      label: _vm.$t("forms_flujo.form2_persona.SP.ultima_direccion_venezuela"),
      error: _vm.errors.get("ultima_direccion_venezuela")
    }
  }, [_c("el-input", {
    attrs: {
      tupe: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.ultima_direccion_venezuela")
    },
    model: {
      value: _vm.formulario2.info_extra.ultima_direccion_venezuela,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "ultima_direccion_venezuela", $$v);
      },
      expression: "formulario2.info_extra.ultima_direccion_venezuela"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.ultimo_trabajo_venezuela",
      label: _vm.$t("forms_flujo.form2_persona.SP.ultimo_trabajo_venezuela"),
      error: _vm.errors.get("ultimo_trabajo_venezuela")
    }
  }, [_c("el-input", {
    attrs: {
      tupe: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.ultimo_trabajo_venezuela")
    },
    model: {
      value: _vm.formulario2.info_extra.ultimo_trabajo_venezuela,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "ultimo_trabajo_venezuela", $$v);
      },
      expression: "formulario2.info_extra.ultimo_trabajo_venezuela"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c("el-row", {
    staticClass: "pz-large-margin",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "formato_documento",
      label: _vm.$t("forms_flujo.form2_persona.SP.formato_documento"),
      error: _vm.errors.get("formato_documento")
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.formulario2.formato_documento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "formato_documento", $$v);
      },
      expression: "formulario2.formato_documento"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "dni_plastico"
    }
  }, [_vm._v(" DNI/NIE Plástico o Pasaporte ")]), _c("el-radio-button", {
    attrs: {
      label: "tarjeta_roja"
    }
  }, [_vm._v(" Otra Documentación ")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };