import baseFormDocumentacion from "./baseFormDocumentacion";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzUpload from "@/commons/forms/Fields/PzUpload";
export default {
  name: 'PzCompanyDocumentacionUy',
  components: {
    PzUpload: PzUpload,
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseFormDocumentacion],
  data: function data() {
    return {};
  },
  created: function created() {
    this.getDocs('company');
  },
  methods: {}
};