var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.url !== true ? _c("iframe", {
    staticClass: "pz-large-margin",
    attrs: {
      id: "unnax",
      src: _vm.url,
      name: "unnax",
      frameborder: "0",
      width: _vm.width,
      height: _vm.height,
      allow: "geolocation; microphone; camera;"
    },
    on: {
      load: _vm.iframeLoaded
    }
  }) : _c("div", {
    staticClass: "pic-404"
  }, [_c("img", {
    staticClass: "pic-404__parent",
    attrs: {
      src: require("@/assets/images/exitoso.png"),
      alt: "success"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };