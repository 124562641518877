import lang from "element-ui/lib/locale/lang/en";
var requirements = {
  1: 'Be between 26 and 75 years old.',
  2: 'Have a pay stub.',
  3: 'Not having a low scoring bureau or have defaults with other financial institutions.',
  4: 'At least 12 months old in the same job',
  5: 'Must have a proof of address'
};
var keys = {
  No_autorized: {
    title: 'Please update',
    message: 'Please reload the page we have made some adjustments.'
  },
  date_format: 'DD/MM/YYYY',
  el: lang.el,
  1: 'Yes',
  0: 'No',
  dont_know: "I don't know",
  home: 'Home',
  Planta: 'Floor',
  errors: {
    _404: {
      header: 'Page not found',
      title: '¡OOPS!',
      error: 'Error 404 - Page not found',
      message: 'The page that you requested is missing or temporarily unavailable.'
    },
    _401: {
      header: 'Not authorized',
      title: 'Not authorized!',
      message: 'This company is not authorized anymore, please contact Sales.'
    }
  },
  credit_motive: {
    liquidez: 'Liquidity',
    reparacion_vehiulo: 'Fix your car',
    reforma_hogar: 'Home improvements',
    pago_factura: 'Pay bills',
    celebracion: 'Make a party',
    viaje: 'Take a trip'
  },
  income_source: {
    Nomina: "Payroll",
    Subsidio: "Subsidy",
    Pension: "Pension",
    Alquiler: "Rental"
  },
  vnz_universities: {
    IESA: 'Instituto de Estudios Sup. de Adm. (IESA)',
    ISUM: 'Instituto Superior Universitario de Mercadotecnia (ISUM)',
    IUNP: 'Instituto Universitario de Nuevas Profesiones (IUNP)',
    IUTIRLA: 'Instituto Universitario de Tec. Ind. R. L. Arismendi (IUTIRLA)',
    PSM: 'Instituto Universitario Politécnico Santiago Mariño (PSM)',
    IUTAV: 'Instituto Universitario Tec. Américo Vespucio (IUTAV)',
    UAH: 'Universidad Alejandro Humboldt',
    UAM: 'Universidad Arturo Michelena (UAM)',
    UBV: 'Universidad Bolivariana de Venezuela (UBV)',
    UCAB: 'Universidad Católica Andrés Bello (UCAB)',
    UCSAR: 'Universidad Católica Santa Rosa (UCSAR)',
    UCV: 'Universidad Central de Venezuela (UCV)',
    ULA: 'Universidad de Los Andes (ULA)',
    UJAP: 'Universidad José Antonio Páez (UJAP)',
    UJMV: 'Universidad José María Vargas (UJMV)',
    UNIMET: 'Universidad Metropolitana (UNIMET)',
    UMA: 'Universidad Monteávila (UMA)',
    UNEXPO: 'Universidad N. E. Politécnica A. J. de Sucre (UNEXPO)',
    UNEFA: 'Universidad Nac. E. Politécnica de la Fza. Armada Bol. (UNEFA)',
    UNA: 'Universidad Nacional Abierta (UNA)',
    UNESR: 'Universidad Nacional Experimental Simón Rodríguez (UNESR)',
    UNIPAP: 'Universidad Panamericana del Puerto (UNIPAP)',
    UPEL: 'Universidad Pedagógica Experimental Libertador (UPEL)',
    UPV: 'Universidad Politécnica de Valencia (UPV)',
    USM: 'Universidad Santa María (USM)',
    USB: 'Universidad Simón Bolívar (USB)',
    LUV: 'Universidad Virtual Latinoamericana (LUV)'
  },
  footer_text: ' © {year} Prezzta, all rights reserved.',
  connexion_error: 'Connection error, please retry later.',
  version: 'Version',
  i_waned: 'I wanted it!',
  just_few_questions: 'Just a few more questions...',
  Cant_payments_plans: 'Term',
  cant_product: 'Products',
  contact_us: 'Contact us',
  email: 'E-mail',
  country: 'Country',
  continue: 'Continue',
  go_back: 'Back',
  accept: 'Accept',
  close: 'Close',
  calc_credito: 'Finally products easy to understand!',
  rejected_loan: 'Rejected request',
  not_available: 'Not Available',
  password_restrictions: 'The password does not comply with the established policies. Please review them in the help',
  password_length: 'Your password must have at least {min} characters',
  congrats: 'Congratulations!',
  should_accept: 'Please accept terms and conditions to continue',
  accept_terms_and_conditions: 'Accept terms and conditions',
  accept_terms: 'Accept {name}',
  i_accept_terms_and_conditions: 'I accept terms and conditions',
  i_accept_terms: 'I accept {name}',
  had_accept_terms_and_conditions: 'I have accepted the terms and conditions',
  see_terms_and_conditions: 'View terms and conditions',
  see_terms: 'View {name}',
  terms_and_conditions: 'Terms and conditions',
  sorry: "We are so sorry",
  product_selection: 'Please select the product that best fits your interests',
  rules: {
    incomplete: 'Pleas complete all the information',
    should_accept_terms: 'Please, accept {name} .',
    should_validate_bank_data: 'Please, you must validate your bank data',
    document_number_mandatory: 'PLease, fill your document number.',
    document_company_invalid_format: 'The format of your document is invalid, Ex: 123456789000.',
    document_applicant_invalid_format: 'The format of your document is invalid, Ex: 12345678.',
    field_mandatory: '{field} is required, please double check it.',
    field_not_equal: '{field} is not equal with {compare_field}, please double check it.',
    invalid_field: '{field} is invalid, please double check it.',
    invalid_range: '{field} must be ',
    invalid_range_higher: 'higher than {field} ',
    invalid_range_and: ' and ',
    invalid_range_lower: 'lower than {field}.',
    country_code: 'Country code',
    PhoneNumber: 'Phone number',
    field_number: 'Most be a number',
    identity_rule: 'The format of your document is invalid.'
  },
  forms_flujo: {
    accept_terms_and_conditions: 'Please accept terms and conditions',
    check_form: {
      is_in_clearing: 'Are you banned in any bureau?',
      comprobante_domicilio: 'Do you have proof of address?',
      comprobante_ingresos: 'Do you have proof of income?'
    },
    PzSimulator: {
      how_many_cash: 'How much would you like to borrow?',
      how_many_fee: 'And for how long?',
      how_many_discount: 'How much do you want to discount?',
      limit_date: 'Due date',
      first_limit_date: 'Date of first expiration',
      annual_sells: 'Your annual sales?',
      emission_date: 'Date of issue',
      recived: 'You receive ',
      tasas: ' with rates of ',
      in: ' in ',
      from: ' from ',
      select_date: 'Select date'
    },
    pz_simulators_template: {
      btn_accept: 'Apply for',
      americana: 'The requested amount will be paid in the last installment',
      approximate_value: 'Approximate fee value depends on the profile assigned to the client after completing the application form',
      note: 'Note: calculations are approximate',
      info_bnf: '',
      warning_bnf: ''
    },
    continuar_doc_form: {
      title: 'Enter the verification code that has been sent to you.',
      numero_documento: 'Document number',
      codigo_verificacion: 'Verification code'
    },
    form_documentacion_empresa: {
      title: 'Company documentation.',
      download_template: 'Download template',
      drag: 'Drag file here or ',
      click: 'click to upload'
    },
    form_documentacion_externa: {
      title: 'Your documentation.',
      download_template: 'Download template',
      drag: 'Drag file or ',
      click: 'click to upload'
    },
    form_documentacion_persona: {
      title: 'Your documentation.',
      sub_title: '',
      download_template: 'Download template',
      drag: 'Drag file or ',
      click: 'click to upload',
      persona_template: 'Template',
      name_button: 'Continue'
    },
    form1_empresa: {
      UY: {
        title: 'Applicant information',
        name: 'Names',
        surename: 'Surnames',
        doc_number: 'ID',
        state: 'Province',
        phone: 'Phone',
        email: 'E-mail'
      },
      MX: {
        title: 'Applicant information',
        name: 'Names',
        surename: 'Surnames',
        doc_number: 'ID',
        state: 'Province',
        phone: 'Phone',
        email: 'E-mail'
      },
      SP: {
        title: 'Applicant information',
        name: 'Names',
        nombre: 'Names',
        surename: 'Surnames',
        apellidos: 'Surnames',
        doc_number: 'ID',
        state: 'Province',
        phone: 'Phone',
        email: 'E-mail'
      }
    },
    form1_persona: {
      UY: {
        title: 'Some personal information...',
        tipo_doc: 'Doc type',
        identity_doc: 'ID',
        civil_state: 'Marital status',
        fecha_ingreso: 'Since when are you working?',
        ingreso_mensual: 'How much are your monthly income?',
        telefono: 'Mobile',
        fecha_nacimiento: 'Born date',
        cuota_maxima: 'How much is the maximum you can pay per month?',
        destino_prestamo: 'What do you need the money for?',
        jubilado: '¿Are you retired?',
        jubilado_no: 'Not retired',
        jubilado_si: 'I am retired',
        sucursales: 'Sucursales'
      },
      MX: {
        title: 'Some personal information...',
        tipo_doc: 'Doc type',
        identity_doc: 'ID',
        civil_state: 'Marital status',
        fecha_ingreso: 'Since when are you working?',
        ingreso_mensual: 'How much is your monthly income?',
        telefono: 'Mobile',
        fecha_nacimiento: 'Born date',
        cuota_maxima: 'How much is the maximum you can pay per month?',
        destino_prestamo: 'What do you need the money for?',
        jubilado: 'Are you retired?',
        jubilado_no: 'Not retired',
        jubilado_si: 'I am retired'
      },
      SP: {
        title: 'Some personal information...',
        identity_doc: 'ID',
        tipo_doc: 'Doc type',
        nombre: 'Name',
        nacionalidad: 'Nacionalidad',
        apellidos: 'Surnames',
        mail: 'Email',
        civil_state: 'Marital status',
        fecha_ingreso: 'Since when are you working?',
        ingreso_mensual: 'How much is your monthly income?',
        telefono: 'Mobile',
        fecha_nacimiento: 'Born date',
        cuota_maxima: 'How much is the maximum you can pay per month?',
        destino_prestamo: 'What do you need the money for?',
        jubilado: 'Are you retired?',
        jubilado_no: 'Not retired',
        jubilado_si: 'I am retired',
        genero: 'Género',
        masculino: 'Masculino',
        femenino: 'Femenino',
        otro: 'Otro',
        entidad_nacimiento: 'Lugar de nacimiento',
        tiene_carnet_conducir: '¿Tiene carnet de conducir?',
        studies: 'Nivel educacional',
        institucion_de_estudio: 'Institución de estudio',
        CIV: 'Cédula de identidad venezolana',
        banco_deposito: 'Banco',
        cuenta_deposito: 'Número de cuenta',
        acepted_before: 'Ha sido aceptado por otra empresa de crédito anteriormente',
        has_others_credits: 'Mantiene créditos con otra empresa de micro créditos',
        accept_advertising: 'Acepto recibir ofertas por correo',
        comprobacion_sc: 'Comprobación rápida del estado de tu cuenta',
        comprobacion_unnax: 'Comprobación rápida del estado de tu cuenta'
      }
    },
    form3_persona: {
      UY: {
        title: 'Datos Bancarios'
      },
      MX: {
        title: 'Datos Bancarios'
      },
      SP: {
        title: 'Datos Bancarios'
      }
    },
    incentivo_descuentos_default: {
      label_1: 'Recibís',
      label_2: ' tasa del ',
      label_3: 'Valor de cuota aproximada depende del perfil asignado al cliente luego de completar el formulario de solicitud',
      label_4: 'Nota: Los cálculos son aproximados'
    },
    incentivo_solicitud_default: {
      label_1: 'You are just two steps away from accessing',
      label_2: ' in ',
      label_3: ' instalments',
      label_4: '!'
    },
    form2_empresa: {
      UY: {
        title: 'Company data',
        company: 'Company name',
        company_name: 'Fantasy name',
        doc_number: 'BIN',
        activity_start: 'Open date',
        activity_type: 'Activity',
        address: 'Address',
        add_partner: 'Add partner',
        phone: 'Phone',
        email: 'E-mail',
        partner: {
          name: 'Names',
          surename: 'Surnames',
          doc_number: 'ID'
        }
      },
      MX: {
        title: 'Company data',
        company: 'Company name',
        company_name: 'Fantasy name',
        doc_number: 'BIN',
        activity_start: 'Open date',
        activity_type: 'Activity',
        address: 'Address',
        add_partner: 'Add partner',
        phone: 'Phone',
        email: 'E-mail',
        partner: {
          name: 'Names',
          surename: 'Surnames',
          doc_number: 'ID'
        }
      },
      SP: {
        title: 'Company data',
        company: 'Company name',
        company_name: 'Fantasy name',
        doc_number: 'BIN',
        activity_start: 'Open date',
        activity_type: 'Activity',
        address: 'Address',
        add_partner: 'Add partner',
        phone: 'Phone',
        email: 'E-mail',
        partner: {
          name: 'Names',
          surename: 'Surnames',
          doc_number: 'ID'
        }
      }
    },
    form2_persona: {
      UY: {
        title: 'It\'s almost yours',
        property_type: 'Housing type',
        state: 'Province',
        phone: 'Home phone number',
        employee_type: 'Employment relationship',
        address: 'Full address',
        email: 'E-mail',
        studies: 'Last study completed',
        company_phone: 'Work phone number',
        how_find_out: 'How did you hear about the company?',
        mandatory_field: 'Please fill in the field',
        contact: {
          title: 'Contact person',
          sub_title: 'Person - family or acquaintance - whom to contact in case of not being able to contact the applicant directly',
          full_name: 'Full name',
          phone: 'Phone number'
        }
      },
      MX: {
        title: 'Ya casi es tuyo',
        property_type: 'Tipo de vivienda',
        state: 'Estado',
        phone: 'Teléfono fijo',
        level_3: 'Municipio',
        level_4: 'Colonia',
        postal_code: 'Código postal',
        employee_type: 'Relación laboral',
        address: 'Dirección y localidad',
        email: 'E-mail',
        studies: 'Último estudio cursado',
        company_phone: 'Teléfono del trabajo',
        how_find_out: '¿Cómo se enteró de la empresa?',
        mandatory_field: 'El campo debe ser completado',
        contact: {
          title_personal: 'Referencias personales',
          personal_full_name: 'Nombre y apellido',
          personal_address: 'Dirección',
          personal_phone: 'Teléfono de contacto',
          personal_parentship: 'Parentesco',
          title_familiar: 'Referencias familiares',
          familiar_address: 'Dirección',
          familiar_phone: 'Teléfono de contacto',
          familiar_full_name: 'Nombre y apellido',
          familiar_parentship: 'Parentesco'
        }
      },
      SP: {
        title: 'Ya casi es tuyo',
        property_type: 'Tipo de vivienda',
        state: 'Provincia',
        phone: 'Teléfono de la casa',
        employee_type: 'Relación laboral',
        address: 'Dirección y localidad',
        email: 'E-mail',
        studies: 'Último estudio cursado',
        company_phone: 'Teléfono del trabajo',
        how_find_out: '¿Cómo se enteró de la empresa?',
        mandatory_field: 'El campo debe ser completado',
        contact: {
          title: 'Persona de contacto',
          sub_title: 'Persona - familiar o conocido - a quien poder contactar en caso de no poder contactar directo con el solicitante',
          full_name: 'Nombre y apellido',
          phone: 'Teléfono de contacto'
        },
        venezolano: {
          title: '¿Eres venezolano?',
          sub_title: 'Si tu respuesta es positiva, por favor haznos llegar esta información adicional'
        },
        cash_in: '¿Cómo quiere devolver?',
        ultima_direccion_venezuela: 'Última dirección en Venezuela',
        ultimo_trabajo_venezuela: 'Último trabajo en Venezuela',
        fuente_ingreso_principal: 'Fuente de ingreso principal'
      },
      property_type: 'Housing type',
      BNF: {
        datos_laborales: 'Labor data',
        empresa_trabajo: 'Company where you work',
        direccion: 'Company address',
        ingresos: 'Income',
        sueldo: 'Salary',
        honorarios: 'Fee',
        ingreso_conyugue: 'Spouse Income',
        gastos: 'Expenses',
        gastos_familiares: 'Family',
        gastos_tarjeta_credito: 'Credit card fee',
        gastos_cuota_prestamo: 'Loan installments',
        gastos_otros: 'Others',
        empleado: 'Employee',
        jubilado: 'Retired',
        propietario: 'Owner',
        tipo_trabajo: 'Type of labor bond',
        profesion: 'Profession',
        cargo_ocupa: 'Position',
        ingresos_otros: 'Others',
        datos_conyugue: 'Spouse data',
        nombre_conyuge: 'Name and surname'
      }
    },
    validacion_sms_default: {
      title: 'Enter verification code',
      sub_title: 'We have sent a code to your phone number. The message may take a few seconds to arrive, in any case you can request to forward the code',
      verification_code: 'Verification code',
      resend_btn: 'Resend code'
    },
    descuento_terceros_cheque: {
      title: 'Datos del cheque',
      librador_type: 'Tipo de librador:',
      doc_label: 'Cheque',
      librador_doc_number: 'Documento',
      librador_name: 'Nombres',
      librador_surename: 'Apellidos',
      librador_phone: 'Teléfono',
      bank: 'Banco',
      account_number: 'Número de cuenta',
      number: 'Número de cheque',
      serie: 'Serie',
      document: 'Copia del documento',
      document_drag: 'Arrastre el archivo aquí o ',
      document_click: 'click para subir',
      document_warning: 'Asegúrese de que el documento sea legible',
      company_name: 'Razón social',
      drag: 'Arrastre el archivo aquí o ',
      click: 'click para subir'
    },
    descuento_terceros_factura: {
      title: 'Datos de la factura',
      doc_label: 'Factura',
      librador_doc_number: 'Documento',
      librador_name: 'Nombres',
      librador_email: 'E-mail',
      document: 'Copia del documento',
      document_drag: 'Arrastre el archivo aquí o ',
      document_click: 'click para subir',
      document_warning: 'Asegúrese de que el documento sea legible',
      librador_phone: 'Teléfono',
      drag: 'Arrastre el archivo aquí o ',
      click: 'click para subir'
    },
    form_garante: {
      UY: {
        title: 'Inserte los datos del garante',
        identity_doc: 'ID',
        address: 'Address',
        civil_state: 'Marital status',
        fecha_ingreso: 'Since when are you working?',
        ingreso_mensual: 'How much is your monthly income?',
        phone: 'Mobile',
        state: 'Province',
        born_date: 'Born date',
        relacion_laboral: 'Employment relationship',
        company_phone: 'Work phone',
        error_applicant: 'The guarantor cannot be the same as the applicant.'
      },
      MX: {
        title: 'Inserte los datos del garante',
        identity_doc: 'CURP',
        level_3: 'Municipio',
        address: 'Dirección y localidad',
        civil_state: 'Estado civil',
        fecha_ingreso: '¿Desde cuándo está trabajando?',
        ingreso_mensual: '¿Cuántos son tus ingresos mensuales?',
        phone: 'Móvil',
        state: 'Estado',
        level_4: 'Colonia',
        born_date: 'Fecha de nacimiento',
        relacion_laboral: 'Relación laboral',
        company_phone: 'Teléfono laboral',
        error_applicant: 'El garante no puede ser el mismo que el solicitante.'
      },
      SP: {
        title: 'Inserte los datos del garante',
        identity_doc: 'Documento de identidad',
        address: 'Dirección',
        civil_state: 'Estado civil',
        fecha_ingreso: '¿Desde cuándo está trabajando?',
        ingreso_mensual: '¿Cuántos son tus ingresos mensuales?',
        phone: 'Móvil',
        state: 'Provincia',
        born_date: 'Fecha de nacimiento',
        relacion_laboral: 'Relación laboral',
        company_phone: 'Teléfono laboral',
        error_applicant: 'El garante no puede ser el mismo que el solicitante.'
      }
    },
    select_products_all: {
      title: 'Please select the product that best fits your interests',
      products: 'Finally products easy to understand! '
    },
    oferta_up_descuentos_default: {
      label_1: 'You will receive',
      label_2: ' rate ',
      btn_accept: 'I accept the proposal'
    },
    oferta_down_solicitud_default: {
      label_1: 'Same amount',
      label_2: 'in',
      label_3: 'instalments',
      label_4: 'Same term'
    },
    oferta_up_solicitud_default: {
      label_1: 'in',
      label_2: 'instalments',
      label_3: 'of',
      btn_accept: 'I accept the proposal'
    },
    ofertas_default: {
      title: 'Proposal....you are almost there',
      sub_title: 'This is the best option for you',
      other_options: 'some other options'
    },
    oferta_solicitud_default: {
      offer_line: '{monto_oferta} in {plazo} fee from {monto-cuota} {term_type}',
      offer_line_plural: '{monto_oferta} in {plazo} fees from {monto-cuota} {term_type}'
    },
    oferta_solicitud_table: {
      amount_requested: 'Request Amount',
      plazo: 'Term',
      plazo_cuotas: ' |{cuotas} cuota {type}|{cuotas} cuotas {type} ',
      tasa: 'TEA ',
      fecha_pago: 'Firts Due Data',
      amount: 'Installment'
    }
  },
  views: {
    final: {
      greetings: 'Congratulations!',
      sent_ok: 'The request has been sent successfully.',
      data_received: 'We have received your data and documentation.\n' + 'Very soon, one of our account agents will contact you\n' + 'to report your credit approval.',
      data_received_2: {
        1: 'We will inform you how we will send you the money and the best way to cancel your installments.'
      },
      back_button: 'Back home'
    },
    info_garante: {
      title: 'Wait...we still can help you',
      sub_title: 'Add a guarantee, guarantor or co-signer that meets the following requirements:',
      btn_add_grante: 'Add guarantor',
      sub_title2: '',
      sub_title_aux: '',
      requirements: requirements
    },
    no_podemos_ayudar: {
      title: 'Unfortunately we can not help you this time.',
      sub_title: 'Please remember the requirements:',
      sub_title_aux: '',
      sub_title2: '',
      requirements: requirements
    },
    not_available: {
      title: 'Unfortunately at this time we cannot help you.',
      sub_title: '',
      message: 'Our services are offline'
    },
    producto_caducado: {
      title: 'Product unavailable',
      sub_title: 'Unfortunately this product is not available anymore. ' + 'Pleas contact the company for further information.'
    },
    final_doc: {
      title: 'Congratulations!',
      sub_title: 'Your documentation has been uploaded successfully.'
    },
    validar_telefono: {
      resend_code: 'Your new code has been sent'
    },
    garante: {
      alert: {
        message: 'The person with document number: {document} is not a valid guarantor, must provide another guarantor.',
        title: 'Invalid guarantor',
        btn_aceptar: 'It is understood',
        btn_cancelar: 'View requirements'
      }
    },
    documentacion: {
      vale: {
        banner: 'Contrato',
        not_reset_success: 'El código le fue enviado nuevamente',
        not_reset_error: 'Error al intentar reenviar código',
        not_route_obtain: 'No se obtuvo una ruta válida de la documentación a firmar, por favor revise el código proporcionado y/o comúniquese con nosotros',
        already_signed: 'El contrato de esta solicitud ya ha sido firmado'
      }
    },
    pagos: {
      banner: 'Autorización de Pago',
      title: 'Autorización de Pago',
      sub_title: 'Reddo  ayuda a mantener al día  tus pagos.  Domicilia  las cuotas mensuales de tu crédito. Haz click aquí:',
      prestamo: '#Prestamo: ',
      monto_prestamo: 'Monto Prestamo: ',
      cant_cuotas: 'Cantidad de Cuotas: ',
      monto_cuotas: 'Monto de Cuotas: ',
      fechas_vencimiento: 'Fechas de Cobro de cuota ',
      btn_accept: 'Aceptar Pago',
      not_btn_accept: 'No Aceptar',
      already_signed: 'El pago de esta solicitud ya ha sido procesada',
      not_route_obtain: 'No se puede generar el cobro de la cuota,por favor  comúniquese con nosotros',
      not_route_obtain_naat: 'No se puede generar el cobro de la cuota, por favor comúniquese con nosotros',
      ya_procesado: 'Pago recurrente ya procesado',
      data_received: 'Para realizar los pagos mensuales por transferencia, de las cuotas de tu crédito Reddo, sigue las instrucciones: ',
      final_sub_title: '',
      final_title: 'Final proceso!',
      cuota: 'Cuota # '
    }
  },
  commons: {
    phone_number: {
      label: 'Phone number'
    },
    address: {
      label: 'Address',
      postal_code: 'Postal Code',
      select_previous: 'Select {name} first'
    },
    open_banking: {
      title_header: 'Open Banking',
      label: 'Do you want us to obtain the details of your bank account',
      text: 'This is a service provided by {instanor_link} for {company} ' + 'in order to verify your ability to meet payments.' + ' You only need to log in with the same data with which you access your ' + 'online banking services, and {instanor_link} will perform the check safely ' + 'and confidential in seconds. Upon acceptance of the loan, we will transfer you ' + 'the approved amount in minutes.',
      not_keep_pasword: ' We do not save users or passwords.',
      statements: {
        acces_data: 'Your access data will not be stored or seen by anyone',
        service: 'Service provided by Instantor AB',
        save: 'Safe and reliable',
        fast: 'Fast - Response in minutes',
        easy: 'Easy - Just log into your bank'
      },
      title: 'You are in good hands...'
    },
    bank_aggregation: {
      title_header: 'Agregación Bancaria Unnax'
    },
    youSing: {
      title: 'Ingrese documento',
      sub_title: 'Entre el documento de identidad con el que realizó la solicitud'
    },
    docuSing: {
      title: 'Ingrese el código y  documento',
      sub_title: 'Si desea que le reenvien el código, deje el campo del código vacio y peresione "{reenviar}"'
    }
  },
  title: {
    'pz-products-all-select': 'Please select the product that best fits your interests',
    //**simuladores
    'pz-slider-simulator': 'Plain, simple, clear.',
    'pz-data-input-simulator': 'Plain, simple, clear.',
    "pz-vehiculo-simulator": "Plain, simple, clear.",
    //**simuladores
    //**Form1
    'pz-person-form-es1': 'Plain, simple, clear.',
    'pz-person-form-es2': 'Plain, simple, clear.',
    'pz-person-form-es3': 'Datos Bancarios',
    'pz-company-form1-uy': 'Applicant information',
    'pz-person-form1-default': 'Applicant information',
    'pz-person-form1-uy': 'Applicant information',
    'pz-person-form-1-mx': 'Plain, simple, clear.',
    'pz-company-form-1-mx': 'Plain, simple, clear.',
    //**Form1
    //**Form2
    'pz-person-form-ext-es': 'Applicant information',
    'pz-company-form-ext-es': 'Applicant information',
    'pz-company-form-ext-uy': 'Applicant information',
    'pz-person-form-ext-uy': 'Applicant information',
    'pz-person-form-ext-mx': 'Applicant information',
    'pz-company-form-ext-mx': 'Applicant information',
    //**Form1
    //**Oferta
    'pz-offers-uy': 'Proposal',
    //**Oferta
    //**Doc
    'pz-company-documentacion-uy': 'Documentation',
    'pz-person-documentacion-uy': 'Documentation',
    //**Desc
    'pz-cheque-descuento-terceros': 'Third party documents',
    'pz-factura-descuento-terceros': 'Third party documents',
    //**Desc
    //**Validación telefono
    'pz-validate-by-sms': 'Validate your mobile ',
    //**Validación telefono
    //**Garante
    'pz-garante-form-uy': '',
    'pz-garante-form-mx': '',
    //**Garante
    'pz-message-uy': 'Congratulations',
    'pz-message-es': 'Congratulations',
    'pz-person-form-ext-bnf': 'Applicant information',
    'pz-person-form-ext2-bnf': 'Applicant information',
    'pz-person-form-ext-prestama': 'Applicant information',
    'pz-person-form-ext2-prestama': 'Applicant information',
    'pz-firma-docs': 'Signature of Documents',
    'pz-person-form1-fenoreste': 'Applicant information',
    'pz-person-form-ext-fenoreste': 'Applicant information'
  }
};
export default keys;