import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { getBancos, getEstadoCivil, getPaises } from "@/routes/api/resources";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import { mapGetters } from "vuex";
import { required } from "@/commons/utils/Rules";
import PzUnnaxIframe from "@/commons/forms/Fields/OpenBanking/PzUnnaxIframe";
import { getBankIntegrationWidgetUrl } from "@/routes/api/views/integracion";
import { isEmpty } from "@/commons/utils/functions";
export default {
  name: 'PzPersonFormEs2',
  components: {
    PzDirection: PzDirection,
    PzFormFlujo: PzFormFlujo,
    PzUnnaxIframe: PzUnnaxIframe
  },
  mixins: [baseFormFlujo],
  data: function data() {
    var _this = this;
    var checkUnnaxComplete = function checkUnnaxComplete(rule, value, callback) {
      console.log('checkUnnaxComplete');
      if (_this.formulario1.is_unnax_completed === '') {
        return callback(new Error('El proceso UNNAX no ha concluido'));
      }
      return callback();
    };
    var check_hijos = function check_hijos(rule, value, callback) {
      if (!_this.formulario1.tiene_hijos) {
        _this.formulario1.cant_hijos = '';
        return callback();
      } else {
        return callback();
      }
    };
    var check_doc = function check_doc(rule, value, callback) {
      if (!_this.regla) return callback();
      var pattern = new RegExp(_this.regla.toString());
      if (pattern.test(value)) {
        return callback();
      }
      return callback(new Error(_this.$t('rules.bank_rule')));
    };
    var checkUnnaxRequired = function checkUnnaxRequired(rule, value, callback) {
      if (_this.formulario1.open_bank_integration == '' && _this.product.bank_integration == 1) {
        return callback(new Error('El campo es requerido,por favor verifíquelo.'));
      } else if (_this.formulario1.is_unnax_completed == '' && _this.product.bank_integration == 1) {
        return callback(new Error('El proceso UNNAX no ha concluido'));
      }
      return callback();
    };
    return {
      reference: 'formulario1',
      genero: [{
        id: 'f',
        name: this.$t('forms_flujo.form1_persona.SP.femenino')
      }, {
        id: 'm',
        name: this.$t('forms_flujo.form1_persona.SP.masculino')
      }, {
        id: 'o',
        name: this.$t('forms_flujo.form1_persona.SP.otro')
      }],
      studies: [],
      bancos: [],
      marital_status: [],
      arreglo_hijos: [{
        id: 1,
        name: 'SI'
      }, {
        id: 0,
        name: 'NO'
      }],
      vnz: false,
      regla: '',
      paises: [],
      show_unnax_dialog: false,
      formulario1: {
        direccion: {},
        estado_civil_id: '',
        genero: '',
        ciudad_nacimiento: '',
        banco_deposito: '',
        cuenta_deposito: '',
        instantor: {},
        info_extra: {
          carnet_conducir: ''
        },
        numero_documento: '',
        tiene_hijos: '',
        cant_hijos: '',
        pais_nacimiento: '',
        is_unnax_completed: '',
        not_route_obtained: false,
        unnax_timestamp: Date.now(),
        open_bank_integration: '',
        widget_url: ''
      },
      rules_formulario1: {
        genero: [required('')],
        tiene_hijos: [required(''), {
          validator: check_hijos,
          trigger: 'change'
        }],
        cuenta_deposito: [{
          validator: check_doc,
          trigger: 'blur'
        }],
        pais_nacimiento: [required('')],
        open_bank_integration: [{
          validator: checkUnnaxRequired,
          trigger: 'blur'
        }],
        is_unnax_completed: [{
          validator: checkUnnaxComplete,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: _objectSpread({
    unnax_checkbox_disabled: function unnax_checkbox_disabled() {
      return this.formulario1.is_unnax_completed === 'completed' || this.formulario1.not_route_obtained;
    }
  }, mapGetters(['applicant', 'product'])),
  created: function created() {
    var _this2 = this;
    this.fetchData();
    this.$root.$on('set_unnax_timestamp', function (data) {
      return _this2.updateUnnaxTimestamp(data);
    });
  },
  mounted: function mounted() {
    var _this3 = this;
    this.formulario1.numero_documento = this.applicant.numero_documento;
    this.initialize();
    // activa la escucha de mensajes provenientes del iframe
    window.addEventListener("message", function (evt) {
      if (evt.data === 'unnax:aggregation:done') {
        _this3.formulario1.is_unnax_completed = 'completed';
        _this3.formulario1.open_bank_integration = true;
        setTimeout(function () {
          return _this3.closeDialog();
        }, 1000);
      }
      if (evt.data === 'unnax:aggregation:cancel:done') {
        _this3.formulario1.is_unnax_completed = '';
        _this3.formulario1.open_bank_integration = '';
        _this3.formulario1.unnax_timestamp = Date.now();
        _this3.formulario1.widget_url = '';
        setTimeout(function () {
          return _this3.closeDialog();
        }, 1000);
      }
      if (evt.data === 'unnax:widget_start:done') {
        _this3.formulario1.is_unnax_completed = '';
        _this3.formulario1.open_bank_integration = '';
      }
      if (evt.data === 'unnax:aggregation:bank_selection_view:done') {
        _this3.$store.dispatch('loader/down', {
          trigger: _this3.$options.name
        });
      }
    });
  },
  methods: {
    handleChange: function handleChange() {
      var key = this.formulario1.banco_deposito;
      if (this.bancos.length > 0 && key) {
        this.regla = this.bancos.find(function (datos) {
          return datos.id == key;
        });
        this.regla = this.regla.rules;
      }
    },
    fetchData: function fetchData() {
      var _this4 = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var estado = getEstadoCivil().then(function (res) {
        _this4.marital_status = res.data;
      }).catch(function () {
        _this4.marital_status = [];
      });
      var bancos = getBancos().then(function (res) {
        _this4.bancos = res.data;
      }).catch(function () {
        _this4.bancos = [];
      });
      var paises = getPaises().then(function (res) {
        _this4.paises = res.data;
      }).catch(function () {
        _this4.paises = [];
      });
      return Promise.all([estado, bancos, paises]).then(function () {}).finally(function () {
        _this4.$store.dispatch('loader/down', {
          trigger: _this4.$options.name
        });
      });
    },
    updateUnnaxTimestamp: function updateUnnaxTimestamp(timestamp) {
      this.formulario1.unnax_timestamp = timestamp;
    },
    initialize: function initialize() {
      this.formulario1.is_unnax_completed = '';
      this.formulario1.not_route_obtained = false;
      this.formulario1.unnax_timestamp = Date.now();
      this.formulario1.open_bank_integration = '';
    },
    // cierra el modal de UNNAX
    closeDialog: function closeDialog() {
      this.setUnnaxDialogVisible(false);
    },
    // cambia la visibilidad del modal de UNNAX
    setUnnaxDialogVisible: function setUnnaxDialogVisible(val) {
      this.show_unnax_dialog = val;
      if (val) {
        this.getUnnaxWidgetUrl();
      }
    },
    getUnnaxWidgetUrl: function getUnnaxWidgetUrl() {
      var _this5 = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      getBankIntegrationWidgetUrl({
        unnax_timestamp: this.formulario1.unnax_timestamp
      }).then(function (res) {
        if (res.data.valid && !isEmpty(res.data.ruta)) {
          var ruta = res.data.ruta;
          _this5.formulario1.widget_url = ruta;
          if (ruta === true) _this5.formulario1.is_unnax_completed = 'completed';
          _this5.$root.$emit('set_unnax_timestamp', _this5.formulario1.unnax_timestamp);
        } else {
          var message = isEmpty(res.data.ruta) && res.data.valid ? _this5.$t('views.documentacion.vale.already_signed') : _this5.$t('views.documentacion.vale.not_route_obtain');
          if (!res.data.valid) {
            _this5.formulario1.not_route_obtained = true;
            _this5.formulario1.open_bank_integration = false;
            _this5.closeDialog();
          }
          _this5.$store.dispatch('app/setMessage', message);
        }
      }).catch(function (error) {
        _this5.$store.dispatch('app/catchErrors', {
          _this: _this5,
          error: error
        });
      }).finally(function () {
        _this5.$store.dispatch('loader/down', {
          trigger: _this5.$options.name
        });
      });
    }
  }
};