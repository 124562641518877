import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import { isEmpty, isMobile } from '@/commons/utils/functions';
import PzLoader from "@/commons/PzLoaderMixins";
import { getDocsSign } from "@/routes/api/views/vales";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
export default {
  name: 'PzFirmaDocs',
  mixins: [PzLoader, baseFormFlujo],
  provide: function provide() {
    return {
      myForm: this
    };
  },
  data: function data() {
    return {
      route: null,
      formulario: {}
    };
  },
  computed: {
    frame: function frame() {
      return 'pz-naat';
    }
  },
  created: function created() {
    var _this = this;
    this.$store.dispatch('loader/up', {
      trigger: this.$options.name
    });
    var id = this.loan;
    if (this.$route.params.loan) id = this.$route.params.loan;
    if (!isMobile()) {
      this.route = location.href + '/' + id;
      return true;
    }
    getDocsSign({
      'loan': id
    }).then(function (res) {
      if (res.data.valid && !isEmpty(res.data.ruta)) {
        _this.route = res.data.ruta;
      } else {
        var message = isEmpty(res.data.ruta) && res.data.valid ? _this.$t('views.documentacion.vale.already_signed') : _this.$t('views.documentacion.vale.not_route_obtain_naat');
        _this.$emit('nextStep');
        _this.$store.dispatch('app/setMessage', message);
      }
    }).catch(function (error) {
      _this.$store.dispatch('app/catchErrors', {
        _this: _this,
        error: error
      });
    }).finally(function () {
      _this.$store.dispatch('loader/down', {
        trigger: _this.$options.name
      });
    });
  },
  methods: {}
};