import { Cedula, required } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import { getDepartamentos } from '@/routes/api/resources';
import baseForm1 from '@/components/Flujo/Formularios/Formularios1/baseForm1';
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzCheckForm from "@/components/Flujo/Formularios/Formularios1/CheckFields/ChecksForm";
export default {
  name: 'PzCompanyForm1Mx',
  components: {
    PzCheckForm: PzCheckForm,
    PzForwardButton: PzForwardButton,
    PzBackButton: PzBackButton
  },
  mixins: [baseForm1],
  data: function data() {
    return {
      loading: false,
      marital_status: [],
      centerDialogVisible: false,
      departamentos: [],
      formulario1: {
        nombres: '',
        apellidos: '',
        numero_documento: '',
        provincia: '',
        telefono: '',
        email: '',
        tipo_documento: 1
      },
      disable: true,
      rules_formulario1: {
        nombres: [required('')],
        apellidos: [required('')],
        provincia: [required('')],
        numero_documento: [Cedula('')],
        telefono: [required('')]
      }
    };
  },
  created: function created() {
    var _this = this;
    getDepartamentos().then(function (res) {
      _this.departamentos = res.data;
    }).catch(function () {
      _this.departamentos = [];
    });
  },
  mounted: function mounted() {},
  methods: {}
};