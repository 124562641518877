import _slicedToArray from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.entries.js";
import { required } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import { getFromLocal } from "@/commons/utils/storage";
import currency from "@/commons/filters/other/currency";
export default {
  name: 'PzPersonForm3FenoresteNew',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      tableIngresos: [],
      tableData: [],
      formulario1: {}
    };
  },
  created: function created() {
    var data_client = getFromLocal('data_client');
    this.tableIngresos = [{
      fuente: 'Fijos',
      monto: '$ ' + currency(data_client['monto_ingresos_fijos'], '', 2)
    }, {
      fuente: 'Variables',
      monto: '$ ' + currency(data_client['monto_ingresos_variables'], '', 2)
    }, {
      fuente: 'Total',
      monto: '$ ' + currency(data_client['monto_total_fijos'], '', 2)
    }];
    var object1 = data_client['gastos'];
    for (var _i = 0, _Object$entries = Object.entries(object1); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        key = _Object$entries$_i[0],
        value = _Object$entries$_i[1];
      this.tableData.push({
        fuente: this.$t('forms_flujo.form2_persona.UY.' + key),
        monto: '$ ' + currency(value == 'null' ? 0 : value, '', 2)
      });
    }
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    }
  }
};