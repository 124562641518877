import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { EspacioBlanco, required, type } from "@/commons/utils/Rules";
import { getDepartamentos, getRelacionesLaborales } from "@/routes/api/resources";
import baseGarante from '../baseGarante';
import { mapGetters } from "vuex";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: 'PzGaranteFormUy',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [baseGarante],
  provide: function provide() {
    return {
      myForm: this
    };
  },
  data: function data() {
    var _this = this;
    var checCIGarante = function checCIGarante(rule, value, callback) {
      if (_this.form_product.cedula === _this.formulario_garante.identity.number) {
        return callback(new Error(_this.$t('forms_flujo.form_garante.UY.error_applicant')));
      } else {
        return callback();
      }
    };
    return {
      employment_relations: [],
      departamentos: [],
      formulario_garante: {
        nombres: '',
        apellidos: '',
        telefono: '',
        fecha_nacimiento: '',
        direccion: '',
        provincia: '',
        relacion_laboral: '',
        telefono_empresa: '',
        fecha_ingreso: '',
        ingreso_mensual: '',
        url_trackeo: '',
        tipo_documento: 1,
        identity: {
          document: 1
        }
      },
      disable: true,
      rules_formulario_garante: {
        'identity.document': [required('')],
        'identity.number': [required(''), EspacioBlanco(), {
          validator: checCIGarante,
          trigger: 'blur'
        }],
        nombres: [required('')],
        apellidos: [required('')],
        telefono: [required('')],
        fecha_nacimiento: [required('')],
        direccion: [required('')],
        provincia: [required('')],
        relacion_laboral: [required('')],
        telefono_empresa: [required('')],
        fecha_ingreso: [required('')],
        ingreso_mensual: [required(''), type({
          field: '',
          type: 'number'
        })]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['product', 'form_product', 'current_step'])),
  created: function created() {
    this.$emit('step', 'FormularioGarante');
  },
  beforeMount: function beforeMount() {
    var _this2 = this;
    this.reference = 'formulario_garante';
    getRelacionesLaborales().then(function (res) {
      var employment_relations = res.data;
      _this2.employment_relations = employment_relations.filter(function (elem) {
        var proibid = ['Pensionista', 'Jubilado', 'Otros', 'Desempleado', 'No se sabe'];
        if (!(proibid.indexOf(elem.relacion.toString()) !== -1)) {
          return elem;
        }
      });
    }).catch(function () {
      _this2.employment_relations = [];
    });
    getDepartamentos().then(function (res) {
      _this2.departamentos = res.data;
    }).catch(function () {
      _this2.departamentos = [];
    });
  },
  mounted: function mounted() {},
  methods: {}
};