import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters } from "vuex";
export default {
  name: 'PzBanner',
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(['header'])),
  mounted: function mounted() {},
  methods: {}
};