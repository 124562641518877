import "core-js/modules/es.number.constructor.js";
export default {
  name: 'PzBackButton',
  props: {
    buttonName: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'default'
    },
    back: {
      type: Function,
      default: null
    },
    icon: {
      type: String,
      default: 'el-icon-arrow-left'
    },
    keyPress: {
      type: [Number],
      default: null
    }
  },
  componentName: 'PzBackButton',
  data: function data() {
    return {
      label: this.buttonName ? this.buttonName : this.$t('go_back')
    };
  },
  created: function created() {
    window.addEventListener('keydown', this.handleBackSpace, true);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this.handleBackSpace, true);
  },
  methods: {
    handleClick: function handleClick() {
      if (this.back) return this.back();
      return this.$router.go(-1);
    },
    handleBackSpace: function handleBackSpace(event) {
      if (this.keyPress && event.keyCode === this.keyPress) {
        this.handleClick();
      }
    }
  }
};