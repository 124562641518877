import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: 'PzCaptchaButton',
  props: {
    buttonName: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'primary'
    },
    forward: {
      type: Function,
      required: true
    },
    keyPress: {
      type: [Number],
      default: 13
    },
    icon: {
      type: String,
      default: 'el-icon-inv'
    }
  },
  componentName: 'PzForwardButton',
  data: function data() {
    return {
      capchakey: process.env.VUE_APP_CAPCHA_KEY,
      label: this.buttonName ? this.buttonName : this.$t('continue')
    };
  },
  created: function created() {
    window.addEventListener('keydown', this.handleEnter, true);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keydown', this.handleEnter, true);
  },
  methods: {
    handleEnter: function handleEnter(event) {
      if (this.keyPress && event.keyCode === this.keyPress) {
        this.handleClick();
      }
    },
    handleClick: function handleClick() {
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      this.$refs.invisibleRecaptcha.execute();
    },
    recaptchaCallback: function recaptchaCallback(recaptchaToken) {
      var _this = this;
      this.$emit('input', recaptchaToken);
      return new Promise(function (resolve) {
        var forw = _this.forward();
        resolve(forw);
      }).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};