import "core-js/modules/es.function.name.js";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import { resendVerificationCode } from "@/routes/api/views/flujo";
export default {
  name: 'PzBaseValidarTelefono',
  components: {},
  mixins: [baseFormFlujo],
  provide: function provide() {
    return {
      myForm: this
    };
  },
  data: function data() {
    return {
      reference: 'formulario_validarTelefono'
    };
  },
  created: function created() {
    this.resenCode();
  },
  methods: {
    handleResend: function handleResend() {
      this.resenCode();
      this.$store.dispatch('app/setMessage', this.$t('validar_telefono.resend_code'));
    },
    resenCode: function resenCode() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      resendVerificationCode(this.loan).then(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      }).catch(function (error) {
        _this.$store.dispatch('app/catchErrors', {
          _this: _this,
          error: error
        });
      });
    }
  }
};