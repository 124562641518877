var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSend
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.datos_inversores")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      label: _vm.$t("forms_flujo.form1_persona.SP.nombre"),
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "nombres",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nombre")
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", $$v);
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellido_paterno",
      label: _vm.$t("forms_flujo.form1_persona.SP.apellidos"),
      error: _vm.errors.get("apellido_paterno")
    }
  }, [_c("el-input", {
    ref: "apellido_paterno",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.apellidos")
    },
    model: {
      value: _vm.formulario1.apellido_paterno,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellido_paterno", $$v);
      },
      expression: "formulario1.apellido_paterno"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      type: "all",
      "type-label": _vm.$t("forms_flujo.form1_persona.UY.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.UY.identity_doc")
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "doc_lugar_emision",
      label: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
    }
  }, [_c("el-select", {
    ref: "doc_lugar_emision",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
    },
    model: {
      value: _vm.formulario1.doc_lugar_emision,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "doc_lugar_emision", $$v);
      },
      expression: "formulario1.doc_lugar_emision"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nombre,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.doc_lugar_emision")
      }
    });
  }), 1)], 1)], 1)], 1), _c("pz-direction", {
    model: {
      value: _vm.formulario1.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "direccion", $$v);
      },
      expression: "formulario1.direccion"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("forms_flujo.form1_persona.UY.email"),
      error: _vm.errors.get("email")
    }
  }, [_c("el-input", {
    ref: "email",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.email")
    },
    model: {
      value: _vm.formulario1.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "email", $$v);
      },
      expression: "formulario1.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono",
      label: _vm.$t("forms_flujo.form1_persona.UY.telefono"),
      error: _vm.errors.get("telefono")
    }
  }, [_c("el-input", {
    ref: "telefono",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.telefono")
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "data_extra.banco_deposito",
      label: _vm.$t("forms_flujo.form1_persona.UY.banco_deposito"),
      error: _vm.errors.get("data_extra.banco_deposito")
    }
  }, [_c("el-select", {
    ref: "data_extra.banco_deposito",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.banco_deposito")
    },
    model: {
      value: _vm.formulario1.data_extra.banco_deposito,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.data_extra, "banco_deposito", $$v);
      },
      expression: "formulario1.data_extra.banco_deposito"
    }
  }, _vm._l(_vm.bancos, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.short_name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "data_extra.sucursal_banco",
      label: _vm.$t("forms_flujo.form1_persona.UY.sucursal_banco"),
      error: _vm.errors.get("data_extra.sucursal_banco")
    }
  }, [_c("el-input", {
    ref: "data_extra.sucursal_banco",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.sucursal_banco")
    },
    model: {
      value: _vm.formulario1.data_extra.sucursal_banco,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.data_extra, "sucursal_banco", $$v);
      },
      expression: "formulario1.data_extra.sucursal_banco"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "data_extra.cuenta_deposito",
      label: _vm.$t("forms_flujo.form1_persona.UY.cuenta_deposito"),
      error: _vm.errors.get("data_extra.cuenta_deposito")
    }
  }, [_c("el-input", {
    ref: "data_extra.cuenta_deposito",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.cuenta_deposito")
    },
    model: {
      value: _vm.formulario1.data_extra.cuenta_deposito,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.data_extra, "cuenta_deposito", $$v);
      },
      expression: "formulario1.data_extra.cuenta_deposito"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };