import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import merge from 'element-ui/src/utils/merge';
import components from "@/components/Flujo/Components";
var install = function install(Vue) {
  components.forEach(function (component) {
    Vue.component(component.name, component);
  });
};
/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
export default merge({
  version: '1.0.0',
  install: install
}, components);