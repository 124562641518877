import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import { getAlias, getNameCompany, getLocalZone, getToken, removeAlias, removeNameCompany, removeToken, removeLocalZone, setAlias, setNameCompany, setToken, setLocalZone } from "@/commons/utils/auth";
import { login as _login } from "@/routes/api/auth";
import router from "@/routes/app/routes";
var state = {
  token: getToken(),
  alias: getAlias(),
  company: getNameCompany(),
  localzone: getLocalZone()
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_ALIAS: function SET_ALIAS(state, alias) {
    state.alias = alias;
  },
  SET_COMPANY: function SET_COMPANY(state, company) {
    state.company = company;
  },
  SET_LOCALZONE: function SET_LOCALZONE(state, localzone) {
    state.localzone = localzone;
  }
};
var actions = {
  // remove token
  resetToken: function resetToken(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      commit('SET_ALIAS', '');
      removeAlias();
      commit('SET_COMPANY', '');
      removeNameCompany();
      commit('SET_LOCALZONE', '');
      removeLocalZone();
      resolve();
    });
  },
  notAuthorized: function notAuthorized(_ref2) {
    var dispatch = _ref2.dispatch;
    return new Promise(function (resolve) {
      dispatch('auth/resetToken', null, {
        root: true
      });
      router.push({
        name: '401'
      });
      resolve();
    });
  },
  login: function login(_ref3, alias) {
    var _this = this;
    var commit = _ref3.commit,
      dispatch = _ref3.dispatch;
    return new Promise(function (resolve) {
      _login(alias).then(function (response) {
        var _response$data = response.data,
          token = _response$data.token,
          lang = _response$data.lang,
          name_company = _response$data.name_company,
          locale_zone = _response$data.locale_zone;
        commit('SET_TOKEN', token);
        setToken(token);
        setAlias(alias);
        setNameCompany(name_company);
        setLocalZone(locale_zone);
        _this._vm.$session.set('username', alias);
        dispatch('app/setLocale', lang, {
          root: true
        });
        dispatch('app/getDirection', null, {
          root: true
        });
        resolve();
      }).catch(function (error) {
        console.log(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};