var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      forward: _vm.handleSend
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("commons.docuSing.title")) + " ")]), _c("span", {
    attrs: {
      slot: "form_subtitle"
    },
    slot: "form_subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("commons.docuSing.sub_title", {
    reenviar: _vm.$t("forms_flujo.validacion_sms_default.resend_btn")
  })) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      model: _vm.formulario,
      "status-icon": "",
      rules: _vm.rules_formulario_validarTelefono
    },
    on: {
      validate: _vm.handleValidate
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "identity",
      error: _vm.errors.get("numero_documento")
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity"
    },
    model: {
      value: _vm.formulario.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario, "identity", $$v);
      },
      expression: "formulario.identity"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      identity: "",
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "codigo_verificacion",
      rules: _vm.formulario.codigo_verificacion === "" ? [] : [_vm.required("")],
      label: _vm.$t("forms_flujo.validacion_sms_default.verification_code"),
      error: _vm.errors.get("codigo_verificacion")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.validacion_sms_default.verification_code")
    },
    model: {
      value: _vm.formulario.codigo_verificacion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario, "codigo_verificacion", $$v);
      },
      expression: "formulario.codigo_verificacion"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    staticClass: "center-block text-center",
    attrs: {
      slot: "buttons",
      span: 24
    },
    slot: "buttons"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm.formulario.codigo_verificacion === "" ? _c("pz-button", {
    key: "resend",
    attrs: {
      "key-press": 13,
      icon: "el-icon-refresh",
      "button-name": _vm.$t("forms_flujo.validacion_sms_default.resend_btn")
    },
    on: {
      click: _vm.handleResend
    }
  }) : _c("pz-captcha-button", {
    key: "send",
    attrs: {
      forward: _vm.handleSend
    },
    model: {
      value: _vm.formulario["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario, "captcha", $$v);
      },
      expression: "formulario['captcha']"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };