import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estudios",
      label: _vm.$t("forms_flujo.form1_persona.SP.studies"),
      error: _vm.errors.get("estudios")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.studies")
    },
    model: {
      value: _vm.formulario1.estudios,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "estudios", $$v);
      },
      expression: "formulario1.estudios"
    }
  }, _vm._l(_vm.studies, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.estudio,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.transporte",
      label: _vm.$t("forms_flujo.form1_persona.UY.medio_transporte"),
      error: _vm.errors.get("info_extra.transporte")
    }
  }, [_c("el-select", {
    ref: "info_extra.transporte",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.medio_transporte")
    },
    model: {
      value: _vm.formulario1.info_extra.transporte,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "transporte", $$v);
      },
      expression: "formulario1.info_extra.transporte"
    }
  }, _vm._l(_vm.medio_transporte, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.medio_transporte")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 24
      },
      lg: {
        span: 24
      },
      md: {
        span: 24
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.depende_deti",
      label: _vm.$t("forms_flujo.form1_persona.UY.depende_deti"),
      error: _vm.errors.get("info_extra.depende_deti")
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.formulario1.info_extra.depende_deti,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1.info_extra, "depende_deti", $$v);
      },
      expression: "formulario1.info_extra.depende_deti"
    }
  }, _vm._l(_vm.data_depende, function (depende) {
    return _c("el-checkbox", {
      key: depende,
      attrs: {
        label: depende,
        border: ""
      }
    });
  }), 1)], 1)], 1)], 1), _c("hr", {
    staticClass: "pz-dotted"
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "tipo_vivienda",
      label: _vm.$t("forms_flujo.form2_persona.SP.property_type"),
      error: _vm.errors.get("tipo_vivienda")
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.SP.property_type")
    },
    model: {
      value: _vm.formulario1.tipo_vivienda,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "tipo_vivienda", $$v);
      },
      expression: "formulario1.tipo_vivienda"
    }
  }, _vm._l(_vm.properties, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.tipo,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form2_persona.property_type")
      }
    });
  }), 1)], 1)], 1)], 1), _c("pz-direction", {
    model: {
      value: _vm.formulario1.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "direccion", $$v);
      },
      expression: "formulario1.direccion"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };