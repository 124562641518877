var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("iframe", {
    directives: [{
      name: "observer",
      rawName: "v-observer",
      value: _vm.change,
      expression: "change"
    }],
    staticClass: "pz-large-margin",
    attrs: {
      id: "docusing",
      name: "docusing",
      frameborder: "0",
      width: "100%",
      height: _vm.height,
      src: _vm.frame_route
    },
    on: {
      load: _vm.iframeLoaded
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };