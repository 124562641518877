import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario2["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "captcha", $$v);
      },
      expression: "formulario2['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.datos_laborales")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario2,
      "status-icon": "",
      rules: _vm.rules_formulario2
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "ingreso_mensual",
      label: _vm.$t("forms_flujo.form2_persona.UY.salario"),
      error: _vm.errors.get("ingreso_mensual")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.salario")
    },
    model: {
      value: _vm.formulario2.ingreso_mensual,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "ingreso_mensual", $$v);
      },
      expression: "formulario2.ingreso_mensual"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.profesion",
      label: _vm.$t("forms_flujo.form2_persona.BNF.profesion"),
      error: _vm.errors.get("profesion")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.BNF.profesion")
    },
    model: {
      value: _vm.formulario2.info_extra.profesion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "profesion", $$v);
      },
      expression: "formulario2.info_extra.profesion"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.cargo_ocupa",
      label: _vm.$t("forms_flujo.form2_persona.UY.cargo_ocupa"),
      error: _vm.errors.get("tipo_tienda")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.cargo_ocupa")
    },
    model: {
      value: _vm.formulario2.info_extra.cargo_ocupa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "cargo_ocupa", $$v);
      },
      expression: "formulario2.info_extra.cargo_ocupa"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "fecha_ingreso",
      label: _vm.$t("forms_flujo.form2_persona.UY.fecha_ingreso"),
      error: _vm.errors.get("fecha_ingreso")
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      "picker-options": _vm.pickerOptions1,
      format: "dd/MM/yyyy",
      "value-format": "dd/MM/yyyy",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.fecha_ingreso")
    },
    model: {
      value: _vm.formulario2.fecha_ingreso,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "fecha_ingreso", $$v);
      },
      expression: "formulario2.fecha_ingreso"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.empresa_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.UY.empresa_trabajo"),
      error: _vm.errors.get("info_extra.empresa_trabajo")
    }
  }, [_c("el-input", {
    ref: "info_extra.empresa_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.empresa_trabajo")
    },
    model: {
      value: _vm.formulario2.info_extra.empresa_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "empresa_trabajo", $$v);
      },
      expression: "formulario2.info_extra.empresa_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "banco_deposito",
      label: _vm.$t("forms_flujo.form2_persona.UY.banco_deposito"),
      error: _vm.errors.get("banco_deposito")
    }
  }, [_c("el-select", {
    ref: "banco_deposito",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.banco_deposito")
    },
    model: {
      value: _vm.formulario2.banco_deposito,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "banco_deposito", $$v);
      },
      expression: "formulario2.banco_deposito"
    }
  }, _vm._l(_vm.bancos, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.short_name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.tipo_cuenta",
      label: _vm.$t("forms_flujo.form2_persona.UY.tipo_cuenta"),
      error: _vm.errors.get("info_extra.tipo_cuenta")
    }
  }, [_c("el-select", {
    ref: "info_extra.tipo_cuenta",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.tipo_cuenta")
    },
    model: {
      value: _vm.formulario2.info_extra.tipo_cuenta,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "tipo_cuenta", $$v);
      },
      expression: "formulario2.info_extra.tipo_cuenta"
    }
  }, _vm._l(_vm.tipo_cuenta, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "cuenta_deposito",
      label: _vm.$t("forms_flujo.form2_persona.UY.cuenta_deposito"),
      error: _vm.errors.get("cuenta_deposito")
    }
  }, [_c("el-input", {
    ref: "cuenta_deposito",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.cuenta_deposito")
    },
    model: {
      value: _vm.formulario2.cuenta_deposito,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "cuenta_deposito", $$v);
      },
      expression: "formulario2.cuenta_deposito"
    }
  })], 1)], 1)], 1), _c("pz-direction", {
    attrs: {
      "name-direction": ""
    },
    model: {
      value: _vm.formulario2.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "direccion", $$v);
      },
      expression: "formulario2.direccion"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono_empresa",
      label: _vm.$t("forms_flujo.form2_persona.UY.telefono_empresa"),
      error: _vm.errors.get("telefono_empresa")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.telefono_empresa")
    },
    model: {
      value: _vm.formulario2.telefono_empresa,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "telefono_empresa", $$v);
      },
      expression: "formulario2.telefono_empresa"
    }
  })], 1)], 1)], 1), _c("hr", {
    staticClass: "pz-dotted"
  }), _c("el-row", {
    staticClass: "pz-large-margin",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "row justify-content-center",
    attrs: {
      span: 24
    }
  }, [_c("b", {
    staticClass: "pz-font-size-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.referencias")) + " ")])])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "familiar[0].nombre_completo",
      label: _vm.$t("forms_flujo.form2_persona.UY.familiar.nombre_completo"),
      error: _vm.errors.get("familiar[0].nombre_completo")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.familiar.nombre_completo")
    },
    model: {
      value: _vm.formulario2.familiar[0].nombre_completo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.familiar[0], "nombre_completo", $$v);
      },
      expression: "formulario2.familiar[0].nombre_completo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "familiar[0].telefono",
      label: _vm.$t("forms_flujo.form2_persona.UY.personal.telefono"),
      error: _vm.errors.get("familiar[0].telefono")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.personal.telefono")
    },
    model: {
      value: _vm.formulario2.familiar[0].telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.familiar[0], "telefono", $$v);
      },
      expression: "formulario2.familiar[0].telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "familiar[1].nombre_completo",
      label: _vm.$t("forms_flujo.form2_persona.UY.familiar.nombre_completo"),
      error: _vm.errors.get("familiar[1].nombre_completo")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.familiar.nombre_completo")
    },
    model: {
      value: _vm.formulario2.familiar[1].nombre_completo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.familiar[1], "nombre_completo", $$v);
      },
      expression: "formulario2.familiar[1].nombre_completo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "familiar[1].telefono",
      label: _vm.$t("forms_flujo.form2_persona.UY.familiar.telefono"),
      error: _vm.errors.get("familiar[1].telefono")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.familiar.telefono")
    },
    model: {
      value: _vm.formulario2.familiar[1].telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.familiar[1], "telefono", $$v);
      },
      expression: "formulario2.familiar[1].telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "personal[0].nombre_completo",
      label: _vm.$t("forms_flujo.form2_persona.UY.personal.nombre_completo"),
      error: _vm.errors.get("personal[0].nombre_completo")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.personal.nombre_completo")
    },
    model: {
      value: _vm.formulario2.personal[0].nombre_completo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.personal[0], "nombre_completo", $$v);
      },
      expression: "formulario2.personal[0].nombre_completo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "personal[0].telefono",
      label: _vm.$t("forms_flujo.form2_persona.UY.personal.telefono"),
      error: _vm.errors.get("personal[0].telefono")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.personal.telefono")
    },
    model: {
      value: _vm.formulario2.personal[0].telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.personal[0], "telefono", $$v);
      },
      expression: "formulario2.personal[0].telefono"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };