import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { required } from "@/commons/utils/Rules";
import baseform2 from '../baseForm2';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import { getCoinTypes, getPais } from "@/routes/api/resources";
export default {
  name: 'PzPersonFormExt2Hipotecalo',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [baseform2],
  data: function data() {
    return {
      paises: [],
      monedas: [],
      isjubilado: false,
      formulario2: {
        procedencia: '',
        moneda_ingresos: '',
        monto_ingresos: '',
        concepto_ingresos: '',
        pais_opera: '',
        destino_prestamo: '',
        info_extra: {
          aldia_obligaciones: '',
          beneficiario: '',
          comosecontacto: '',
          actividad_esperada: ''
        },
        personal: [{
          nombre_completo: '',
          telefono: ''
        }],
        institucional: [{
          nombre_completo: '',
          telefono: ''
        }]
      },
      disable: true,
      rules_formulario2: {},
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      created: function created() {
        this.fetchData();
      },
      methods: {
        fetchData: function fetchData() {
          var _this = this;
          this.$store.dispatch('loader/up', {
            trigger: this.$options.name
          });
          var coins = getCoinTypes().then(function (res) {
            _this.monedas = res.data;
          }).catch(function () {
            _this.monedas = [];
          });
          var paises = getPaises().then(function (res) {
            _this.paises = res.data;
          }).catch(function () {
            _this.paises = [];
          });
          return Promise.all([coins, paises]).then(function () {}).finally(function () {
            _this.$store.dispatch('loader/down', {
              trigger: _this.$options.name
            });
          });
        }
      }
    };
  }
};