import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario2["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "captcha", $$v);
      },
      expression: "formulario2['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario2,
      "status-icon": "",
      rules: _vm.rules_formulario2
    },
    slot: "formulario"
  }, [_c("pz-direction", {
    attrs: {
      viewline: ""
    },
    model: {
      value: _vm.formulario2.direccion,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "direccion", $$v);
      },
      expression: "formulario2.direccion"
    }
  }), _c("label", [_vm._v(_vm._s(_vm.$t("forms_flujo.form2_persona.BNF.datos_particulares")))]), _c("hr", {
    staticClass: "pz-dotted",
    staticStyle: {
      "margin-top": "1px"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "genero",
      label: _vm.$t("forms_flujo.form1_persona.SP.genero"),
      error: _vm.errors.get("genero")
    }
  }, [_c("el-select", {
    ref: "genero",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.genero")
    },
    model: {
      value: _vm.formulario2.genero,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "genero", $$v);
      },
      expression: "formulario2.genero"
    }
  }, _vm._l(_vm.genero, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.genero")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "estado_civil_id",
      label: _vm.$t("forms_flujo.form1_persona.UY.civil_state"),
      error: _vm.errors.get("estado_civil_id")
    }
  }, [_c("el-select", {
    ref: "estado_civil_id",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.civil_state")
    },
    model: {
      value: _vm.formulario2.estado_civil_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "estado_civil_id", $$v);
      },
      expression: "formulario2.estado_civil_id"
    }
  }, _vm._l(_vm.marital_status, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.estado,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.civil_state")
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "pais_nacimiento",
      label: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
    }
  }, [_c("el-select", {
    ref: "pais_nacimiento",
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
    },
    model: {
      value: _vm.formulario2.pais_nacimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "pais_nacimiento", $$v);
      },
      expression: "formulario2.pais_nacimiento"
    }
  }, _vm._l(_vm.paises, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nacionalidad,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.SP.nacionalidad")
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("forms_flujo.form2_persona.UY.email"),
      error: _vm.errors.get("email")
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form2_persona.UY.email")
    },
    model: {
      value: _vm.formulario2.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "email", $$v);
      },
      expression: "formulario2.email"
    }
  })], 1)], 1)], 1), _vm.viewSucursal ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "sucursal_id",
      label: _vm.$t("forms_flujo.form1_persona.UY.sucursales"),
      error: _vm.errors.get("sucursal_id")
    }
  }, [_c("el-select", {
    ref: "sucursal_id",
    attrs: {
      clearable: "",
      placeholder: _vm.$t("forms_flujo.form1_persona.UY.sucursales")
    },
    model: {
      value: _vm.formulario2.sucursal_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2, "sucursal_id", $$v);
      },
      expression: "formulario2.sucursal_id"
    }
  }, _vm._l(_vm.sucursales, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id,
        placeholder: _vm.$t("forms_flujo.form1_persona.UY.sucursales")
      }
    });
  }), 1)], 1)], 1)], 1) : _vm._e(), _c("label", [_vm._v(_vm._s(_vm.$t("forms_flujo.form2_persona.BNF.datos_laborales")))]), _c("hr", {
    staticClass: "pz-dotted",
    staticStyle: {
      "margin-top": "1px"
    }
  }), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.empresa_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.BNF.empresa_trabajo"),
      error: _vm.errors.get("info_extra.empresa_trabajo")
    }
  }, [_c("el-input", {
    ref: "info_extra.empresa_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.BNF.empresa_trabajo")
    },
    model: {
      value: _vm.formulario2.info_extra.empresa_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "empresa_trabajo", $$v);
      },
      expression: "formulario2.info_extra.empresa_trabajo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "info_extra.direccion_trabajo",
      label: _vm.$t("forms_flujo.form2_persona.BNF.direccion"),
      error: _vm.errors.get("info_extra.direccion_trabajo")
    }
  }, [_c("el-input", {
    ref: "info_extra.direccion_trabajo",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form2_persona.BNF.direccion")
    },
    model: {
      value: _vm.formulario2.info_extra.direccion_trabajo,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario2.info_extra, "direccion_trabajo", $$v);
      },
      expression: "formulario2.info_extra.direccion_trabajo"
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };