import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { getPaises } from "@/routes/api/resources";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
export default {
  name: 'PzPersonForm2Hipotecalo',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzIdentityDocument: PzIdentityDocument
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      paises: [],
      formulario1: {
        nombre_conyuge: '',
        nacionalidad_conyuge: '',
        fecha_nacimiento_conyuge: '',
        lugar_nacimiento_conyuge: '',
        bienes_separados: '',
        identity_conyugue: {
          document: 1
        },
        doc_vencimiento_conyugue: '',
        doc_lugar_emision_conyugue: ''
      },
      rules_formulario1: {},
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.$store.dispatch('loader/up', {
        trigger: this.$options.name
      });
      var paises = getPaises().then(function (res) {
        _this.paises = res.data;
      }).catch(function () {
        _this.paises = [];
      });
      return Promise.all([paises]).then(function () {}).finally(function () {
        _this.$store.dispatch('loader/down', {
          trigger: _this.$options.name
        });
      });
    }
  }
};