import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import baseValidacionTelefono from "./baseValidacionTelefono";
import { required } from '@/commons/utils/Rules';
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzButton from "@/commons/forms/Buttons/PzButton";
export default {
  name: 'PzValidateBySms',
  components: {
    PzButton: PzButton,
    PzFormFlujo: PzFormFlujo,
    PzBackButton: PzBackButton,
    PzForwardButton: PzForwardButton
  },
  mixins: [baseValidacionTelefono],
  data: function data() {
    return {
      formulario_validarTelefono: {
        codigo_verificacion: ''
      },
      rules_formulario_validarTelefono: {
        codigo_verificacion: [required('')]
      }
    };
  }
};