import { required, type } from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css';
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import { getFromLocal } from "@/commons/utils/storage";
export default {
  name: 'PzPersonForm2Fenoreste',
  components: {
    PzFormFlujo: PzFormFlujo
  },
  mixins: [PzBaseForm1],
  data: function data() {
    return {
      reference: 'formulario1',
      formulario1: {
        telefono: '',
        identity: {
          document: 1
        },
        comprobante_domicilio: null,
        comprobante_ingresos: null,
        clearing: null,
        sucursal_id: '',
        email: ''
      },
      rules_formulario1: {
        telefono: [required('')],
        email: [required(''), type({
          field: '',
          type: 'email'
        })]
      },
      pickerOptions1: {
        disabledDate: function disabledDate(time) {
          return Date.now() < time.getTime();
        }
      }
    };
  },
  created: function created() {
    var data_client = getFromLocal('data_client');
    if (data_client) {
      this.formulario1.identity = data_client.identity, this.formulario1.email = data_client.email, this.formulario1.telefono = data_client.telefono;
    }
  },
  computed: {
    condocumento: function condocumento() {
      var docmun = localStorage.getItem('docnum');
      if (docmun) {
        return true;
      }
      return false;
    }
  },
  methods: {
    isReadOnly: function isReadOnly(data) {
      var data_client = getFromLocal('data_client');
      if (data_client) return data_client[data] ? true : false;else return false;
    }
  }
};