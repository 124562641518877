import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import baseVerificacionCliente from "./baseVerificacionCliente";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import { isEmpty } from '@/commons/utils/functions';
export default {
  name: 'PzVerificacionHashCliente',
  components: {
    PzFormFlujo: PzFormFlujo,
    PzBackButton: PzBackButton,
    PzForwardButton: PzForwardButton
  },
  mixins: [baseVerificacionCliente],
  data: function data() {
    return {
      formulario_verificar_cliente: {
        hash: '',
        identity: {}
      },
      defaultData: {},
      showForm: false
    };
  },
  mounted: function mounted() {
    this.checkDocAvailability();
  },
  methods: {
    checkDocAvailability: function checkDocAvailability() {
      if (this.defaultData !== null) {
        if (this.defaultData.hasOwnProperty('hash') && !isEmpty(this.$route.params.tipodoc)) {
          this.formulario_verificar_cliente = {
            hash: this.$route.params.tipodoc,
            identity: {
              document: 1,
              number: '33333'
            }
          };
          this.$store.dispatch('product/setFormProduct', {
            verify_route: true
          });
          this.handleSave();
        } else {
          this.showForm = true;
        }
      }
    }
  }
};