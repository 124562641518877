var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_empresa.UY.title")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "nombres",
      label: _vm.$t("forms_flujo.form1_empresa.UY.name"),
      error: _vm.errors.get("nombres")
    }
  }, [_c("el-input", {
    ref: "nombres",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_empresa.UY.name")
    },
    model: {
      value: _vm.formulario1.nombres,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "nombres", $$v);
      },
      expression: "formulario1.nombres"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "apellidos",
      label: _vm.$t("forms_flujo.form1_empresa.UY.surename"),
      error: _vm.errors.get("apellidos")
    }
  }, [_c("el-input", {
    ref: "apellidos",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_empresa.UY.surename")
    },
    model: {
      value: _vm.formulario1.apellidos,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "apellidos", $$v);
      },
      expression: "formulario1.apellidos"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("pz-identity-document", {
    attrs: {
      prop: "identity",
      type: "person",
      "type-label": _vm.$t("forms_flujo.form1_persona.SP.tipo_doc"),
      "number-label": _vm.$t("forms_flujo.form1_persona.SP.identity_doc"),
      "type-hidden": ""
    },
    model: {
      value: _vm.formulario1.identity,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "identity", $$v);
      },
      expression: "formulario1.identity"
    }
  })], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "telefono",
      label: _vm.$t("forms_flujo.form1_empresa.UY.phone"),
      error: _vm.errors.get("telefono")
    }
  }, [_c("el-input", {
    ref: "telefono",
    attrs: {
      type: "text",
      placeholder: _vm.$t("forms_flujo.form1_empresa.UY.phone")
    },
    model: {
      value: _vm.formulario1.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "telefono", $$v);
      },
      expression: "formulario1.telefono"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "provincia",
      label: _vm.$t("forms_flujo.form1_empresa.UY.state"),
      error: _vm.errors.get("provincia")
    }
  }, [_c("el-select", {
    ref: "provincia",
    attrs: {
      placeholder: _vm.$t("forms_flujo.form1_empresa.UY.state")
    },
    model: {
      value: _vm.formulario1.provincia,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "provincia", $$v);
      },
      expression: "formulario1.provincia"
    }
  }, _vm._l(_vm.departamentos, function (item) {
    return _c("el-option", {
      key: item.departamento,
      attrs: {
        label: item.departamento,
        value: item.departamento
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      xl: {
        span: 12
      },
      lg: {
        span: 12
      },
      md: {
        span: 12
      },
      sm: {
        span: 24
      },
      xs: {
        span: 24
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      label: _vm.$t("forms_flujo.form1_empresa.UY.email"),
      error: _vm.errors.get("email")
    }
  }, [_c("el-input", {
    ref: "email",
    attrs: {
      type: "text",
      "auto-complete": "no",
      placeholder: _vm.$t("forms_flujo.form1_empresa.UY.email")
    },
    on: {
      change: function change($event) {
        return _vm.errors.clear("email");
      }
    },
    model: {
      value: _vm.formulario1.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "email", $$v);
      },
      expression: "formulario1.email"
    }
  })], 1)], 1)], 1), _c("pz-check-form"), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.producto.conditions, function (condition) {
    return _c("el-col", {
      key: condition.id,
      staticClass: "row justify-content-between",
      attrs: {
        xl: {
          span: 12
        },
        lg: {
          span: 12
        },
        md: {
          span: 12
        },
        sm: {
          span: 24
        },
        xs: {
          span: 24
        }
      }
    }, [_c("pz-terms-and-conditions", {
      attrs: {
        prop: "condition_" + condition.slug,
        name: condition.nombre
      },
      model: {
        value: _vm.formulario1.conditions[condition.slug],
        callback: function callback($$v) {
          _vm.$set(_vm.formulario1.conditions, condition.slug, $$v);
        },
        expression: "formulario1.conditions[condition.slug]"
      }
    }, [_c("div", {
      domProps: {
        innerHTML: _vm._s(condition.contenido)
      }
    })])], 1);
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };