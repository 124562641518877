import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * Converts a string to UPPERCASE
 * 
 * 'abc' => 'ABC'
 */

function uppercase(value) {
  return value || value === 0 ? value.toString().toUpperCase() : '';
}
export default uppercase;