import i18n, { languge_list } from "@/lang/lang_config";
import { arrayFind } from "@/commons/utils/functions";
export default {
  name: "PzLanguage",
  components: {},
  data: function data() {
    return {
      lang: languge_list,
      locale: i18n.locale,
      current_lang: {
        flag: this.locale,
        language: this.locale,
        title: ''
      }
    };
  },
  created: function created() {
    this.setFlag(this.locale);
  },
  methods: {
    changeLocale: function changeLocale(command) {
      this.$store.dispatch('setLocale', command);
      this.setFlag(command);
    },
    setFlag: function setFlag(command) {
      this.current_lang = arrayFind(this.lang, function (elem) {
        return elem.language === command;
      });
    }
  }
};