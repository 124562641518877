import _objectSpread from "/Users/dayanamartinez/Developer/laas_simulator/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.values.js";
import { mapGetters } from "vuex";
export default {
  name: 'PzMessageEs',
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(['product', 'form_product', 'current_step'])),
  created: function created() {
    this.activities2 = Object.values(this.$t('views.final.data_received_2')).map(function (value) {
      return value;
    });
    this.$store.dispatch('loader/down', {
      trigger: 'route_enter'
    });
  },
  methods: {
    goFirstStep: function goFirstStep() {
      this.$emit('save');
    }
  }
};