import "core-js/modules/es.object.keys.js";
import Cookies from 'js-cookie';
var TokenKey = 'authorization';
var AliasKey = 'alias';
var DirectionKey = 'address';
var Company = 'company';
var Localzone = 'localzone';
var Lang = 'lang';
var Campaign = 'campaign';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getAlias() {
  return Cookies.get(AliasKey);
}
export function setAlias(alias) {
  return Cookies.set(AliasKey, alias);
}
export function removeAlias() {
  return Cookies.remove(AliasKey);
}
export function getNameCompany() {
  return Cookies.get(Company);
}
export function setNameCompany(company) {
  return Cookies.set(Company, company);
}
export function removeNameCompany() {
  return Cookies.remove(Company);
}
export function getLocalZone() {
  return Cookies.get(Localzone);
}
export function setLocalZone(localzone) {
  return Cookies.set(Localzone, localzone);
}
export function removeLocalZone() {
  return Cookies.remove(Localzone);
}
export function getDirection() {
  var dir = Cookies.get(DirectionKey);
  if (dir) {
    return JSON.parse(dir);
  }
  return null;
}
export function setDirecction(dir) {
  return Cookies.set(DirectionKey, dir);
}
export function removeDirecction() {
  return Cookies.remove(DirectionKey);
}
export function getLang() {
  return Cookies.get(Lang);
}
export function setLang(lang) {
  return Cookies.set(Lang, lang);
}
export function getCampaign() {
  return Cookies.get(Campaign);
}