var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-button", {
    staticClass: "pz-large-margin",
    attrs: {
      type: _vm.type,
      icon: _vm.icon
    },
    on: {
      click: _vm.handleClick,
      keydown: _vm.handleKeyDown
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };