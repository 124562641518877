var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("pz-title", [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.ofertas_default.title")) + " ")]), _c("span", {
    attrs: {
      slot: "sub_title"
    },
    slot: "sub_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.ofertas_default.sub_title")) + " ")])]), _c("el-row", {
    staticClass: "center-block"
  }, [_c("pz-oferta-up-" + _vm.elOfertaView, {
    tag: "comment"
  })], 1), _vm.down ? _c("el-row", {
    staticClass: "center-block"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("hr", {
    staticClass: "pz-dotted"
  }), _c("pz-title", [_c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.ofertas_default.other_options")) + " ")])]), _c("el-row", [_c("el-col", {
    attrs: {
      xl: {
        span: 12,
        offset: 6
      },
      lg: {
        span: 12,
        offset: 6
      },
      md: {
        span: 12,
        offset: 6
      },
      sm: {
        span: 18,
        offset: 3
      },
      xs: {
        span: 22,
        offset: 1
      }
    }
  }, [_c("el-card", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form", {
    ref: _vm.reference,
    staticClass: "center-block",
    attrs: {
      model: _vm.otras_ofertas,
      "status-icon": "",
      rules: _vm.rules_otrasOfertas,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "final",
      error: _vm.errors.get("final")
    }
  }, [_c("pz-oferta-down-" + _vm.elOfertaView, {
    tag: "comment"
  })], 1), _vm.viewInfo ? _c("h3", {
    staticClass: "text-center pz-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.oferta_down_solicitud_default.view_info")) + " ")]) : _vm._e(), _c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("pz-back-button", {
    attrs: {
      back: _vm.handleBack
    }
  }), _c("pz-forward-button", {
    attrs: {
      forward: _vm.setOferta
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };