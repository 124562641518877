var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("pz-form-flujo", {
    attrs: {
      "available-captcha": _vm.extraData.withCaptcha,
      forward: _vm.handleSave,
      backwards: _vm.showBack ? _vm.handleBack : null
    },
    model: {
      value: _vm.formulario1["captcha"],
      callback: function callback($$v) {
        _vm.$set(_vm.formulario1, "captcha", $$v);
      },
      expression: "formulario1['captcha']"
    }
  }, [_c("span", {
    attrs: {
      slot: "form_title"
    },
    slot: "form_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form1_persona.UY.resumen_ingresos")) + " ")]), _c("el-form", {
    ref: _vm.reference,
    attrs: {
      slot: "formulario",
      "label-position": "top",
      model: _vm.formulario1,
      "status-icon": "",
      rules: _vm.rules_formulario1
    },
    slot: "formulario"
  }, [_c("el-row", {
    staticClass: "row justify-content-center",
    attrs: {
      gutter: 20
    }
  }, [_c("b", {
    staticClass: "pz-font-size-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.ingresos")) + " ")])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableIngresos,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "fuente",
      label: "Fuente"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "monto",
      label: "Monto"
    }
  })], 1)], 1), _c("br"), _c("el-row", {
    staticClass: "row justify-content-center",
    attrs: {
      gutter: 20
    }
  }, [_c("b", {
    staticClass: "pz-font-size-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("forms_flujo.form2_persona.UY.gastos")) + " ")])]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "fuente",
      label: "Fuente"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "monto",
      label: "Monto"
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };